import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent
} from "@mui/material";
import {
	minCharCountText,
	myOrdersText,
	searchBarHelperText,
	searchHeaderButtonText,
	searchOrdersText
} from "constants/text";
import CloseIcon from "@mui/icons-material/Close";
import { type FC, useState } from "react";
import SearchRounded from "@mui/icons-material/SearchRounded";
import { OrderSearchCommonProps } from "./OrderSearch";
import { OrderSearchFormControl, OrderSearchTextField } from "components/OrderSearch/OrderSearchDesktop.styles";
import { ExactMatchText, PartialMatchText, ShowMeText } from "../NewOrders/constants";

const OrderSearchMobile: FC<OrderSearchCommonProps> = ({
	children,
	hasSearchValidationError,
	searchValue,
	handleSearchFieldOnChange,
	handleSearchFieldOnKeyDown,
	handleOnSearch,
	handlePartialSearch,
	partialMatch,
	isCareRole,
	resetSearch
}: OrderSearchCommonProps) => {
	const iconStyle = { color: "var(--cwg-blue-900)", padding: "1rem 0" };
	const [expandIcon, setExpandIcon] = useState(<SearchRounded sx={iconStyle} />);
	const [expanded, setExpanded] = useState(false);

	const handleChange = (event: SelectChangeEvent) => {
		handlePartialSearch(event);
	};

	const toggleAccordion = () => {
		setExpanded(!expanded);
		setExpandIcon(expanded ? <SearchRounded sx={iconStyle} /> : <CloseIcon sx={iconStyle} />);
	};

	return (
		<Box sx={{ backgroundColor: "var(--white-50)" }}>
			<Accordion
				sx={{
					backgroundColor: "var(--white-50)",
					boxShadow: "none"
				}}
				expanded={expanded}
				onChange={toggleAccordion}
			>
				<AccordionSummary>
					<Grid container>
						<Grid
							item
							xs={12}
						>
							<h5>{myOrdersText}</h5>
						</Grid>
					</Grid>
					{expandIcon}
				</AccordionSummary>
				<AccordionDetails>
					<Grid container>
						<Grid
							item
							xs={12}
						>
							<OrderSearchTextField
								sx={{ marginBottom: "8px" }}
								size="small"
								required
								error={hasSearchValidationError}
								label={searchOrdersText}
								helperText={hasSearchValidationError ? minCharCountText : searchBarHelperText}
								placeholder={searchOrdersText}
								onKeyDown={handleSearchFieldOnKeyDown}
								InputProps={{
									endAdornment: (
										<InputAdornment
											position="start"
											onClick={() => {
												resetSearch();
											}}
										>
											{searchValue !== undefined && searchValue.length >= 3 && (
												<CloseIcon cursor="pointer" />
											)}
										</InputAdornment>
									)
								}}
								InputLabelProps={{
									required: false
								}}
								data-testid="search-header-input-field"
								FormHelperTextProps={{
									style: {
										marginLeft: 1,
										fontWeight: 300
									}
								}}
								value={searchValue}
								onChange={handleSearchFieldOnChange}
							/>
						</Grid>
						<Grid
							item
							xs={12}
						>
							{isCareRole && (
								<OrderSearchFormControl size="small">
									<InputLabel>{ShowMeText}</InputLabel>
									<Select
										value={partialMatch}
										label={ShowMeText}
										onChange={handleChange}
									>
										<MenuItem value="false">{ExactMatchText}</MenuItem>
										<MenuItem value="true">{PartialMatchText}</MenuItem>
									</Select>
								</OrderSearchFormControl>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							sm={12}
						>
							<div style={{ paddingBottom: "1rem" }}>{children}</div>
						</Grid>
					</Grid>
					<Grid
						item
						sm={12}
					>
						<Button
							variant="contained"
							fullWidth
							data-testid="search-header-button"
							data-id="search-header-button"
							sx={{ margin: 0 }}
							aria-label="Search Orders"
							onClick={handleOnSearch}
						>
							{searchHeaderButtonText}
						</Button>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default OrderSearchMobile;
