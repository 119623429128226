import styled from "@emotion/styled";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface CheckCircleOutlineIconProps {
	size: string | number;
}

export const ReplacementControls = styled.div(({ theme }) => ({
	margin: 0,
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	height: "100%",
	width: "100%",
	div: {
		color: "var(--success-green)",
		fontSize: "var(--font-size-3)",
		textTransform: "unset",
		marginTop: ".25rem",
		fontFamily: "Gibson Medium, sans-serif",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
		gap: "6px",
		p: {
			margin: "2px 0 0 0"
		}
	},
	[theme.breakpoints.down("md")]: {
		marginTop: 4,
		marginBottom: 16
	}
}));

export const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)<CheckCircleOutlineIconProps>(({ size }) => ({
	width: size,
	height: size
}));
