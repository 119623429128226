import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { FormControl } from "@mui/material";
interface StyledOrderSearchDesktopInputLineProps {
	isCareRole: boolean;
}

export const OrderSearchDesktopInputLine = styled.div<StyledOrderSearchDesktopInputLineProps>(({ isCareRole }) => ({
	display: "grid",
	gap: 8,
	gridTemplateColumns: isCareRole ? "auto 154px 300px max-content" : "auto 300px max-content",
	gridTemplateRows: "40px auto",
	marginRight: "20px",
	width: "100%"
}));

export const OrderSearchTextField = styled(TextField)(({ theme }) => ({
	lineHeight: "var(--line-height-5)",
	paddingBottom: ".5rem",
	[theme.breakpoints.down("lg")]: {
		width: "100%"
	}
}));

export const OrderSearchFormControl = styled(FormControl)({
	marginBottom: "16px",
	width: "100%"
});
