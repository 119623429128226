import { TextField } from "@mui/material";
import { JobNameText, PONumberText, ReplacementReviewOrderDetailsHeader, RequiredFieldsText } from "constants/text";
import { Controller, useFormContext } from "react-hook-form";
import {
	JOB_NAME_MAX_LENGTH,
	PO_NUMBER_MAX_LENGTH,
	schema
} from "components/NewOrders/NewOrderShippingDetailsForm/schema";
import { getFormFieldProps } from "utils/form";
import { DesignerViewModel } from "data/api/v1/model/designer-view-model";
import { ShippingDetailsCard } from "./NewOrders.styles";
import { useMemo } from "react";
import { NewOrderDesignerNumberText } from "./constants";
import AutoComplete from "components/Common/Autocomplete/Autocomplete";
import { displayDesigner } from "utils/order";

export interface ShippingOrderDetailsProp {
	designers?: DesignerViewModel[];
}

const ShippingOrderDetails = ({ designers }: ShippingOrderDetailsProp) => {
	const { formState, register, watch, control } = useFormContext();
	const formFieldData = { formState, register, schema };
	const poNumberInput = watch("poNumber") ?? "";
	const jobNameInput = watch("jobName") ?? "";

	const searchOptions = useMemo(() => {
		return (designers || [])
			.filter((designer) => designer.designerNumber != null)
			.map((designer) => displayDesigner(designer))
			.filter((item: string, index: number, arr: string[]) => arr.indexOf(item) === index)
			.sort();
	}, [designers]);

	return (
		<ShippingDetailsCard>
			<div data-testid="new-orders-shipping-order-details-header">{ReplacementReviewOrderDetailsHeader}</div>
			<div>{RequiredFieldsText}</div>
			<div>
				<Controller
					name="designer"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							{...getFormFieldProps({
								name: "designer",
								...formFieldData
							})}
							options={Array.from(new Set(searchOptions))}
							onChange={(_event, value) => {
								onChange(value);
							}}
							value={value}
							isError={!!error}
							errorText={error?.message}
							label={NewOrderDesignerNumberText}
							dataTestId="shipping-order-details-designer"
						/>
					)}
				/>
			</div>
			<div>
				<TextField
					{...getFormFieldProps({
						name: "poNumber",
						characterLimit: {
							currentLength: poNumberInput.length,
							limit: PO_NUMBER_MAX_LENGTH
						},
						...formFieldData
					})}
					data-testid="shipping-order-details-poNumber"
					fullWidth
					label={PONumberText}
					size="small"
				/>
				<TextField
					{...getFormFieldProps({
						name: "jobName",
						characterLimit: {
							currentLength: jobNameInput.length,
							limit: JOB_NAME_MAX_LENGTH
						},
						...formFieldData
					})}
					fullWidth
					data-testid="shipping-order-details-jobName"
					label={JobNameText}
					size="small"
				/>
			</div>
		</ShippingDetailsCard>
	);
};

export default ShippingOrderDetails;
