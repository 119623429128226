import { SyntheticEvent } from "react";
import { AccountBillToContentWrapper, FileContentsContainer, FileContentsLogo } from "./NewOrders.styles";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import {
	DoorStyleText,
	FileNameText,
	FinishText,
	SelectAccountText,
	SelectBillToText,
	noAccountText
} from "constants/text";
import { Button, FormControl, PopperProps } from "@mui/material";
import { FileContents, newOrderActions } from "features/newOrder";
import { brandCheck } from "utils/order";
import { useGetAccountsQuery } from "features/accountApi";
import { useDispatch } from "react-redux";
import {
	NewOrderCancelButton,
	NewOrderConfirmRemoveGroup,
	NewOrderRemoveGroup,
	NewOrderRemoveGroupSubtitle,
	NewOrderRemoveGroupTitle,
	NewOrderSelectAccountError,
	NewOrderSelectBillToError,
	NewOrderUpdateGroup
} from "./constants";
import AutoComplete from "components/Common/Autocomplete/Autocomplete";
import { CustomerAccountViewModel } from "data/api/v1/model/customer-account-view-model";
import { AutoCompletePopper } from "components/Common/Autocomplete/Autocomplete.styles";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";
import { convertToTitleCase } from "utils/string";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LeaveFlowDialog from "../Common/LeaveFlowDialog/LeaveFlowDialog";

interface AccountBillToContentProps {
	csvContents: FileContents | undefined;
	index: number;
	removeGroup?: boolean;
	handleDialogClose?: () => void;
	handleRemoveGroup?: () => void;
	handleGlobalConfirm?: () => void;
	isBuildOrderPage?: boolean;
	updateButtons?: boolean;
}

const AccountBillToContent = ({
	csvContents,
	index,
	removeGroup,
	handleDialogClose,
	handleRemoveGroup,
	handleGlobalConfirm,
	isBuildOrderPage,
	updateButtons
}: AccountBillToContentProps) => {
	const currentConfiguration = csvContents?.configurations ? csvContents?.configurations[index] : null;
	const { data: accounts, isLoading: isAccountsLoading } = useGetAccountsQuery(
		currentConfiguration?.productLineCode ?? null
	);
	const dispatch = useDispatch();

	const handleAccountChange = (_event: SyntheticEvent, value: CustomerAccountViewModel) => {
		dispatch(newOrderActions.updateAccountNumber({ index: index, newAccountNumber: value }));
		if (currentConfiguration?.billToNumber !== null) {
			dispatch(newOrderActions.updateBillToNumber({ index: index, newBillToNumber: undefined }));
		}
	};

	const handleBillToChange = (_event: SyntheticEvent, value: BillToInfoViewModel) => {
		if (!currentConfiguration?.accountNumber) {
			dispatch(newOrderActions.updateAccountNumber({ index: index, newAccountNumber: null }));
		} else {
			dispatch(newOrderActions.updateBillToNumber({ index: index, newBillToNumber: value }));
		}
	};

	const CustomPopper = (props: PopperProps) => {
		if (!currentConfiguration?.accountNumber) {
			return null;
		}
		return <AutoCompletePopper {...props} />;
	};

	const handleMouseDownCapture = (e: SyntheticEvent) => {
		e.stopPropagation();
		if (!currentConfiguration?.accountNumber) {
			dispatch(newOrderActions.updateAccountNumber({ index: index, newAccountNumber: null }));
		}
	};

	return (
		<>
			<LeaveFlowDialog
				dialogState={removeGroup ?? false}
				firstButtonText={NewOrderCancelButton.toUpperCase()}
				handleOpenDraftModal={handleGlobalConfirm}
				handleDialogClose={handleDialogClose}
				handleDialogExitOrder={handleDialogClose}
				secondButtonText={NewOrderConfirmRemoveGroup}
				subtitle={NewOrderRemoveGroupSubtitle}
				title={NewOrderRemoveGroupTitle}
			/>
			<AccountBillToContentWrapper
				isBuildOrderPage={isBuildOrderPage}
				isBillToError={currentConfiguration?.billToNumber === null}
			>
				<FileContentsContainer isBuildOrderPage={isBuildOrderPage}>
					<FileContentsLogo isBuildOrderPage={isBuildOrderPage}>
						<StyledSummaryCardLogo
							parentBrand={currentConfiguration?.parentBrand}
							className="body2"
							src={
								brandCheck(currentConfiguration?.parentBrand)
									? `/assets/manufacture_logos/${currentConfiguration?.parentBrand}.png`
									: "/assets/tandem_logos/cwg_logo.png"
							}
							alt={
								brandCheck(currentConfiguration?.parentBrand)
									? (currentConfiguration?.parentBrand ?? "")
									: "CabinetworksGroup Logo"
							}
						/>
					</FileContentsLogo>
					{!isBuildOrderPage && (
						<>
							<div className="subtitle2">
								{FileNameText}
								<div className="body2">{csvContents?.fileName}</div>
							</div>
							{csvContents?.configurations?.length && (
								<div className="subtitle2">
									{DoorStyleText}
									<div className="body2">{currentConfiguration?.doorStyle}</div>
								</div>
							)}
							{csvContents?.configurations?.length && (
								<div className="subtitle2">
									{FinishText}
									<div className="body2">{currentConfiguration?.finish}</div>
								</div>
							)}
						</>
					)}
				</FileContentsContainer>

				<FormControl
					size="small"
					required
					fullWidth
				>
					<AutoComplete
						key={currentConfiguration?.accountNumber?.accountId ?? ""}
						options={accounts ?? []}
						onMouseDownCapture={(e: SyntheticEvent) => {
							if (e.target instanceof HTMLElement && e.target.nodeName === "INPUT") {
								e.stopPropagation();
							}
						}}
						getOptionLabel={(option) =>
							option?.accountNumber && option?.accountName
								? `${option.accountNumber} - ${option.accountName}`
								: (option?.accountNumber ?? "")
						}
						isLoading={isAccountsLoading}
						onChange={handleAccountChange}
						value={currentConfiguration?.accountNumber ?? ""}
						isError={
							!isAccountsLoading &&
							(!accounts || accounts.length === 0 || currentConfiguration?.accountNumber === null)
						}
						errorText={
							!isAccountsLoading && (!accounts || accounts.length === 0)
								? noAccountText
								: NewOrderSelectAccountError
						}
						label={SelectAccountText}
						dataTestId="newOrder-select-account"
						required
						disableClearable
					/>
				</FormControl>

				<FormControl
					size="small"
					required
					fullWidth
				>
					<AutoComplete
						key={currentConfiguration?.billToNumber?.billToId ?? ""}
						options={currentConfiguration?.accountNumber?.billTos ?? []}
						onMouseDownCapture={handleMouseDownCapture}
						getOptionLabel={(option: BillToInfoViewModel) => {
							const projectName =
								option.projectName && option.projectName.trim() !== ""
									? option.projectName + " - "
									: "";
							const logisticsMode = option.logisticsMode
								? " - " + convertToTitleCase(option.logisticsMode)
								: "";
							return option
								? `${projectName}${option.city}, ${option.state}${logisticsMode} - ${option.billToId}`
								: "";
						}}
						isLoading={isAccountsLoading}
						onChange={handleBillToChange}
						value={currentConfiguration?.billToNumber ?? ""}
						isError={currentConfiguration?.billToNumber === null}
						errorText={NewOrderSelectBillToError}
						label={SelectBillToText}
						dataTestId="newOrder-select-billTo"
						PopperComponent={CustomPopper}
						required
						disableClearable
					/>
				</FormControl>

				{updateButtons && (
					<>
						<Button
							variant="outlined"
							data-testid="update-global-attributes"
							onClick={() => {}}
							startIcon={<EditIcon />}
						>
							{NewOrderUpdateGroup}
						</Button>
						<Button
							onClick={handleRemoveGroup}
							variant="text"
							data-testid="remove-group"
						>
							<DeleteIcon />
							{NewOrderRemoveGroup}
						</Button>
					</>
				)}
			</AccountBillToContentWrapper>
		</>
	);
};

export default AccountBillToContent;
