import { CharacterLimitExceeded, RequiredFieldNotice } from "constants/text";
import { NewOrdersDialog } from "../NewOrders.styles";
import { IsLoadingBody, SaveDraftModalBody, SaveDraftModalButtons, SaveDraftModalHeader } from "./SaveDraft.styles";
import {
	ComeBackToOrderText,
	DraftNamePlaceholder,
	DuplicateDraftTitleError,
	InputDraftTitleError,
	ReplacePreviouslySavedDraftText,
	SaveDraftHangTightText,
	SaveDraftSuccess,
	SaveDraftSuccessfullySavedText,
	SaveDraftText,
	SpacesOnlyTitleError
} from "../constants";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Close from "@mui/icons-material/Close";
import { Button, LinearProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useGetDraftOrdersQuery, useSaveDraftOrderMutation } from "features/orderApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import type { DialogProps } from "@mui/material";
import HelperText from "components/Form/HelperText/HelperText";
import { submittingNewOrderActions } from "features/submittingNewOrder";

interface SaveDraftModalProps {
	saveDraftModalIsOpen: boolean;
	handleOpenCloseSaveDraftModalToggle: () => void;
	firstButtonText: string;
	secondButtonText: string;
}

const SaveDraftModal = ({
	saveDraftModalIsOpen,
	handleOpenCloseSaveDraftModalToggle,
	firstButtonText,
	secondButtonText
}: SaveDraftModalProps) => {
	const { data: drafts } = useGetDraftOrdersQuery({}, { refetchOnMountOrArgChange: true });
	const draftOrder = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);
	const [draftTitle, setDraftTitle] = useState(draftOrder?.draftName ?? "");
	const [errorText, setErrorText] = useState<undefined | string>(undefined);
	const [draftTitleHasError, setDraftTitleHasError] = useState(false);
	const [saveDraftOrder, { isLoading, isSuccess, reset, isError }] = useSaveDraftOrderMutation();
	const dispatch = useDispatch();
	const [isSavedDraft, setIsSavedDraft] = useState(draftOrder?.draftName && draftOrder.draftName !== "");
	const handleOpenCloseToggle = useCallback(() => {
		handleOpenCloseSaveDraftModalToggle();
		setErrorText(undefined);
		reset();
	}, [handleOpenCloseSaveDraftModalToggle, reset]);

	useEffect(() => {
		if (isSuccess) {
			setTimeout(() => {
				dispatch(submittingNewOrderActions.updateDraftName(draftTitle));
				handleOpenCloseToggle();
			}, 4000);
		} else if (isError) {
			handleOpenCloseToggle();
			dispatch(submittingNewOrderActions.updateDraftError("ERROR SAVING DRAFT"));
		}
	}, [isSuccess, isError, dispatch, draftTitle, handleOpenCloseToggle]);

	const submitDraft = async () => {
		const draft = {
			...draftOrder,
			draftName: btoa(draftTitle)
		};
		try {
			await saveDraftOrder(draft);
			setIsSavedDraft(true);
			dispatch(submittingNewOrderActions.clearDraftError());
		} catch (error: any) {
			console.error("error:", error);
			handleOpenCloseToggle();
			dispatch(submittingNewOrderActions.updateDraftError(error));
		}
	};

	const checkForErrors = () => {
		const foundDraftSimilarTitle = drafts?.find(
			(draft) => draft.draftName?.toUpperCase() === draftTitle.trim().toUpperCase()
		);
		if (draftTitle === "" && draftTitle.length <= DRAFT_TITLE_MAX_CHAR) {
			setDraftTitleHasError(true);
			setErrorText(InputDraftTitleError);
		} else if (draftTitle.split("")[0] === " " && draftTitle.length <= DRAFT_TITLE_MAX_CHAR) {
			setDraftTitleHasError(true);
			setErrorText(SpacesOnlyTitleError);
		} else if (foundDraftSimilarTitle && draftTitle.length <= DRAFT_TITLE_MAX_CHAR) {
			if (foundDraftSimilarTitle.draftOrderId === draftOrder?.draftOrderId) {
				submitDraft();
			} else {
				setDraftTitleHasError(true);
				setErrorText(DuplicateDraftTitleError);
			}
		} else if (draftTitle.length <= DRAFT_TITLE_MAX_CHAR) {
			submitDraft();
		}
	};

	const DRAFT_TITLE_MAX_CHAR = 50;

	let content;
	if (isLoading) {
		content = (
			<IsLoadingBody>
				<h6>{SaveDraftHangTightText}</h6>
				<div>
					<span>{draftTitle}</span>
					<LinearProgress style={{ width: "100%" }} />
				</div>
			</IsLoadingBody>
		);
	} else if (isSuccess) {
		content = (
			<IsLoadingBody>
				<h6>{SaveDraftSuccessfullySavedText}</h6>
				<div>
					<span>{draftTitle}</span>
					<LinearProgress
						variant="determinate"
						style={{ width: "100%" }}
						value={100}
					/>
					<div>
						<CheckCircleOutline />
						<span>{SaveDraftSuccess}</span>
					</div>
				</div>
			</IsLoadingBody>
		);
	} else {
		content = (
			<>
				<p>{RequiredFieldNotice}</p>
				<TextField
					label={DraftNamePlaceholder}
					value={draftTitle}
					data-testid="save-draft-input"
					onChange={(e) => {
						setDraftTitleHasError(false);
						setDraftTitle(e.target.value);
					}}
					sx={{ marginBottom: "4px" }}
				/>
				<HelperText
					isError={draftTitle.length > DRAFT_TITLE_MAX_CHAR || draftTitleHasError}
					text={
						draftTitleHasError
							? errorText
							: draftTitle.length > DRAFT_TITLE_MAX_CHAR
								? CharacterLimitExceeded
								: ""
					}
					characterLimit={{
						currentLength: draftTitle.length,
						limit: DRAFT_TITLE_MAX_CHAR,
						isLimitError: draftTitle.length > DRAFT_TITLE_MAX_CHAR
					}}
				/>
				<SaveDraftModalButtons>
					<Button
						variant="outlined"
						onClick={handleOpenCloseToggle}
					>
						{secondButtonText}
					</Button>
					<Button
						variant="contained"
						onClick={checkForErrors}
					>
						{firstButtonText}
					</Button>
				</SaveDraftModalButtons>
			</>
		);
	}

	const handleDialogClose: DialogProps["onClose"] = (event, reason) => {
		if (reason && reason === "backdropClick" && (isSuccess || isLoading)) return;
		handleOpenCloseToggle();
	};

	return (
		<NewOrdersDialog
			open={saveDraftModalIsOpen}
			onClose={handleDialogClose}
		>
			<SaveDraftModalHeader>
				<div>
					<h6>{SaveDraftText}</h6>
					{!isLoading && !isSuccess && (
						<p>{isSavedDraft ? ReplacePreviouslySavedDraftText : ComeBackToOrderText}</p>
					)}
				</div>
				{!isLoading && !isSuccess && <Close onClick={handleOpenCloseToggle} />}
			</SaveDraftModalHeader>
			<SaveDraftModalBody isError={draftTitleHasError || draftTitle.length > DRAFT_TITLE_MAX_CHAR}>
				{content}
			</SaveDraftModalBody>
		</NewOrdersDialog>
	);
};

export default SaveDraftModal;
