import { ReasonDamage, ReasonFinishOrSurfaceDefects, ReasonWrongOrMissingProduct } from "./text";

export const REASONS_FOR_REPLACEMENT = [
	{
		id: 1,
		replacementReason: `${ReasonDamage}`
	},
	{
		id: 2,
		replacementReason: `${ReasonFinishOrSurfaceDefects}`
	},
	{
		id: 3,
		replacementReason: `${ReasonWrongOrMissingProduct}`
	}
] as const;

export const REPLACEMENT_REASON_CODES = [
	{
		id: 1,
		replacementReason: `${ReasonDamage}`,
		reasonCode: "DD11",
		reasonDescription: "Damage noticed at time of delivery ",
		reasonSecondaryText: "(as noted in the Proof of Delivery (POD) paperwork)"
	},
	{
		id: 2,
		replacementReason: `${ReasonDamage}`,
		reasonCode: "DD15",
		reasonDescription: "Water damage at time of delivery",
		reasonSecondaryText: ""
	},
	{
		id: 3,
		replacementReason: `${ReasonDamage}`,
		reasonCode: "DD14",
		reasonDescription: "Damage due to insufficient or missing packaging",
		reasonSecondaryText: "(e.g. crushed corners due to missing corner protectors, insufficient tape)"
	},
	{
		id: 4,
		replacementReason: `${ReasonDamage}`,
		reasonCode: "OD11",
		reasonDescription: "Product cracked at joint, seam, or materials; no damage to packaging",
		reasonSecondaryText: "(e.g. joint separation, hardwood cracks)"
	},
	{
		id: 5,
		replacementReason: `${ReasonDamage}`,
		reasonCode: "OC22",
		reasonDescription: "Product warped, twisted, bent, or distorted",
		reasonSecondaryText: "(e.g. moldings, runners, panels, doors)"
	},
	{
		id: 6,
		replacementReason: `${ReasonDamage}`,
		reasonCode: "OC21",
		reasonDescription: "Product constructed poorly",
		reasonSecondaryText:
			"(e.g. staple blowouts, screws are stripped or broken, fasteners are threaded/non-threaded)"
	},
	{
		id: 7,
		replacementReason: `${ReasonDamage}`,
		reasonCode: "OC23",
		reasonDescription: "Defective hardware",
		reasonSecondaryText: "(e.g. hinges, runners, waste-baskets, etc. not operating as designed)"
	},
	{
		id: 8,
		replacementReason: `${ReasonDamage}`,
		reasonCode: "DD13",
		reasonDescription: "Non-water damage to packaging or product",
		reasonSecondaryText: ""
	},
	{
		id: 9,
		replacementReason: `${ReasonDamage}`,
		reasonCode: "DD12",
		reasonDescription: "There is something else wrong",
		reasonSecondaryText: ""
	},
	{
		id: 10,
		replacementReason: `${ReasonFinishOrSurfaceDefects}`,
		reasonCode: "OD12",
		reasonDescription: "Scratch or dents to any surface",
		reasonSecondaryText: ""
	},
	{
		id: 11,
		replacementReason: `${ReasonFinishOrSurfaceDefects}`,
		reasonCode: "OA31",
		reasonDescription: "Inconsistent finish",
		reasonSecondaryText: "(e.g. door does not match the frame, shiny or dull spots, streaks, unfinished wood)"
	},
	{
		id: 12,
		replacementReason: `${ReasonFinishOrSurfaceDefects}`,
		reasonCode: "OA32",
		reasonDescription: "Dirt/debris in finish",
		reasonSecondaryText: "(e.g. dirt, dust, bubbles in the finish or clear coat)"
	},
	{
		id: 13,
		replacementReason: `${ReasonFinishOrSurfaceDefects}`,
		reasonCode: "OA33",
		reasonDescription: "Finish or material peeling or failing",
		reasonSecondaryText: "(e.g. laminate peeling or clear-coat failing)"
	},
	{
		id: 14,
		replacementReason: `${ReasonFinishOrSurfaceDefects}`,
		reasonCode: "OM43",
		reasonDescription: "Received product in wrong color",
		reasonSecondaryText: "(e.g. wrong finish, wrong clear-coat sheen)"
	},
	{
		id: 15,
		replacementReason: `${ReasonFinishOrSurfaceDefects}`,
		reasonCode: "OA34",
		reasonDescription: "Something else in the appearance is incorrect",
		reasonSecondaryText: "(e.g. over-sanding, dips in material, burns, undesirable wood characteristics)"
	},
	{
		id: 16,
		replacementReason: `${ReasonWrongOrMissingProduct}`,
		reasonCode: "OM44",
		reasonDescription: "Missing part or incorrect quantity",
		reasonSecondaryText: "(e.g. ordered 3 pieces of molding and only received 2)"
	},
	{
		id: 17,
		replacementReason: `${ReasonWrongOrMissingProduct}`,
		reasonCode: "DM11",
		reasonDescription: "Package not delivered",
		reasonSecondaryText: "(as noted in the Proof of Delivery (POD) paperwork)"
	},
	{
		id: 18,
		replacementReason: `${ReasonWrongOrMissingProduct}`,
		reasonCode: "OM42",
		reasonDescription: "Wrong wood species",
		reasonSecondaryText: "(i.e. wrong wood type)"
	},
	{
		id: 19,
		replacementReason: `${ReasonWrongOrMissingProduct}`,
		reasonCode: "DM12",
		reasonDescription: "Received wrong product",
		reasonSecondaryText: "(i.e. received product that was not ordered)"
	},
	{
		id: 20,
		replacementReason: `${ReasonWrongOrMissingProduct}`,
		reasonCode: "OM41",
		reasonDescription: "Wrong size",
		reasonSecondaryText: "(i.e. dimensions are incorrect)"
	}
] as const;

// LEAVING BELOW IN CASE WE DECIDE TO MAKE FILE UPLOAD REQUIREMENT CONDITIONAL BASED ON REASON CODES
// export const REQ_REASONS_FOR_MEDIA = [
// 	"OD11",
// 	"OD12",
// 	"OC21",
// 	"OC22",
// 	"OC23",
// 	"OA31",
// 	"OA32",
// 	"OA33",
// 	"OA34",
// 	"OM41",
// 	"OM42",
// 	"OM43",
// 	"OM44"
// ];
