import styled from "@emotion/styled";
import { Alert } from "@mui/material";

interface ReplacementUploadStatusTextProps {
	error: string | undefined;
}
export const ReplacementUploadStatusText = styled.p<ReplacementUploadStatusTextProps>(({ error }) => ({
	color: error ? "var(--red-700)" : "var(--cwg-blue-900)"
}));

export const ReplacementAttachmentView = styled.div({
	marginBottom: ".5rem"
});

export const MaxFilesAlert = styled(Alert)({
	fontFamily: "Gibson Medium",
	color: "var(--red-700)",
	position: "relative",
	p: {
		margin: "0 2.75rem 0 0"
	},
	span: {
		position: "absolute",
		top: ".875rem",
		right: "1.25rem",
		padding: 0,
		cursor: "pointer",
		color: "var(--red-700)",
		background: "none",
		border: "none"
	}
});
