import { Button } from "@mui/material";
import { ActionButtonContainer } from "components/Dialog/dialog.styles";
import { ModalCancel, ModalSave } from "constants/text";

interface FooterActionsProps {
	handleClose?: () => void;
	handleConfirm?: () => void;
	cancelText?: string;
	confirmText?: string;
	submitsForm?: boolean;
	isAddNewAddressModal?: boolean;
}
const FooterActions = ({
	handleClose,
	handleConfirm,
	cancelText = ModalCancel,
	confirmText = ModalSave,
	submitsForm = false,
	isAddNewAddressModal = false
}: FooterActionsProps) => (
	<ActionButtonContainer>
		<Button
			variant="outlined"
			data-testid="close-modal-button"
			data-id={isAddNewAddressModal && "replacement-review-new-address-cancel"}
			onClick={handleClose}
		>
			{cancelText}
		</Button>
		<Button
			variant="contained"
			onClick={handleConfirm}
			data-testid="submit-modal"
			type={submitsForm ? "submit" : "button"}
			data-id={isAddNewAddressModal && "replacement-review-new-address-save"}
		>
			{confirmText}
		</Button>
	</ActionButtonContainer>
);

export default FooterActions;
