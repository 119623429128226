import styled from "@emotion/styled";
import { Button, ListItemButton } from "@mui/material";
import Link from "@mui/material/Link";

interface AppNavigationProps {
	subMenuOpen?: boolean;
}
export const NavigationPlaceNewOrderButton = styled(Button)({
	backgroundColor: "var(--orange-500)",
	"&:hover": {
		backgroundColor: "var(--orange-800)"
	}
});

export const NavigationItemButton = styled(ListItemButton)(({ theme }) => ({
	justifyContent: "flex-start",
	color: theme.project.colors.fontColors.contrast,
	gap: "24px",
	padding: "4px",
	alignItems: "center",
	"&:hover": {
		borderRadius: "1.5rem",
		backgroundColor: "#005982"
	},
	"&.Mui-selected": {
		"&:hover": {
			borderRadius: "1.5rem",
			backgroundColor: "#005982"
		},
		borderRadius: "1.5rem",
		backgroundColor: "#005982"
	}
}));

export const SubNavigationButton = styled(ListItemButton)(({ theme }) => ({
	justifyContent: "flex-start",
	color: theme.project.colors.fontColors.contrast
}));

export const NavigationContainer = styled.div<AppNavigationProps>(({ subMenuOpen }) => ({
	display: "flex",
	"& > div:nth-of-type(1)": {
		display: subMenuOpen ? "none" : "flex",
		flexDirection: "column"
	},
	"& > div:nth-of-type(2)": {
		display: subMenuOpen ? "flex" : "none",
		flexDirection: "column"
	}
}));

export const MainNavigationLinks = styled.div({
	gap: "8px",
	width: "100%",
	padding: "8px"
});

export const SubNavigationLinks = styled.div({
	gap: "16px",
	width: "100%",
	padding: "8px"
});

export const CloseSubMenuButton = styled(Link)(({ theme }) => ({
	color: theme.project.colors.fontColors.contrast,
	display: "flex",
	textTransform: "uppercase",
	alignItems: "center",
	justifyContent: "flex-start",
	"&:hover": {
		backgroundColor: "transparent",
		cursor: "pointer"
	}
}));

export const MainNavigationIcon = styled.img({
	"&:active": {
		backgroundColor: "#005982",
		borderRadius: "1.5rem",
		padding: "0.5rem"
	}
});
