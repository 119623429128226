import { OrderDetailViewModel } from "data/api/v1/model/order-detail-view-model";
import { checkForAndReplaceNullEmptyWhitespace, convertToTitleCase, toFormattedPrice } from "utils/string";
import { getFileNameBasedOnCurrentDateTime } from "utils/file";
import { LineItemModificationViewModel, OrderType } from "data/api/v1";
import { UnavailableDataPlaceholderText } from "constants/text";
import { formatDateToMMDDYYYY } from "utils/date";
import * as Excel from "exceljs";
import FileSaver from "file-saver";

export function convertOrderDetailsToExcelOrderDetails(orderDetails: OrderDetailViewModel) {
	if (orderDetails) {
		return [
			{
				"Account Number": checkForAndReplaceNullEmptyWhitespace(orderDetails.accountNumber),
				"Order Number": checkForAndReplaceNullEmptyWhitespace(orderDetails.orderNumber),
				"PO Number": checkForAndReplaceNullEmptyWhitespace(orderDetails.poNumber),
				"Custom Built For/Job Name": checkForAndReplaceNullEmptyWhitespace(orderDetails.jobName),
				"Order Type": checkForAndReplaceNullEmptyWhitespace(
					convertToTitleCase(orderDetails.orderType).toUpperCase()
				),
				"Designer Number": checkForAndReplaceNullEmptyWhitespace(orderDetails.designer?.designerNumber),
				"Order Status": !orderDetails.orderType?.includes(OrderType.BILLING_ONLY)
					? checkForAndReplaceNullEmptyWhitespace(convertToTitleCase(orderDetails.status).toUpperCase())
					: UnavailableDataPlaceholderText,
				"Order Date": orderDetails.orderDate
					? formatDateToMMDDYYYY(new Date(orderDetails.orderDate))
					: UnavailableDataPlaceholderText,
				"Order Subtotal": toFormattedPrice(orderDetails.subTotal),
				"Estimated Delivery Date": !orderDetails.orderType?.includes(OrderType.BILLING_ONLY)
					? checkForAndReplaceNullEmptyWhitespace(orderDetails.eta)
					: UnavailableDataPlaceholderText,
				"Cab #": orderDetails.counts?.cabinetCount ?? UnavailableDataPlaceholderText,
				"Cube #": orderDetails.counts?.cubeCount ?? UnavailableDataPlaceholderText,
				"Acc #": orderDetails.counts?.accessoryCount ?? UnavailableDataPlaceholderText
			}
		];
	}

	return [];
}

export function convertOrderDetailsToExcelLineItems(orderDetails: OrderDetailViewModel) {
	if (orderDetails?.lineItems && orderDetails.lineItems.length > 0) {
		return orderDetails.lineItems?.map((od) => {
			return {
				"Line Item Number": checkForAndReplaceNullEmptyWhitespace(od.lineItemNumber),
				"Quantity Ordered": checkForAndReplaceNullEmptyWhitespace(od.quantityOrdered),
				"Quantity Shipped": checkForAndReplaceNullEmptyWhitespace(od.quantityShipped),
				"Quantity Backordered": checkForAndReplaceNullEmptyWhitespace(od.quantityBackordered),
				"Part/SKU": checkForAndReplaceNullEmptyWhitespace(od.sku),
				Description: checkForAndReplaceNullEmptyWhitespace(od.description),
				"Door Style": checkForAndReplaceNullEmptyWhitespace(od.doorStyle),
				Finish: checkForAndReplaceNullEmptyWhitespace(od.finish),
				Modification: modificationsToString(od.modifications),
				"Item Number": checkForAndReplaceNullEmptyWhitespace(od.name),
				"List Price": toFormattedPrice(od.listPrice),
				"Extended Price": toFormattedPrice(od.extendedPrice),
				"Ship Date": od.shipDate ? formatDateToMMDDYYYY(new Date(od.shipDate)) : UnavailableDataPlaceholderText,
				"Truck Number": checkForAndReplaceNullEmptyWhitespace(
					od.truckNumber ?? orderDetails.shippingDetails?.truckNumber
				),
				"Tracking Number": checkForAndReplaceNullEmptyWhitespace(
					od.trackingNumber ?? orderDetails.shippingDetails?.trackingNumber
				),
				Carrier: checkForAndReplaceNullEmptyWhitespace(
					od.carrierName ?? orderDetails.shippingDetails?.carrierName
				)
			};
		});
	}

	return [];
}

export const modificationsToString = (mod: LineItemModificationViewModel[] | undefined | null) => {
	let result = "";

	if (mod && mod.length > 0) {
		mod.forEach((item) => {
			result += `${checkForAndReplaceNullEmptyWhitespace(item.details)}, ${toFormattedPrice(item.price)}, ${checkForAndReplaceNullEmptyWhitespace(item.value)}; `;
		});
	} else {
		result = "N/A";
	}

	return result;
};

export function generateMyOrderDetailsExcel(orderDetails: OrderDetailViewModel) {
	const filePrefix = `${orderDetails.jobName}_PO${orderDetails.poNumber}`;

	const workbook = new Excel.Workbook();
	const detailsWorksheet = workbook.addWorksheet("Order");
	const lineItemsWorksheet = workbook.addWorksheet("Line Items");

	const details = convertOrderDetailsToExcelOrderDetails(orderDetails);

	if (details.length > 0) {
		detailsWorksheet.columns = Object.keys(details[0]).map((col) => {
			return { key: col, header: col };
		});
		detailsWorksheet.addRows(details);
	}

	const lineItems = convertOrderDetailsToExcelLineItems(orderDetails);

	if (lineItems.length > 0) {
		lineItemsWorksheet.columns = Object.keys(lineItems[0]).map((col) => {
			return { key: col, header: col };
		});
		lineItemsWorksheet.addRows(lineItems);
	}

	try {
		workbook.xlsx.writeBuffer().then((data) => {
			const blob = new Blob([data], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
			});
			FileSaver.saveAs(blob, `${getFileNameBasedOnCurrentDateTime(filePrefix)}.xlsx`);
		});
	} catch (e) {
		console.error("Error trying to generate order details xlsx file", e);
	}
}
