/* tslint:disable */

/**
 * Marketplace
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum OrderType {
	UNKNOWN = "UNKNOWN",
	ORIGINAL = "ORIGINAL",
	REPLACEMENT = "REPLACEMENT",
	ADDON = "ADD_ON",
	BACKORDER = "BACKORDER",
	CANCELLED = "CANCELLED",
	BILLING_ONLY = "BILLING_ONLY"
}
