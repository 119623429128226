import styled from "@emotion/styled";
import Logout from "@mui/icons-material/Logout";

export const UserInfoContainer = styled.div({
	div: {
		display: "flex",
		padding: "0.25rem 1rem",
		alignItems: "center",
		justifyContent: "space-between",
		div: {
			flexDirection: "column",
			alignItems: "flex-start",
			padding: 0,
			color: "var(--white-50)"
		}
	}
});

export const UserInfoLogoutIcon = styled(Logout)({
	color: "var(--white-50)"
});

export const UserName = styled.div({
	textTransform: "uppercase"
});
