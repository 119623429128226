import { createTheme, type Theme as MuiTheme } from "@mui/material/styles";
import { allColors } from "styles/themes/common/colors";
import { typography } from "styles/themes/common/typography";
import defaultTheme, {
	combinedTheme as combinedDefaultTheme,
	type DefaultTheme
} from "styles/themes/variants/defaultTheme";

export interface BaseTheme {
	project: {
		borders: {
			presets: {
				primary: string;
				outline: string;
			};
			radius: Record<string, number>;
		};
		colors: {
			allColors: typeof allColors;
			backgroundColors: Record<string, string>;
			fontColors: Record<string, string>;
			primaryColors: Record<string, string>;
			secondaryColors: Record<string, string>;
			semanticColors: Record<string, string>;
		};
		layout: {
			gap: Record<string, number>;
			gutters: Record<string, number>;
		};
		typography: typeof typography;
	};
}

// Type with Widen like Widen<DefaultTheme | DarkTheme> & MuiTheme to add more themes
export type ThemeShape = DefaultTheme & MuiTheme;
export type ThemeKey = "defaultTheme" | "muiTheme";
export type ThemeType = DefaultTheme | {};

// Combines the MUI Theme and our selected theme since MUI also uses Emotion
// TODO: Remove Partial when the theme implementation is finished
export const getConsolidatedTheme = <T extends Partial<BaseTheme>>(theme: T, muiTheme: MuiTheme): T & MuiTheme => ({
	...theme,
	...muiTheme
});

// Used to provide a theme given a theme name
export const themeMap: Record<ThemeKey, ThemeType & MuiTheme> = {
	defaultTheme: getConsolidatedTheme(defaultTheme, createTheme(combinedDefaultTheme)),
	muiTheme: getConsolidatedTheme({}, createTheme({}))
};
