import { Skeleton, Grid } from "@mui/material";
import styles from "pages/order-status-page-styles.module.css";

const OrderDetailsHeaderSkeleton = () => {
	return (
		<div className={styles.orderDetailsHeaderSkeletonContainer}>
			<Grid container>
				<Grid
					item
					xs={12}
				>
					<h5 style={{ margin: "1rem 0", color: "var(--cwg-blue-900" }}>My Orders</h5>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<Grid
						container
						wrap="nowrap"
					>
						<Grid
							item
							xs={4}
						>
							<Skeleton
								animation="wave"
								variant="rounded"
								height={30}
								sx={{ marginBottom: "0.5rem" }}
							/>
							<Skeleton
								animation="wave"
								variant="rounded"
								height={10}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<Grid
						container
						columns={6}
						wrap="nowrap"
					>
						<Grid
							item
							xl={5.65}
							lg={5}
							md={5.5}
							sm={5}
							xs={4.8}
						>
							<Skeleton
								animation="wave"
								variant="rounded"
								height={50}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default OrderDetailsHeaderSkeleton;
