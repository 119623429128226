import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/material";
import styles from "../../pages/replacements-page-styles.module.css";

interface CurrentStepIconProps {
	text: string | null;
	nextRoute: boolean;
}

const CurrentStepIcon = ({ text = "3", nextRoute = true }: CurrentStepIconProps) => {
	return (
		<Box
			sx={{
				display: "flex",
				fontWeight: 400,
				justifyContent: "center",
				alignItems: "center",
				width: 24,
				height: 24,
				borderRadius: "50%",
				backgroundColor: nextRoute ? "var(--white-50)" : "var(--cwg-blue-900)",
				color: nextRoute ? "var(--cwg-blue-900)" : "var(--white-50)",
				fontSize: "var(--font-size-1)",
				marginRight: "5px",
				border: "1px solid var(--cwg-blue-900)"
			}}
		>
			{!text && <CheckIcon fontSize="small" />}
			<p
				data-testid="replacements-circle-icon-text"
				className={styles.circleIconText}
			>
				{text}
			</p>
			{nextRoute}
		</Box>
	);
};

export default CurrentStepIcon;
