import styled from "@emotion/styled";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Accordion, accordionClasses } from "@mui/material";
import { LinkButton } from "components/Common/Link";

export const AddAddress = styled(LinkButton)({
	marginTop: 8
});

export const ReplacementsShipToFormSelect = styled(Select)({
	width: "100%",
	fontFamily: "Gibson Regular, sans-serif",
	color: "var(--text-primary)"
});

export const ReplacementsShipToFormMenuItem = styled(MenuItem)({
	"@media (max-width:600px)": {
		width: "300px",
		whiteSpace: "unset",
		wordBreak: "break-all"
	}
});

export const ReplacementsShipToFormTextField = styled(TextField)({
	color: "var(--text-primary)",
	input: {
		color: "var(--text-primary)",
		fontFamily: "Gibson Regular, sans-serif"
	},
	label: {
		color: "var(--text-secondary)",
		fontFamily: "Gibson Regular, sans-serif"
	},
	width: "100%"
});

export const ShipToAccordion = styled(Accordion)({
	[`&.${accordionClasses.expanded}`]: {
		marginTop: 0
	},
	[`&.${accordionClasses.root}:before`]: {
		content: "none"
	}
});
