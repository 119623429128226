import { NewOrdersContentContainer, NewOrdersLayoutContainer } from "components/NewOrders/NewOrders.styles";
import BuildNewOrderLineItems from "components/NewOrders/BuildNewOrderLineItems/BuildNewOrderLineItems";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { RefObject, useState } from "react";

const BuildOrderPage = () => {
	const [contentOffset, setContentOffset] = useState(0);
	const calcHeightOfHeaderForContentTopOffset = (headerContainer: RefObject<HTMLDivElement>) => {
		setContentOffset(headerContainer.current?.clientHeight ?? 0);
	};
	return (
		<NewOrdersLayoutContainer>
			<NewOrdersHeader
				getContainerElement={calcHeightOfHeaderForContentTopOffset}
				isConfirmation={false}
			/>
			<NewOrdersContentContainer marginTopOffset={contentOffset}>
				<BuildNewOrderLineItems />
			</NewOrdersContentContainer>
		</NewOrdersLayoutContainer>
	);
};

export default BuildOrderPage;
