import Drawer from "@mui/material/Drawer";
import ReplacementsFlyout from "./ReplacementsFlyout";
import ShoppingCartFlyout from "./ShoppingCartFlyout";
import { LineItemGridProps } from "components/OrderDetailsLineItem/OrderDetailsLineItemGrid";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/application.store";
import { useLocation } from "react-router";

type ActiveFlyout = "replacements" | "shoppingCart" | null;

interface FlyoutHandlerProps {
	showButtons: boolean;
	lineItemGridProps?: LineItemGridProps;
	dataTestId?: string;
	showReplacementTrigger?: boolean;
	showShoppingCartTrigger?: boolean;
}

const FlyoutHandler = ({
	showButtons,
	lineItemGridProps,
	dataTestId,
	showReplacementTrigger,
	showShoppingCartTrigger
}: FlyoutHandlerProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [activeFlyout, setActiveFlyout] = useState<ActiveFlyout>(null);
	const navigateTo = useNavigate();
	const params = useParams();
	const location = useLocation();
	const cartItems = useSelector((state: RootState) => state.cart.replacements);

	const showReplacements = useCallback(() => {
		setActiveFlyout("replacements");
		setIsOpen(true);
	}, []);

	const showShoppingCart = useCallback(() => {
		setActiveFlyout("shoppingCart");
		setIsOpen(true);
	}, []);

	const hideFlyouts = useCallback(() => {
		if (
			(location.pathname.includes(`reason/${params?.orderId}`) ||
				location.pathname.includes(`review/${params?.orderId}`)) &&
			cartItems.length <= 0
		) {
			navigateTo(`/replacements/${params?.orderId}`);
		}
		setIsOpen(false);
		setActiveFlyout(null);
	}, [cartItems.length, location.pathname, navigateTo, params?.orderId]);

	useEffect(() => {
		if (showReplacementTrigger) {
			showReplacements();
		}
	}, [showReplacementTrigger, showReplacements]);

	useEffect(() => {
		if (showShoppingCartTrigger) {
			showShoppingCart();
		}
	}, [showShoppingCartTrigger, showShoppingCart]);

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={hideFlyouts}
			{...(dataTestId ? { "data-testid": dataTestId } : {})}
		>
			{activeFlyout === "replacements" && (
				<ReplacementsFlyout
					{...(lineItemGridProps ?? {})}
					addToOrder={showShoppingCart}
					closeDrawer={hideFlyouts}
				/>
			)}

			{activeFlyout === "shoppingCart" && (
				<ShoppingCartFlyout
					showButtons={showButtons}
					edit={showReplacements}
					closeDrawer={hideFlyouts}
				/>
			)}
		</Drawer>
	);
};

export default FlyoutHandler;
