export const NewOrderModalImportCSV = "Import CSV";
export const NewOrderModalDisclaimerText = "Create your item list by providing CSV files.";
export const NewOrderModalSupportedFiles = "CSV";
export const NewOrderModalDragFile = "Drag your file here or";
export const NewOrderModalLoadingText = "Hang tight while we read your file...";
export const NewOrdersMaxUploadsError =
	"Max limit of 1 CSV file exceeded. Please select no more than 1 file to import.";
export const NewOrderFileIncorrectTypeText = "Unsupported file! File must be CSV. Please try again.";
export const NewOrderSelectedFiles = "Selected File";

export const NewOrderUnsupportedFile = "Unsupported file! File must be CSV. Please try again.";

export const NewOrderContinueButton = "Continue";

export const NewOrderImportFileError = "The file you imported has issues and cannot be opened. Please try again.";

export const NewOrderIncomingText = "Incoming";

export const NewOrderBuildOrderLabel = "Build Order";

export const NewOrderShippingDetailsLabel = "Enter Shipping";
export const ReviewAndPlaceOrder = "Review and Place Order";

export const NewOrderBack = "BACK";

export const NewOrderContinue = "CONTINUE";

export const NewOrderSubmitOrder = "SUBMIT ORDER";

export const NewOrderPriceEstimate = "Generate price estimate";

export const NewOrderBuildGridFinishColumnHeader = "Finish";

export const NewOrderBuildGridDoorStyleColumnHeader = "Door Style";

export const NewOrderBuildGridQuantityColumnHeader = "Qty";

export const NewOrderViewPriceEstimateTitle = "View Price Estimate";
export const NewOrderViewPriceEstimateListPriceColumnHeader = "List Price";
export const NewOrderViewPriceEstimateExtendedPriceColumnHeader = "Extended Price";
export const NewOrderViewPriceEstimateEstimatedSubtotalHeader = "Estimated Subtotal";
export const NewOrderViewPriceEstimatePromotionalPricingText = "promotional pricing not included.";
export const NewOrderViewPriceEstimateEstimatedSubtotal = "Estimated Subtotal";

export const NewOrderSaveButton = "Save";

export const NewOrderCancelButton = "Cancel";

export const NewOrderShippingMethod = "Shipping Method";
export const NewOrderSelectAccountError = "Please Select an Account # to Select a Bill To #.";
export const NewOrderSelectBillToError = "Please Select a Bill To #.";

// SAVE DRAFT MODAL
export const SaveDraftText = "Save Draft";
export const ComeBackToOrderText = "Come back to this order when you're ready.";
export const ReplacePreviouslySavedDraftText = "Replace your previously saved draft.";
export const DraftNamePlaceholder = "Draft Name *";

export const SelectAnAddress = "Select an Address";
export const NewOrderDesignerNumberText = "Select a Designer # (optional)";
export const ShowMeText = "Show Me";
export const ExactMatchText = "Exact Matches";
export const PartialMatchText = "Partial Matches";
export const DateRangeErrorMessage =
	"Your custom date range must be 90 days or less to complete a partial match search. Please adjust your date range and try searching again.";

export const NewOrdersHeaderSummaryDetailsProductLines = "Product Line(s)";
export const NewOrdersHeaderSummaryDetailsCabinetsCount = "Cab #";
export const NewOrdersHeaderSummaryDetailsAccessoriesCount = "Acc #";
export const NewOrdersHeaderSummaryDetailsCubeCount = "Cube #";

export const NewOrderReviewPageTitle = "Review and Place Order";

export const NewOrderSaveDraft = "Save Draft";

export const NewOrderOrderDetailsTitle = "Order Details";

export const NewOrderReviewDesignerLabel = "Designer #";

export const NewOrderReviewPOLabel = "PO #";

export const NewOrderReviewJobNameLabel = "Job Name";

export const NewOrderShipToTitle = "Ship To";

export const NewOrderAddressTitle = "Address";

export const NewOrderRequestedDeliveryDate = "Requested Date";

export const NewOrderDeliveryContact = "Delivery Contact";

export const NewOrderDeliverContact = "Delivery Contact";

export const NewOrderShippingLabelNotes = "Shipping Label Notes";

export const NewOrderUnavailableDataPlaceholderText = "--";

export const NewOrderOrderListHeader = "Order List";

export const NewOrderLineItemsInOrderText = "Line Items";

export const NewOrderSubtotalLabel = "Estimated Subtotal";

export const NewOrderAccountNumber = "Account #";

export const NewOrderThanksHeader = "Thank you!";
export const NewOrderThanksSubheader =
	"Your order has been submitted. Updates will typically be available in Tandem within 10 minutes. If any further information is needed to process your order, we will reach out directly.";
export const NewOrderButton = "Return to Your Orders";
export const NewOrderConfirmationOrderNumber = "Order #";
export const NewOrderConfirmationOrderPending = "Pending";
export const NewOrderOrderDate = "Order Date";
export const NewOrderNewOrderLabel = "New Order";
export const NewOrderExport = "Export confirmation";

export const SaveDraftHangTightText = "Hang tight while we save your file....";
export const SaveDraftSuccessfullySavedText = "We've successfully saved your draft!";
export const SaveDraftSuccess = "Success!";
export const InputDraftTitleError = "Please enter a draft name to proceed.";
export const SpacesOnlyTitleError = "Draft name must contain characters. Please enter a valid draft name.";
export const DuplicateDraftTitleError = "This draft name already exists. Please enter a different draft name.";
export const DraftErrorTitle = "We're sorry, we couldn't save your draft.";
export const DraftSaveAndExit = "SAVE AND EXIT";
export const DraftExitOrder = "EXIT ORDER";

export const NewOrderRequestedDateLabel = "Requested Date";
export const NewOrderRequestedDateLaterDateButton = "Does your order need to be delayed to a later date?";
export const NewOrderRemoveButton = "Remove";
export const NewOrderRequestedDateHelperText = "We will schedule the best available date upon review of your request.";
export const NewOrderRemoveItem = "YES, REMOVE ITEM";
export const NewOrderDialogSubtitle = "This item will be removed from your order list and can't be undone.";
export const NewOrderDialogTitle = "Are you sure you'd like to remove this Item?";
export const NewOrderEdit = "Edit";
export const NewOrderOpen = "Open";
export const NewOrderSubtitle = "To add line items to your order:";
export const NewOrderEmptyBullet1 = "Import items from a CSV";
export const NewOrderEmptyBullet2 = "Add a Global Attribute Group";
export const SaveDraftTableDisclaimerText =
	"Use a draft when you want to come back to it later to place an order. A draft will no longer be available when an order is submitted or after 6 months of inactivity.";
export const SaveDraftTableSearchText = "Search Drafts by Name or Brand";
export const SaveDraftNoOrdersHeader = "You don't have any saved draft(s)";
export const SaveDraftNoOrdersSubheader = "When you save orders they will appear here.";
export const SaveDraft400ErrorHeader = "Uh oh, something went wrong!";
export const SaveDraft400ErrorSubheader = "To see your draft(s), please try:";
export const SaveDraft400ErrorBullet = "Refreshing the page";
export const SaveDraft400ErrorContact = "Please contact Care should the issue continue.";
export const SaveDraftDraftTab = "DRAFTS";
export const SaveDraftTemplateTab = "TEMPLATE";
export const SaveDraftTHeadDate = "Date";
export const SaveDraftTHeadDraft = "Draft";
export const SaveDraftTHeadAccount = "Account";
export const SaveDraftTHeadDesigner = "Designer";
export const SaveDraftTHeadProductLine = "Product Line";
export const NewOrderUpdateGroup = "UPDATE GLOBAL ATTRIBUTES";
export const NewOrderRemoveGroup = "REMOVE GROUP";
export const NewOrderConfirmRemoveGroup = "YES, REMOVE GROUP";
export const NewOrderRemoveGroupSubtitle =
	"The Global Attribute Group and it's items(s) will be removed from your order list and can't be undone.";
export const NewOrderRemoveGroupTitle = "Are you sure you'd like to remove this Global Attribute Group?";
