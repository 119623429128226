import { Dispatch, SetStateAction, useMemo } from "react";
import ChipDropdownMenu, { ChipDropdownMenuProps } from "./ChipDropdownMenu";
import styles from "containers/Orders/order-styles.module.css";
import { OrderStatus, UserDetailsResponse, OrderSummaryViewModel } from "data/api/v1";
import { NoDesignerText } from "constants/text";
import { displayDesigner } from "utils/order";
import { FilterType } from "./filterType";
import { arraysEqual } from "utils/array";
import { FilterOpts } from "pages/OrderPages/OrderListPage/OrderListPage";
import OpenOrdersToggle from "./OpenOrdersToggle";

const SearchBarFilters = ({ isMobile, orders, userDetails, filterOptions, setFilterOptions }: SearchBarFilterProps) => {
	const orderTypes = useMemo(
		() =>
			orders
				.reduce((accum: string[], order) => {
					if (order.orderType) {
						accum.push(order.orderType.replace(/_/g, " "));
					}
					return accum;
				}, [])
				.filter((item, index, arr) => arr.indexOf(item) === index)
				.sort(),
		[orders]
	);

	const orderStatuses = useMemo(() => {
		const orderStatusChronological: string[] = Object.values(OrderStatus);
		const statuses = orders
			.map((order) => order.status)
			.filter((item, index, arr) => arr.indexOf(item) === index)
			.sort((a: OrderStatus | undefined, b: OrderStatus | undefined) => {
				const indexA = orderStatusChronological.indexOf(String(a));
				const indexB = orderStatusChronological.indexOf(String(b));
				return indexA - indexB;
			});
		return statuses.reduce((accum: string[], status: OrderStatus | undefined) => {
			if (status) {
				accum.push(status.replace(/_/g, " "));
			}

			return accum;
		}, []);
	}, [orders]);

	const designerNumber = useMemo(
		() =>
			orders
				.map((order: OrderSummaryViewModel) => {
					return displayDesigner(order.designer) ?? NoDesignerText;
				})
				.filter((item: string, index: number, arr: string[]) => arr.indexOf(item) === index)
				.sort(),
		[orders]
	);

	const isActiveAccountNumbers = useMemo(
		() =>
			userDetails?.user?.accounts
				?.slice()
				.filter((account) => account.isActive)
				.map((account) => {
					return {
						label: `${account.number}` + (account.description ? ` - ${account.description}` : ""),
						...account
					};
				})
				.sort((a, b) => a.label.localeCompare(b.label))
				.map((account) => account.label)
				.filter((item, index, arr) => arr.indexOf(item) === index),
		[userDetails]
	);

	const isInactiveAccountNumbers = useMemo(
		() =>
			userDetails?.user?.accounts
				?.slice()
				.filter((account) => !account.isActive)
				.map((account) => {
					return {
						label: `${account.number}` + (account.description ? ` - ${account.description}` : ""),
						...account
					};
				})
				.sort((a, b) => a.label.localeCompare(b.label))
				.map((account) => account.label)
				.filter((item, index, arr) => arr.indexOf(item) === index),
		[userDetails]
	);

	const brandsFromOrders = useMemo(() => orders.map((order: any) => order.parentBrand), [orders]);

	const userBrands = useMemo(
		() =>
			brandsFromOrders
				.map((order: any) => order?.replace(/_/g, " "))
				.filter((item: any, index: any, arr: any) => arr.indexOf(item) === index)
				.sort(),
		[brandsFromOrders]
	);

	const onFilterChange = (id: string, values: string[]) => {
		setFilterOptions((prev: FilterOpts) => ({
			...prev,
			[id]: values
		}));
	};

	const openOrderStatuses = useMemo(
		() =>
			orderStatuses?.filter(
				(status: string) =>
					status !== OrderStatus.DELIVERED &&
					status !== OrderStatus.CANCELLED &&
					status !== OrderStatus.UNKNOWN
			),
		[orderStatuses]
	);

	const hasClosedOrderStatuses = useMemo(
		() =>
			orderStatuses?.some(
				(status: string) =>
					status === OrderStatus.DELIVERED ||
					status === OrderStatus.CANCELLED ||
					status === OrderStatus.UNKNOWN
			),
		[orderStatuses]
	);

	const hasOpenAndClosedStatuses = hasClosedOrderStatuses && openOrderStatuses?.length > 0;

	const isOpenOrdersToggledOn = useMemo(
		() => arraysEqual(openOrderStatuses, filterOptions?.status) && hasOpenAndClosedStatuses,
		[filterOptions?.status, hasOpenAndClosedStatuses, openOrderStatuses]
	);

	const handleOpenOrdersToggle = () => onFilterChange("status", isOpenOrdersToggledOn ? [] : openOrderStatuses);

	const openOrdersToggle = hasOpenAndClosedStatuses ? (
		<OpenOrdersToggle
			isOpenOrdersToggledOn={isOpenOrdersToggledOn}
			handleOpenOrdersToggle={handleOpenOrdersToggle}
		/>
	) : null;

	const hasErrors = useMemo(
		() =>
			orders.some(
				(order) => order.status === OrderStatus.SUBMITTEDWITHERRORS || order.status === OrderStatus.HOLD
			),
		[orders]
	);

	const convertOrderStatuses = () => {
		return orderStatuses.map((status) => {
			return {
				value: status,
				error: (status === "SUBMITTED WITH ERRORS" || status === "HOLD") && hasErrors
			};
		});
	};

	const filterDetails: FilterDetails[] = [
		{
			selectedItems: filterOptions?.accounts,
			onChange: (v) => onFilterChange("accounts", v),
			filterType: FilterType.ACCOUNT,
			items: [],
			dataTestId: `all-accounts-filter-chip${isMobile && "-mobile"}`,
			isSearchable: true,
			hasWarning: false,
			inactiveAccounts: isInactiveAccountNumbers ?? [],
			activeAccounts: isActiveAccountNumbers ?? [],
			children: undefined,
			isVisible: userDetails?.user?.role !== "CARE",
			GTMIdentifier: "account-filter-chip"
		},
		{
			selectedItems: filterOptions?.designers,
			onChange: (v) => onFilterChange("designers", v),
			filterType: FilterType.DESIGNER,
			items: designerNumber,
			dataTestId: `all-designers-filter-chip${isMobile && "-mobile"}`,
			isSearchable: true,
			hasWarning: false,
			inactiveAccounts: [],
			activeAccounts: [],
			children: undefined,
			isVisible: true,
			GTMIdentifier: "designer-filter-chip"
		},
		{
			selectedItems: filterOptions?.brands,
			onChange: (v) => onFilterChange("brands", v),
			filterType: FilterType.BRAND,
			items: userBrands,
			dataTestId: `brands-filter-chip${isMobile && "-mobile"}`,
			isSearchable: false,
			hasWarning: false,
			inactiveAccounts: [],
			activeAccounts: [],
			children: undefined,
			isVisible: true,
			GTMIdentifier: "brand-filter-chip"
		},
		{
			selectedItems: filterOptions?.status,
			onChange: (v) => onFilterChange("status", v),
			filterType: FilterType.ORDER_STATUS,
			items: convertOrderStatuses(),
			dataTestId: `order-status-filter-chip${isMobile && "-mobile"}`,
			isSearchable: false,
			hasWarning: hasErrors,
			inactiveAccounts: [],
			activeAccounts: [],
			children: openOrdersToggle,
			isVisible: true,
			GTMIdentifier: "order-status-filter-chip"
		},
		{
			selectedItems: filterOptions?.types,
			onChange: (v) => onFilterChange("types", v),
			filterType: FilterType.ORDER_TYPE,
			items: orderTypes,
			dataTestId: `order-types-filter-chip${isMobile && "-mobile"}`,
			isSearchable: false,
			hasWarning: false,
			inactiveAccounts: [],
			activeAccounts: [],
			children: undefined,
			isVisible: true,
			GTMIdentifier: "order-type-filter-chip"
		}
	];

	return (
		<div style={{ padding: "1rem 0 .75rem 0", width: "100%", backgroundColor: "var(--white-50)" }}>
			<div
				className={isMobile ? styles.filterFabMobileScroll : styles.filterChipContainer}
				data-testid={`search-filter-container${isMobile && "-mobile"}`}
			>
				{filterDetails.map(
					(filter) =>
						filter.isVisible && (
							<ChipDropdownMenu
								selectedItems={filter.selectedItems}
								onChange={filter.onChange}
								filterType={filter.filterType}
								items={filter.items}
								data-testid={filter.dataTestId}
								inactiveAccounts={filter.inactiveAccounts}
								activeAccounts={filter.activeAccounts}
								hasWarning={filter.hasWarning}
								isSearchable={filter.isSearchable}
								isMobile={isMobile}
								key={filter.filterType}
								GTMIdentifier={filter.GTMIdentifier}
							>
								{filter.children}
							</ChipDropdownMenu>
						)
				)}
			</div>
		</div>
	);
};

export interface FilterDetails extends ChipDropdownMenuProps {
	dataTestId: string;
	isVisible: boolean;
	GTMIdentifier: string;
}

export interface SearchBarFilterProps {
	isMobile: boolean;
	orders: OrderSummaryViewModel[];
	userDetails: UserDetailsResponse | undefined;
	filterOptions: FilterOpts;
	setFilterOptions: Dispatch<SetStateAction<FilterOpts>>;
}

export default SearchBarFilters;
