import { useState } from "react";
import { Box, ButtonGroup, IconButton, Typography } from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import DatePickerCalendarDays from "./DatePickerCalendarDays";

interface DatePickerCalendarProps {
	value: Date;
	firstValidDate?: Date;
	secondValidDate?: Date;
	onChange: (date: Date) => void;
}

const DatePickerCalendar = ({ value, firstValidDate, secondValidDate, onChange }: DatePickerCalendarProps) => {
	const currentDate = value;
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth();
	const currentDay = currentDate.getDate();

	const [selectedDay, setSelectedDay] = useState(currentDay);
	const [selectedMonth, setSelectedMonth] = useState(currentMonth);
	const [selectedYear, setSelectedYear] = useState(currentYear);

	const decreaseMonth = () => {
		if (selectedMonth === 0) {
			setSelectedMonth(11);
			setSelectedYear(selectedYear - 1);
		} else {
			setSelectedMonth(selectedMonth - 1);
		}
	};

	const todayDate = new Date();

	const increaseMonth = () => {
		if (selectedMonth === 11) {
			setSelectedMonth(0);
			setSelectedYear(selectedYear + 1);
		} else {
			setSelectedMonth(selectedMonth + 1);
		}
	};

	const onChangeSelected = (date: Date) => {
		setSelectedDay(date.getDate());
		onChange(date);
	};

	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	];

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "between",
					alignItems: "center",
					px: "1rem"
				}}
			>
				<Box
					sx={{
						flexGrow: 1
					}}
				>
					<div
						style={{
							fontSize: "1rem",
							color: "var(--text-primary)",
							paddingLeft: "1rem"
						}}
					>
						<Typography
							data-testid="date-picker-calendar-month-year"
							variant="subtitle1"
							sx={{
								color: "var(--text-secondary)"
							}}
						>
							{`${months[selectedMonth]} ${selectedYear}`}
						</Typography>
					</div>
				</Box>
				<ButtonGroup>
					<IconButton
						data-testid="previous-month-button"
						onClick={decreaseMonth}
						aria-label="Previous month"
						sx={{
							color: "var(--text-primary)"
						}}
					>
						<ChevronLeft />
					</IconButton>
					<IconButton
						data-testid="next-month-button"
						onClick={increaseMonth}
						aria-label="Next month"
						sx={{
							color: "var(--text-primary)"
						}}
					>
						<ChevronRight />
					</IconButton>
				</ButtonGroup>
			</Box>
			<Box>
				<DatePickerCalendarDays
					month={selectedMonth}
					year={selectedYear}
					day={selectedDay}
					selectedDate={value}
					enableAfterDate={firstValidDate}
					disabledAfterDate={todayDate}
					onSelect={onChangeSelected}
				/>
			</Box>
		</>
	);
};

export default DatePickerCalendar;
