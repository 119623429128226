import { Box, CircularProgress, Paper, Snackbar, Typography } from "@mui/material";
import React from "react";
import styles from "./export-file.module.css";
import CheckCircle from "@mui/icons-material/CheckCircle";

interface Props {
	open: boolean;
	fileLoading: boolean;
	largePDF: boolean;
}

const ExportSnackbar = ({ open, fileLoading, largePDF }: Props) => {
	return (
		<Snackbar
			open={open}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			sx={{ width: "341px", height: "38px" }}
		>
			<Paper
				className={styles.snackbarContainer}
				elevation={6}
				sx={{
					backgroundColor: "var(--gray-900)",
					color: "var(--white-50)",
					display: "flex",
					alignItems: "center"
				}}
			>
				{fileLoading ? (
					<Box className={styles.snackbarContent}>
						<CircularProgress size="28px" />
						{largePDF ? (
							<Box marginLeft="1rem">
								<Typography
									sx={{
										fontFamily: "Gibson Regular",
										fontSize: "14px"
									}}
								>
									Generating PDF
								</Typography>
								<Typography
									sx={{
										fontFamily: "Gibson",
										fontSize: "12px",
										letterSpacing: ".5px"
									}}
								>
									Large exports may take a few minutes to complete.
								</Typography>
							</Box>
						) : (
							<Typography
								className={styles.snackbarText}
								marginLeft="1rem"
								sx={{ fontFamily: "Gibson Regular" }}
							>
								Generating PDF
							</Typography>
						)}
					</Box>
				) : (
					<Box className={styles.snackbarContent}>
						<CheckCircle
							sx={{
								width: "28px",
								height: "28px",
								color: "var(--green-500)"
							}}
						/>
						<Typography
							className={styles.snackbarText}
							marginLeft="1rem"
							sx={{ fontFamily: "Gibson Regular" }}
						>
							PDF Downloaded
						</Typography>
					</Box>
				)}
			</Paper>
		</Snackbar>
	);
};

export default ExportSnackbar;
