import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";
import { LoadingSkeletonProps } from "../../Common/LineItemGrid/LineItemGridStyles";

export const EstimateLoadingSkeletonContainer = styled.div<LoadingSkeletonProps>(({ isLoading }) => ({
	display: isLoading ? "grid" : "none",
	padding: "16px"
}));

export const BuildNewOrderLineItemsHeaderSkeleton = styled.div({
	display: "flex",
	justifyContent: "space-between"
});

export const AccountBillToSkeleton = styled(Skeleton)({
	width: "100%",
	height: "72px"
});

export const NewOrderLabelSkeleton = styled(Skeleton)<LoadingSkeletonProps>(({ isLoading }) => ({
	display: isLoading ? "grid" : "none",
	marginBottom: "8px",
	width: "100%",
	height: "42px"
}));
