import styles from "pages/order-status-page-styles.module.css";
import { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import { AddressWrapper, CommentWrapper } from "components/OrderDetailsSummary/orderDetailsSummary.styles";
import {
	AccessoryCountText,
	CabinetCountText,
	CubeCountText,
	DoorStyleText,
	FinishText,
	OrderDateText,
	OrderNumberText,
	RequestedDateText,
	UnavailableDataPlaceholderText,
	PONumberText
} from "constants/text";
import { OrderDetailViewModel } from "data/api/v1";
import BillToBox from "components/Common/BillToShipToBox/BillToBox/BillToBox";
import ShipToBox from "components/Common/BillToShipToBox/ShipToBox/ShipToBox";
import { useGetAccountsByIdQuery, useGetBillToInfoQuery } from "features/accountApi";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";

const OrderDetailsSummary = ({ orderDetail }: Props) => {
	const [isReadMore, setIsReadMore] = useState(true);

	const dateFormat = (orderDate: string) => {
		return new Date(orderDate).toLocaleDateString(undefined);
	};

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	const [billTo, setBillTo] = useState<BillToInfoViewModel | null>(null);

	const { data: accounts } = useGetAccountsByIdQuery(orderDetail.orderId ?? "", { skip: !orderDetail.orderId });

	const accountId = accounts?.[0]?.accountId;

	const { data: billToInfo } = useGetBillToInfoQuery(
		{ accountId: accountId ?? "", billToId: orderDetail.billToId ?? "" },
		{ skip: !accountId || !orderDetail.billToId }
	);

	useEffect(() => {
		if (billToInfo) {
			setBillTo(billToInfo);
		}
	}, [billToInfo]);

	return (
		<div className={styles.orderDetailsCardContainer}>
			<Grid
				container
				columns={9}
			>
				<Grid
					item
					xl
					lg={1.25}
					md={1.5}
					xs={9}
				>
					<div
						className={styles.orderDetailsColumn}
						data-testid="orderDetails-orderDateColumn"
					>
						<p className={styles.orderDetailsHeader}>{OrderDateText}</p>
						<p className={styles.orderDetailsSubheader}>
							{dateFormat(orderDetail?.orderDate ?? "") ?? UnavailableDataPlaceholderText}
						</p>
					</div>
				</Grid>
				<Grid
					item
					xl
					lg={1.25}
					md={1.5}
					xs={9}
				>
					<div
						className={styles.orderDetailsColumn}
						data-testid="orderDetails-orderNumberColumn"
					>
						<p className={styles.orderDetailsHeader}>{OrderNumberText}</p>
						<p className={styles.orderDetailsSubheader}>
							{orderDetail?.orderNumber ?? UnavailableDataPlaceholderText}
						</p>
					</div>
				</Grid>
				<Grid
					item
					xl
					lg={1.25}
					md={1.5}
					xs={9}
				>
					<div
						className={styles.orderDetailsColumn}
						data-testid="orderDetailsHeader-PONumber"
					>
						<p className={styles.orderDetailsHeader}>{PONumberText}</p>
						<p className={styles.orderDetailsSubheader}>{orderDetail.poNumber}</p>
					</div>
				</Grid>
				<Grid
					item
					xl
					lg={1.25}
					md={1.5}
					xs={9}
				>
					<div
						className={styles.orderDetailsColumn}
						data-testid="orderDetails-doorStyleColumn"
					>
						<p className={styles.orderDetailsHeader}>{DoorStyleText}</p>
						<p className={styles.orderDetailsSubheader}>
							{orderDetail?.doorStyle ?? UnavailableDataPlaceholderText}
						</p>
					</div>
				</Grid>
				<Grid
					item
					xl
					lg={1.25}
					md={1.5}
					xs={9}
				>
					<div
						className={styles.orderDetailsColumn}
						data-testid="orderDetails-finishColumn"
					>
						<p className={styles.orderDetailsHeader}>{FinishText}</p>
						<p className={styles.orderDetailsSubheader}>
							{orderDetail?.finish ?? UnavailableDataPlaceholderText}
						</p>
					</div>
				</Grid>
				<Grid
					item
					xl={1}
					lg={1.25}
					md={1.5}
					xs={9}
				>
					<div
						className={styles.orderDetailsColumn}
						data-testid="orderDetails-requestedDeliveryDateColumn"
					>
						<p className={styles.orderDetailsHeader}>{RequestedDateText}</p>
						{orderDetail?.requestedDeliveryDate ? (
							<p className={styles.orderDetailsSubheader}>
								{dateFormat(orderDetail?.requestedDeliveryDate)}
							</p>
						) : (
							<p className={styles.orderDetailsSubheader}>--</p>
						)}
					</div>
				</Grid>
				<Grid
					item
					xl
					lg={1.3}
					md={1.5}
					xs={9}
				>
					<div
						className={styles.orderDetailsColumn}
						data-testid="orderDetails-cabinetCountColumn"
					>
						<p className={styles.orderDetailsHeader}>{CabinetCountText}</p>
						{orderDetail?.counts?.cabinetCount ? (
							<p className={styles.orderDetailsSubheader}>{orderDetail?.counts?.cabinetCount}</p>
						) : (
							<p className={styles.orderDetailsSubheader}>{UnavailableDataPlaceholderText}</p>
						)}
					</div>
				</Grid>
				<Grid
					item
					xl
					lg={1.25}
					md={1.5}
					xs={9}
				>
					<div
						className={styles.orderDetailsColumn}
						data-testid="orderDetails-accessoryCountColumn"
					>
						<p className={styles.orderDetailsHeader}>{AccessoryCountText}</p>
						{orderDetail?.counts?.accessoryCount ? (
							<p className={styles.orderDetailsSubheader}>{orderDetail?.counts?.accessoryCount}</p>
						) : (
							<p className={styles.orderDetailsSubheader}>{UnavailableDataPlaceholderText}</p>
						)}
					</div>
				</Grid>
				<Grid
					item
					xl
					lg={1.25}
					md={1.5}
					xs={9}
				>
					<div
						className={styles.orderDetailsColumn}
						data-testid="orderDetails-cubeCountColumn"
					>
						<p className={styles.orderDetailsHeader}>{CubeCountText}</p>
						{orderDetail?.counts?.cubeCount ? (
							<p className={styles.orderDetailsSubheader}>{orderDetail?.counts?.cubeCount}</p>
						) : (
							<p className={styles.orderDetailsSubheader}>{UnavailableDataPlaceholderText}</p>
						)}
					</div>
				</Grid>
			</Grid>
			<AddressWrapper>
				<ShipToBox
					orderDetail={orderDetail}
					dataTestIdPrefix="orderDetails"
				/>
				<BillToBox
					billTo={billTo}
					orderType={orderDetail.orderType}
					isNoChargeOrder={orderDetail.isNoChargeOrder}
					orderDetailSubTotal={orderDetail.subTotal}
					hasBorder
					hasMinHeight
					dataTestIdPrefix="orderDetails"
				/>
			</AddressWrapper>
			{orderDetail.comments && orderDetail?.comments?.length > 1 && (
				<CommentWrapper>
					<p
						className="body2"
						data-testid="orderDetails-orderComments"
					>
						{orderDetail?.comments?.length > 1 ? <b className="subtitle2">Comment: </b> : null}
						{isReadMore ? orderDetail?.comments?.slice(0, 250) : orderDetail?.comments}
						{orderDetail?.comments?.length > 250 && (
							<Button
								variant="text"
								sx={{ padding: 0 }}
								onClick={toggleReadMore}
								onKeyDown={toggleReadMore}
								data-testid="orderDetails-commentViewMoreViewLess"
							>
								{isReadMore ? "...View More" : "...View Less"}
							</Button>
						)}
					</p>
				</CommentWrapper>
			)}
		</div>
	);
};
interface Props {
	orderDetail: OrderDetailViewModel;
}
export default OrderDetailsSummary;
