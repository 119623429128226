import { Content, StyledDialog } from "./LeaveFlowDialog.styles";
import { FC } from "react";
import DialogTitle from "../../Dialog/components/DialogTitle";
import { FooterActions } from "../../Dialog";

interface LeaveFlowDialogProps {
	firstButtonText: string;
	handleDialogClose?: () => void;
	handleDialogExitOrder?: () => void;
	handleOpenDraftModal?: () => void;
	dialogState: boolean;
	secondButtonText: string;
	subtitle: string;
	title: string;
}

const LeaveFlowDialog: FC<LeaveFlowDialogProps> = ({
	firstButtonText,
	handleDialogClose,
	handleDialogExitOrder,
	handleOpenDraftModal,
	dialogState,
	secondButtonText,
	subtitle,
	title
}) => {
	return (
		<StyledDialog
			open={dialogState}
			data-testid="leave-flow-dialog"
			onClose={handleDialogClose}
		>
			<DialogTitle
				title={title}
				handleClose={handleDialogClose}
			/>
			<Content>
				<div>{subtitle}</div>
				<FooterActions
					handleClose={handleDialogExitOrder}
					handleConfirm={handleOpenDraftModal}
					cancelText={firstButtonText}
					confirmText={secondButtonText}
				/>
			</Content>
		</StyledDialog>
	);
};

export default LeaveFlowDialog;
