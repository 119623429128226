import styled from "@emotion/styled";
export const ReviewOrderListHeader = styled.div(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle1,
	color: theme.project.colors.fontColors.secondary,
	fontSize: "var(--font-size-3)",
	textTransform: "uppercase",
	"span:nth-of-type(2)": {
		float: "right"
	}
}));

export const ReviewOrderAccountBillToCardContainer = styled.div(({ theme }) => ({
	display: "grid",
	columnGap: "16px",
	padding: "16px 0",
	color: theme.project.colors.fontColors.main,
	gridTemplateColumns: "1fr 1fr",
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr",
		rowGap: "16px"
	}
}));

export const ReviewOrderListComponentHeader = styled.div(({ theme }) => ({
	"& > div:nth-of-type(2)": {
		float: "right",
		marginTop: "-45px"
	}
}));

export const ReviewOrderListLineItemContainer = styled.div(({ theme }) => ({
	marginBottom: 8
}));

export const ReviewOrderSubtotal = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-end",
	marginBottom: 36,
	"&:last-child": {
		marginBottom: 8
	},
	"span:nth-of-type(2)": {
		marginLeft: 16,
		...theme.project.typography.subtitle.subtitle1,
		color: theme.project.colors.fontColors.main,
		lineHeight: "normal"
	}
}));
