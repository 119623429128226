import { useEffect } from "react";
import useSession from "./useSession";
import { useGetByIdQuery } from "features/orderApi";

export const useInitOrderDetail = (orderId: string | undefined) => {
	const [previousOrderId, setPreviousOrderId] = useSession("_cabinet-previous-id", "");

	const { data, isLoading, refetch, error } = useGetByIdQuery(orderId ?? "");

	useEffect(() => {
		refetch();
	}, [refetch]);

	useEffect(() => {
		if (orderId !== undefined) setPreviousOrderId(orderId);
	}, [orderId, setPreviousOrderId]);

	return {
		orderDetail: data,
		isLoading,
		orderId,
		previousOrderId,
		error
	};
};

export default useInitOrderDetail;
