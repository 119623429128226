import { NoOrderDetailsFoundHeader, NoOrderDetailsFoundSubheader } from "constants/text";
import styles from "containers/Orders/order-styles.module.css";
import { Button } from "@mui/material";
const NoOrdersErrorBanner = () => {
	const previousOrderId = sessionStorage.getItem("orderDetailsId");

	const previousPage = window.parent.location.href;

	return (
		<div className={styles.noOrderDetailsErrorBannerContainer}>
			<img
				src="/assets/NoOrdersSadBoxImage.svg"
				alt="No Orders Banner"
				className={styles.noOrdersErrorBannerContainerImage}
				data-testid="no-order-details-error-sad-box-img"
			/>
			<h5 data-testid="no-order-details-error-header">{NoOrderDetailsFoundHeader}</h5>
			<p data-testid="no-order-details-error-subheader">{NoOrderDetailsFoundSubheader}</p>
			<Button
				variant="contained"
				data-testid="back-to-previous-page"
				onClick={(e) => {
					if (previousPage.includes(`/details/${previousOrderId}`)) {
						e.preventDefault();
						window.history.back();
					}
				}}
				href="/"
			>
				{previousPage.includes("/details/" + previousOrderId) && "GO"} BACK TO{" "}
				{previousPage.includes("/details/" + previousOrderId) ? "PREVIOUS ORDER" : "MY ORDERS"}
			</Button>
		</div>
	);
};

export default NoOrdersErrorBanner;
