import styled from "@emotion/styled";
import Close from "@mui/icons-material/Close";

interface LeavePageModalWrapperProps {
	isMobile: boolean;
}

export const LeavePageModalWrapper = styled.div<LeavePageModalWrapperProps>(({ isMobile }) => ({
	width: isMobile ? "257px" : "552px",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	background: "var(--white-50)",
	padding: "1rem 1.5rem 1.5rem 1.5rem",
	fontFamily: "Gibson Regular, sans-serif",
	boxShadow: "1.5rem",
	borderRadius: ".25rem",
	h6: {
		margin: isMobile ? "0 2rem 1rem 0" : "0 0 1rem 0",
		color: "var(--text-secondary)"
	},
	p: {
		fontSize: ".875rem",
		color: "var(--text-secondary)",
		letterSpacing: "0.17px",
		margin: "0 0 1rem 0",
		lineHeight: "20.02px"
	},
	div: {
		display: "flex",
		flexDirection: isMobile ? "column" : "row",
		width: "100%",
		justifyContent: "space-between",
		alignItems: isMobile ? "center" : "none",
		height: isMobile ? "100px" : "auto"
	}
}));

export const CloseModalButton = styled(Close)<LeavePageModalWrapperProps>(({ isMobile }) => ({
	color: "var(--text-secondary)",
	width: "1.5rem",
	height: "1.5rem",
	position: "absolute",
	top: isMobile ? "2rem" : "1rem",
	right: isMobile ? "2rem" : "1rem",
	cursor: "pointer"
}));
