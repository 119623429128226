import { useState, Dispatch, SetStateAction, KeyboardEvent } from "react";
import { guardAgainstInvalidDatePickerRange } from "./helpers/dates";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Card, ClickAwayListener, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePickerForm } from "./components";
import styles from "containers/Orders/order-styles.module.css";
import { DateRange } from "pages/OrderPages/OrderListPage/OrderListPage";
import { formatDateToMMDDYYYY, getPriorDateByDaysOffset } from "utils/date";
import { DatePickerWrapper } from "components/DatePicker/datePicker.styles";
import { OrderDateRangeLabel } from "constants/text";
import { differenceInDays } from "date-fns";
import { DateRangeErrorMessage } from "../NewOrders/constants";

export interface Props {
	partialMatch: string;
	searchDates: DateRange;
	setSearchDates: Dispatch<SetStateAction<DateRange>>;
}

const DatePicker = ({ partialMatch, searchDates, setSearchDates }: Props) => {
	const [showPickerCard, setShowPickerCard] = useState(false);

	const daysOffset = 90;
	const maxYearsAgoOffset = 20;

	const from = searchDates?.fromDate ?? getPriorDateByDaysOffset(new Date(), daysOffset);
	const to = searchDates?.toDate ?? new Date();
	const rangeDisplay = `${formatDateToMMDDYYYY(from)} - ${formatDateToMMDDYYYY(to)}`;

	const over90Days = differenceInDays(to, from) > 90;
	const dateError = partialMatch === "true" && over90Days;

	const onSetDateRange = (from: Date, to: Date) => {
		const [cleanFrom, cleanTo] = guardAgainstInvalidDatePickerRange(from, to, maxYearsAgoOffset, daysOffset);

		setSearchDates({
			fromDate: cleanFrom,
			toDate: cleanTo
		});
		setShowPickerCard(false);
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key !== "Tab") {
			event.preventDefault();
		}
		if (event.key === "Enter" || event.key === " " || event.key === "ArrowDown") {
			setShowPickerCard(true);
		}
		if (event.key === "Escape") {
			setShowPickerCard(false);
		}
	};

	return (
		<DatePickerWrapper
			widthValue="100%"
			role="search"
			data-testid="date-picker-range"
		>
			<div
				className={styles.datePickerRangeInputField}
				onClick={() => setShowPickerCard((prev) => !prev)}
				onKeyDown={handleKeyDown}
			>
				<TextField
					className="datePickerInputTypeDate"
					label={OrderDateRangeLabel}
					fullWidth
					error={dateError}
					helperText={dateError ? DateRangeErrorMessage : undefined}
					type="text"
					variant="outlined"
					size="small"
					sx={{
						"& .MuiInputBase-input.Mui-disabled": {
							WebkitTextFillColor: "var(--text-primary)"
						},
						"& .MuiInputBase-input": {
							cursor: "pointer"
						}
					}}
					value={rangeDisplay}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<DateRangeIcon data-id="date-picker-range-field" />
							</InputAdornment>
						)
					}}
					inputProps={{
						"data-testid": "date-picker-range-field",
						"data-id": "date-picker-range-field",
						readOnly: true
					}}
				/>
			</div>
			{showPickerCard && (
				<ClickAwayListener onClickAway={() => setShowPickerCard(false)}>
					<div
						data-testid="date-picker-form-card"
						style={{
							position: "absolute",
							zIndex: "100",
							width: "100%",
							minWidth: "300px"
						}}
					>
						<Card
							sx={{
								mt: "1rem",
								flexDirection: "column"
							}}
						>
							<DatePickerForm
								onSetDateRange={onSetDateRange}
								fromDate={searchDates.fromDate}
								toDate={searchDates.toDate}
							/>
						</Card>
					</div>
				</ClickAwayListener>
			)}
		</DatePickerWrapper>
	);
};

export default DatePicker;
