import styles from "../../../containers/Orders/order-styles.module.css";
import {
	SaveDraft400ErrorBullet,
	SaveDraft400ErrorContact,
	SaveDraft400ErrorHeader,
	SaveDraft400ErrorSubheader
} from "../constants";

const SaveDraft400Error = () => {
	return (
		<div className={styles.noOrdersErrorBannerContainer}>
			<div>
				<img
					src="/assets/UserAuthServiceError.svg"
					alt="No Save Draft Orders banner"
					className={styles.noOrdersErrorBannerContainerImage}
					data-testid="no-save-draft-orders-icon"
				/>
				<h5 data-testid="no-save-draft-orders-header">{SaveDraft400ErrorHeader}</h5>
				<p data-testid="no-save-draft-orders-subheader">{SaveDraft400ErrorSubheader}</p>
				<ul>
					<li>{SaveDraft400ErrorBullet}</li>
				</ul>
				<p>{SaveDraft400ErrorContact}</p>
			</div>
		</div>
	);
};

export default SaveDraft400Error;
