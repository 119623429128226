export const layout = {
	gap: {
		xs: 4,
		small: 8,
		medium: 12,
		large: 16,
		xl: 32
	},
	gutters: {
		small: 8,
		medium: 12,
		large: 16,
		xl: 32
	}
} as const;
