export const UNIQUE_BRAND_NAMES = [
	{
		id: 1,
		input: "ARBOR CREEK CABINETS",
		output: "Arbor Creek"
	},
	{
		id: 2,
		input: "CARDELL CABINETRY",
		output: "Cardell"
	},
	{
		id: 3,
		input: "DESIGNCRAFT CABINETS",
		output: "Design-Craft"
	},
	{
		id: 4,
		input: "KRAFTMAID",
		output: "KraftMaid"
	},
	{
		id: 5,
		input: "MASTERCRAFT",
		output: "MasterCraft"
	},
	{
		id: 6,
		input: "MEDALLION MENARDS",
		output: "Medallion at Menards"
	},
	{
		id: 7,
		input: "QUALITYCABINETS",
		output: "QualityCabinets"
	},
	{
		id: 8,
		input: "SMART CABINETRY",
		output: "Smart"
	},
	{
		id: 9,
		input: "CONCEPTS CABINETRY",
		output: "Concepts"
	}
];
