import style from "pages/replacements-page-styles.module.css";
import { AssociatedOrdersHeaderText } from "constants/text";
import CloseIcon from "@mui/icons-material/Close";
import { AssociatedOrdersHeader, AssociatedOrdersHeading, XIconContainer } from "../OrderDetailsHeader.styles";

interface FlyoutHeaderProps {
	handleOnClose: () => void;
}

const FlyoutHeader = ({ handleOnClose }: FlyoutHeaderProps) => {
	return (
		<AssociatedOrdersHeader>
			<AssociatedOrdersHeading margin>{AssociatedOrdersHeaderText}</AssociatedOrdersHeading>
			<XIconContainer>
				<CloseIcon
					className={style.closeIcon}
					onClick={handleOnClose}
					data-testid="linkedOrders-flyout-close-button"
					sx={{ paddingBottom: "3px" }}
				/>
			</XIconContainer>
		</AssociatedOrdersHeader>
	);
};

export default FlyoutHeader;
