import { Button, Grid } from "@mui/material";
import { OrderDetailViewModel, ReplacementPostModel } from "data/api/v1";
import { useMemo, useState } from "react";
import { BackText, ReplacementReviewSubmitOrder } from "constants/text";
import { v4 as uuidv4 } from "uuid";
import { useCreateReplacementOrderMutation } from "features/orderApi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useGetUserDetailsQuery } from "features/userApi";
import styles from "pages/replacements-page-styles.module.css";
import { useLazyGetImageUploadDataByOrderIdQuery } from "features/imageApi";
import { BlobServiceClient } from "@azure/storage-blob";
import { ReplacementCartItem } from "features/cart";
import { OrderDetails } from "features/submittingReplacementOrder";
import { submittedReplacementOrdersActions } from "features/submittedReplacementOrders";
import { trackGA4Event } from "utils/googleAnalytics";
import { APIErrorResponse } from "features/apiUtils";
import { CustomerAccountViewModel } from "data/api/v1/model/customer-account-view-model";
import { BillToViewModel } from "data/api/v1/model/bill-to-view-model";

export interface ReplacementsReviewButtons {
	orderDetail: OrderDetailViewModel | null;
	accounts: CustomerAccountViewModel | undefined;
	handleReplacementError: (error: APIErrorResponse["errors"]) => void;
}

const ReplacementReviewButtons = ({ orderDetail, accounts, handleReplacementError }: ReplacementsReviewButtons) => {
	const [createReplacementOrder] = useCreateReplacementOrderMutation();
	const { data: userDetails } = useGetUserDetailsQuery();
	const cartItems: ReplacementCartItem[] = useSelector((state: RootState) => state.cart.replacements);
	const details: OrderDetails = useSelector((state: RootState) => state.submittingReplacementOrder.order);
	const navigateTo = useNavigate();
	const [trigger] = useLazyGetImageUploadDataByOrderIdQuery();
	const dispatch = useDispatch();
	const [disabled, setDisabled] = useState(false);

	const replaceableParts = useMemo(() => cartItems.map((item: any) => item.replaceableParts), [cartItems]).flat();

	const wholeCabinetOrders = cartItems.reduce((accum: any, item: any) => {
		if (item.wholeCabinetQuantity > 0) {
			accum.push({
				originalLineItemId: item.lineItemId,
				id: item.id,
				hasAttachments: item.cabinetFileUploads && item.cabinetFileUploads.length > 0,
				defectCode: item.cabinetDefectCode,
				quantityOrdered: item.wholeCabinetQuantity
			});
		}
		return accum;
	}, []);

	const cabinetPartOrders = replaceableParts.reduce((accum: any, part: any) => {
		if (part.partQuantity > 0) {
			accum.push({
				originalLineItemId: part.lineItemId,
				id: part.id,
				hasAttachments: part.partFileUploads && part.partFileUploads.length > 0,
				defectCode: part.partDefectCode,
				quantityOrdered: part.partQuantity
			});
		}
		return accum;
	}, []);

	const encodeBase64 = (obj: ReplacementPostModel): ReplacementPostModel => {
		return {
			...obj,
			poNumber: btoa(obj?.poNumber ?? ""),
			jobName: btoa(obj?.jobName ?? ""),
			labelComments: btoa(obj?.labelComments ?? ""),
			shipToAddress: {
				...obj.shipToAddress,
				name: btoa(obj?.shipToAddress?.name ?? ""),
				line1: btoa(obj.shipToAddress?.line1 ?? ""),
				line2: btoa(obj.shipToAddress?.line2 ?? ""),
				phoneNumber: btoa(obj.shipToAddress?.phoneNumber ?? ""),
				email: btoa(obj.shipToAddress?.email ?? "")
			}
		};
	};

	const combinedOrders =
		cabinetPartOrders?.length > 0 ? wholeCabinetOrders.concat(cabinetPartOrders) : wholeCabinetOrders;

	const billToId = useMemo(
		() =>
			accounts?.billTos
				?.filter((item: BillToViewModel) => item.isSelected)
				.map((item: BillToViewModel) => item.billToId),
		[accounts]
	);

	const shipToAddresses = useMemo(
		() => accounts?.billTos?.map((address: BillToViewModel) => address.shipTos).flat(),
		[accounts]
	);

	const selectedAddress = shipToAddresses?.reduce((accum: any, address: any) => {
		if (address.isSelected) {
			accum.push({
				shipToId: address.shipToId
			});
		}
		return accum;
	}, []);

	const uploadToBlobStorage = async (uuID: string, newLineItems: any[]) => {
		const imageUploadData = await trigger(uuID).unwrap();
		// This should be one of three values: tandemdatastoragedev, tandemdatastoragetest, or tandemdatastorageprod
		const account = `tandemdatastorage${import.meta.env.VITE_APP_ENV}`;
		const sas = imageUploadData.credentials;
		// Loop through each cart item to get the uploads and lineItemNumber, to be able to upload each image from the cart
		for (const cartItem of cartItems) {
			const { cabinetFileUploads, replaceableParts, cabinetDefectCode } = cartItem;
			const partUploads = replaceableParts.map((part) => part.partFileUploads).flat();
			const combinedArray = [...cabinetFileUploads, ...partUploads];
			if (partUploads.length > 0) {
				Array.from(replaceableParts.filter((p) => p.partQuantity > 0)).forEach((part) => {
					trackGA4Event({
						event: "Replacement - Submitted Images",
						eventCategory: "replacements",
						eventLabel: "replacement images uploaded",
						eventAction: "submit",
						replacementUploadType: "part",
						numberOfFileUploads: part.partFileUploads.length,
						defectCode: part.partDefectCode
					});
				});
			}

			if (wholeCabinetOrders.length > 0 && cabinetDefectCode && cabinetFileUploads.length > 0) {
				trackGA4Event({
					event: "Replacement - Submitted Images",
					eventCategory: "replacements",
					eventLabel: "replacement images uploaded",
					eventAction: "submit",
					replacementUploadType: "whole cabinet",
					numberOfFileUploads: cabinetFileUploads.length,
					defectCode: cabinetDefectCode
				});
			}
			for await (const file of combinedArray) {
				try {
					const { fileUrl, size, name, id, originalLineItemId } = file;
					const newLineItemNumber =
						newLineItems.findIndex(
							(lineItem) => lineItem.id === id && lineItem.originalLineItemId === originalLineItemId
						) + 1;
					const fileName = `${newLineItemNumber}/${name}`;
					const blobContent = await fetch(fileUrl).then((response) => response.blob());
					const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?${sas}`);
					const containerClient = blobServiceClient.getContainerClient(uuID);
					const blockBlobClient = containerClient.getBlockBlobClient(fileName);
					let contentType = "";
					if (name.endsWith(".pdf")) {
						contentType = "application/pdf";
					} else if (name.endsWith(".jpg") || name.endsWith(".jpeg")) {
						contentType = "image/jpeg";
					} else if (name.endsWith(".png")) {
						contentType = "image/png";
					}
					await blockBlobClient.upload(blobContent, size, {
						blobHTTPHeaders: {
							blobContentType: contentType
						}
					});
				} catch (e) {
					console.error(e);
				}
			}
		}
	};

	const submitReplacement = async () => {
		const uuID: string = uuidv4();
		setDisabled((prevState) => !prevState);

		// When shipping details exists and shipping details id exists assign id
		// Otherwise the calculateShipToId should remain undefined
		// When shipping details does not exist then assign calculateShipToId as the initial selectedAddress (the default)

		const calculateShipToId = !details?.shippingDetails
			? selectedAddress[0]?.shipToId
			: details?.shippingDetails?.shipToId;

		// calculateShipToAddress should remain undefined when shipping details id is provided unless...
		// - User provides phone number when switching addresses OR
		// - User provides email when switching addresses
		// Otherwise calculateShipToAddress should be assigned the updated store state details info
		const hasShippingDetailsAddressId = Boolean(details?.shippingDetails?.shipToId);
		const hasPhoneNumber = Boolean(
			details?.shippingDetails?.address.phoneNumber && details?.shippingDetails?.address.phoneNumber.trim() !== ""
		);
		const hasEmail = Boolean(
			details?.shippingDetails?.address.email && details?.shippingDetails?.address.email.trim() !== ""
		);
		const calculateShipToAddress = {
			...(hasShippingDetailsAddressId && {
				phoneNumber: hasPhoneNumber ? details?.shippingDetails?.address.phoneNumber : undefined,
				email: hasEmail ? details?.shippingDetails?.address.email : undefined
			}),
			...(!hasShippingDetailsAddressId &&
				!calculateShipToId && {
					name: details?.shippingDetails?.address.name,
					line1: details?.shippingDetails?.address.line1,
					line2: details?.shippingDetails?.address.line2,
					line3: details?.shippingDetails?.address.line3,
					phoneNumber: details?.shippingDetails?.address.phoneNumber,
					email: details?.shippingDetails?.address.email,
					city: details?.shippingDetails?.address.city,
					state: details?.shippingDetails?.address.state,
					zip: details?.shippingDetails?.address.zip,
					county: details?.shippingDetails?.address.county ?? ""
				})
		};

		// Populate base fields used for replacement order
		let replacementOrder: ReplacementPostModel = {
			tandemOrderId: uuID,
			originalOrderId: orderDetail?.orderId,
			lineItems: combinedOrders,
			accountId: accounts?.accountId,
			jobName: details?.jobName ?? orderDetail?.jobName,
			poNumber: details?.poNumber ?? orderDetail?.poNumber,
			billToId: String(billToId),
			isCustomerPickup: details?.isCustomerPickup ?? orderDetail?.shippingDetails?.shipVia === "CUSTOMER_PICKUP",
			orderedBy: `${userDetails?.user?.firstName} ${userDetails?.user?.lastName}`,
			labelComments: details?.labelComments,
			shipToId: calculateShipToId,
			shipToAddress: calculateShipToAddress
		};

		if (replacementOrder.tandemOrderId && replacementOrder.lineItems) {
			await uploadToBlobStorage(replacementOrder.tandemOrderId, replacementOrder.lineItems);
		}
		try {
			// Encode the replacementOrder object fields to Base64
			replacementOrder = encodeBase64(replacementOrder);
			await createReplacementOrder(replacementOrder).unwrap();
			dispatch(submittedReplacementOrdersActions.updateOrders(replacementOrder?.originalOrderId));
			navigateTo(`/replacements/confirmation/${orderDetail?.orderId}`);
		} catch (error: any) {
			if (error instanceof DOMException && error.name === "InvalidCharacterError") {
				console.error("Invalid character in string for btoa encoding:", error);
			} else if (error?.data) {
				// Handle API call errors based on status code
				if (error.data.status === 403) {
					return navigateTo("/unapproved/account");
				}

				if (error.data.status === 404) {
					return navigateTo("/ordernotfound");
				}

				if (error.data.status === 409) {
					return navigateTo(`/replacements/resubmit/${orderDetail?.orderId}`);
				}

				if (error.data.status === 400) {
					setTimeout(() => window.scrollTo(0, 0), 0);
					handleReplacementError(error.data.errors);
				}
			} else {
				console.error("An unexpected error occurred:", error);
			}
		}
	};

	const handleBackNavigation = () => {
		navigateTo(`/replacements/reason/${orderDetail?.orderId}`);
	};

	return (
		<Grid
			container
			justifyContent="flex-end"
			className={styles.replacementReviewButtons}
		>
			<Grid
				item
				order={{ xs: 2, sm: 1 }}
				xs={12}
				sm={2}
				md={1}
				className={styles.backButton}
			>
				<Button
					variant="text"
					fullWidth
					sx={{ textTransform: "uppercase", fontFamily: "Gibson Medium, sans-serif" }}
					data-testid="replacementReason-backButton"
					onClick={handleBackNavigation}
				>
					{BackText}
				</Button>
			</Grid>
			<Grid
				item
				order={{ xs: 1, sm: 2 }}
				xs={12}
				sm={4}
				md={3}
			>
				<Button
					fullWidth
					onClick={submitReplacement}
					disabled={disabled}
					variant="contained"
					data-testid="replacementReview-submitOrder"
					data-id="replacementReview-submitOrder"
				>
					{ReplacementReviewSubmitOrder}
				</Button>
			</Grid>
		</Grid>
	);
};

export default ReplacementReviewButtons;
