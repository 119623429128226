import { Grid } from "@mui/material";
import styles from "./warning-box.module.css";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { WarningBoxTestIds } from "./constants";

type IconShape = "circle" | "triangle" | "none";

interface WarningBoxProps {
	warning: string;
	message: string;
	iconShape?: IconShape;
	containerTestId?: string;
	iconShapeTestId?: string;
	warningTestId?: string;
	messageTestId?: string;
}

const WarningBox = ({
	warning,
	message,
	iconShape = "circle",
	containerTestId,
	iconShapeTestId,
	warningTestId,
	messageTestId
}: WarningBoxProps) => {
	function renderWarningIcon() {
		const iconProps = {
			className: styles.warningBoxIcon,
			"data-testid": iconShapeTestId ?? WarningBoxTestIds.Icon,
			"aria-label": "Warning",
			"data-warning-icon": iconShape
		};

		switch (iconShape) {
			case "circle":
				return <ErrorOutline {...iconProps} />;
			case "triangle":
				return <WarningAmber {...iconProps} />;
			case "none":
			default:
				return;
		}
	}

	return (
		<div
			className={styles.warningBox}
			data-testid={containerTestId ?? WarningBoxTestIds.Container}
		>
			<Grid container>
				<Grid item>{renderWarningIcon()}</Grid>
				<Grid item>
					<h6 data-testid={warningTestId ?? WarningBoxTestIds.Warning}>{warning}</h6>
				</Grid>
			</Grid>
			<Grid container>
				<p data-testid={messageTestId ?? WarningBoxTestIds.Message}>{message}</p>
			</Grid>
		</div>
	);
};

export default WarningBox;
