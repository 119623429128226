import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";

export const ReplacementHeaderText = styled(Typography)({
	fontFamily: "Gibson Regular, sans-serif",
	color: "var(--text-primary)",
	fontSize: "var(--font-size-6)",
	margin: 0,
	display: "inline-block"
});

export const GenerateStepperWrapper = styled(Grid)(({ theme }) => ({
	minWidth: "20rem",
	margin: ".5rem 0",
	[theme.breakpoints.up("md")]: {
		justifyContent: "flex-end"
	}
}));

export const SelectPartsLineItemWrapper = styled.div(({ theme }) => ({
	color: theme.palette.text.primary,
	fontSize: "0.75rem",
	fontWeight: 400,
	letterSpacing: 1,
	margin: "0 0 1rem 0",
	textTransform: "uppercase"
}));

export const SelectPartsOrderDetailsWrapper = styled.div(({ theme }) => ({
	color: theme.palette.text.primary,
	margin: "5px 16px 0 0",
	[theme.breakpoints.up("md")]: {
		paddingTop: "5px"
	}
}));
