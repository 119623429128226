import MuiPictureAsPdf from "@mui/icons-material/PictureAsPdf";
import styled from "@emotion/styled";

export const UploadViewWrapper = styled.div({
	alignItems: "center",
	backgroundColor: "var(--upload-background)",
	display: "flex",
	height: "2.5rem",
	justifyContent: "space-between",
	padding: ".25rem .5rem",
	marginBottom: ".5rem",
	width: "calc(100% - 1rem)",
	button: {
		background: "none",
		border: "none",
		color: "var(--cwg-blue-900)",
		cursor: "pointer"
	},
	div: {
		alignItems: "center",
		display: "flex",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		div: {
			display: "block",
			flexDirection: "column",
			justifyContent: "space-around",
			alignItems: "flex-start",
			height: "auto",
			p: {
				fontWeight: "var(--font-weight-regular)",
				lineHeight: "var(--line-height-6)",
				fontSize: "var(--font-size-3)",
				letterSpacing: "var(--letter-spacing-5)",
				color: "var(--text-secondary)",
				whiteSpace: "nowrap",
				overflow: "hidden",
				textOverflow: "ellipsis",
				margin: 0
			}
		}
	},
	img: {
		borderRadius: ".25rem",
		height: "2.25rem",
		objectFit: "cover",
		marginRight: ".5rem",
		width: "2.25rem"
	}
});

export const PictureAsPdf = styled(MuiPictureAsPdf)({
	height: "2.25rem",
	marginRight: ".5rem",
	width: "2.25rem"
});
