import { type FC } from "react";
import { Link as RouterLink, type LinkProps as RouterLinkProps } from "react-router-dom";
import { MuiLink } from "components/Common/Link/link.styles";
import ChevronLeftRounded from "@mui/icons-material/ChevronLeftRounded";

interface LinkProps extends RouterLinkProps {
	hasBackIcon?: boolean;
}

const Link: FC<LinkProps> = ({ children, hasBackIcon, ...props }) => {
	return (
		<MuiLink
			{...props}
			component={RouterLink}
		>
			{hasBackIcon && <ChevronLeftRounded />} <>{children}</>
		</MuiLink>
	);
};

export default Link;
