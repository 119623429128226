import { BillToTitle, NoChargeOrder, SubtotalText } from "constants/text";
import { BillToShipToBoxWrapper } from "../BillToShipToBox.styles";
import { convertToTitleCase } from "utils/string";
import { OrderType } from "data/api/v1";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";

interface BillToBoxProps {
	billTo: BillToInfoViewModel | null | undefined;
	orderType: string | undefined;
	isNoChargeOrder?: boolean;
	orderDetailSubTotal?: number | null;
	hasBorder?: boolean;
	hasMinHeight?: boolean;
	dataTestIdPrefix: string;
	containsSubtotal?: boolean;
}

const BillToBox = ({
	billTo,
	orderType,
	isNoChargeOrder = false,
	orderDetailSubTotal = null,
	hasBorder = false,
	hasMinHeight = false,
	dataTestIdPrefix,
	containsSubtotal = true
}: BillToBoxProps) => {
	return (
		<BillToShipToBoxWrapper
			hasBorder={hasBorder}
			hasMinHeight={hasMinHeight}
			hasSubtotal={containsSubtotal}
		>
			<h6>{BillToTitle}</h6>
			<div>
				<div>
					{billTo?.projectName && billTo.projectName.trim() !== "" && (
						<p data-testid={dataTestIdPrefix + "-bill-to-projectName"}>{billTo.projectName} -</p>
					)}
					<p data-testid={dataTestIdPrefix + "-bill-to-city-state-id"}>
						{billTo?.city}, {billTo?.state} -{" "}
						{billTo?.logisticsMode && `${convertToTitleCase(billTo.logisticsMode)} - `}
						{billTo?.billToId}
					</p>
				</div>
				{containsSubtotal && (
					<div>
						{orderType !== OrderType.BACKORDER && !isNoChargeOrder && (
							<div>
								<b>{SubtotalText}</b>
								<p data-testid={dataTestIdPrefix + "-billToSubtotal"}>
									{orderDetailSubTotal?.toLocaleString("en-US", {
										style: "currency",
										currency: "USD"
									})}
								</p>
							</div>
						)}
						{orderType === OrderType.REPLACEMENT && isNoChargeOrder && (
							<b data-testid={dataTestIdPrefix + "-no-charge-order"}>{NoChargeOrder}</b>
						)}
					</div>
				)}
			</div>
		</BillToShipToBoxWrapper>
	);
};

export default BillToBox;
