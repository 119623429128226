import { getLaterDateByDaysOffset, getPriorDateByDaysOffset } from "utils/date";

export function guardAgainstInvalidFromDate(
	startDate: Date,
	maxNumberOfYears: number = 20,
	maxNumberOfDays: number = 90
): Date {
	const currentDate = new Date();
	const dateFromMaxNumberOfYearsAgo = new Date();
	dateFromMaxNumberOfYearsAgo.setFullYear(currentDate.getFullYear() - maxNumberOfYears);

	if (startDate > currentDate) {
		return getPriorDateByDaysOffset(currentDate, maxNumberOfDays);
	} else {
		return startDate <= dateFromMaxNumberOfYearsAgo ? dateFromMaxNumberOfYearsAgo : startDate;
	}
}

export function guardAgainstInvalidToDate(endDate: Date): Date {
	return endDate > new Date() ? new Date() : endDate;
}

export function guardAgainstInvalidDatePickerRange(from: Date, to: Date, maxYearsOffset: number, daysOffset: number) {
	const cleansedFromDate = guardAgainstInvalidFromDate(from, maxYearsOffset, daysOffset);
	let cleansedToDate = guardAgainstInvalidToDate(to);

	if (cleansedFromDate > cleansedToDate) {
		let newToDate = getLaterDateByDaysOffset(cleansedFromDate, daysOffset);
		cleansedToDate = guardAgainstInvalidToDate(newToDate);
	}

	return [cleansedFromDate, cleansedToDate];
}
