import { Table, TableCell, TableRow, TableBody, Tabs, Tab, Tooltip } from "@mui/material";
import {
	SaveDraftTableContainer,
	SaveDraftExteriorTableHeader,
	SaveDraftInteriorTableHeader,
	SaveDraftTableDisclaimer,
	SaveDraftTableWrapper,
	SaveDraftDateTableCell,
	SaveDraftDraftTableCell
} from "./SaveDraftTable.styles";
import {
	SaveDraftTableDisclaimerText,
	NewOrderOpen,
	NewOrderRemoveButton,
	SaveDraftDraftTab,
	SaveDraftTemplateTab,
	SaveDraftTHeadDate,
	SaveDraftTHeadDraft,
	SaveDraftTHeadAccount,
	SaveDraftTHeadDesigner,
	SaveDraftTHeadProductLine
} from "../constants";
import { SyntheticEvent, useState, Fragment } from "react";
import { useGetDraftOrdersQuery } from "features/orderApi";
import { formatDateToMMDDYYYY } from "utils/date";
import { LinkButton } from "../../Common/Link";
import SaveDraftNoDrafts from "./SaveDraftNoDrafts";
import SaveDraft400Error from "./SaveDraft400Error";
import SaveDraftTableSkeleton from "./SaveDraftTableSkeleton";
import { DraftOrderViewModel } from "data/api/v1/model/draft-order-model";

function a11yProps(index: number) {
	return {
		id: `new-order-save-draft-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`
	};
}
const SaveDraftTable = () => {
	const [value, setValue] = useState(0);

	const { data: drafts, error, isLoading } = useGetDraftOrdersQuery({});

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const getDraftTableRow = (draft: DraftOrderViewModel) => {
		const config = draft?.configurations?.reduce((accum, current, index) => {
			if (index !== 0) {
				return {
					...accum,
					accountId: [accum.accountId, `${current.accountNumber} - ${current.accountName}`].join(","),
					brand: "Multibrand"
				};
			} else
				return {
					...accum,
					accountId: `${accum.accountNumber} - ${accum.accountName}`
				};
		}, draft?.configurations[0]);

		return (
			<Fragment key={config?.configurationId}>
				<TableCell
					data-testid="new-order-save-draft-table-date-content"
					component="th"
					scope="row"
				>
					{formatDateToMMDDYYYY(new Date(String(draft.updatedAt)))}
				</TableCell>
				<SaveDraftDraftTableCell data-testid="new-order-save-draft-table-draft-name-content">
					{draft.draftName}
				</SaveDraftDraftTableCell>
				<TableCell data-testid="new-order-save-draft-table-account-content">
					{config?.accountId
						?.split(",")
						.map((accountId) => <div key={config?.accountNumber}>{accountId}</div>)}
				</TableCell>
				<TableCell data-testid="new-order-save-draft-table-designer-name-content">
					{draft.designerName}
				</TableCell>
				<TableCell data-testid="new-order-save-draft-table-brand-content">{config?.brand}</TableCell>
				<TableCell>
					<LinkButton data-testid="new-order-save-draft-table-edit-link">{NewOrderOpen}</LinkButton>

					<LinkButton
						variant="remove"
						data-testid="new-order-save-draft-table-remove-link"
					>
						{NewOrderRemoveButton}
					</LinkButton>
				</TableCell>
			</Fragment>
		);
	};

	return (
		<>
			{isLoading ? (
				<SaveDraftTableSkeleton />
			) : (
				<SaveDraftTableContainer>
					<SaveDraftExteriorTableHeader>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="Save draft table tabs"
						>
							<Tab
								data-testid="new-order-save-draft-draft-tab"
								label={SaveDraftDraftTab}
								{...a11yProps(0)}
							/>
							<Tab
								data-testid="new-order-save-draft-template-tab"
								label={SaveDraftTemplateTab}
							/>
						</Tabs>
					</SaveDraftExteriorTableHeader>

					<SaveDraftTableDisclaimer>{SaveDraftTableDisclaimerText}</SaveDraftTableDisclaimer>

					{error && "status" in error && error.status === 400 && <SaveDraft400Error />}

					{drafts && drafts?.length > 0 && !isLoading ? (
						<SaveDraftTableWrapper>
							<Table
								data-testid="new-order-save-draft-table"
								aria-label="Save draft table"
							>
								<SaveDraftInteriorTableHeader>
									<TableRow>
										<Tooltip
											data-testid="new-order-save-draft-table-date-tooltip"
											title="Date Last Saved"
											placement="top"
										>
											<SaveDraftDateTableCell data-testid="new-order-save-draft-table-date-header">
												{SaveDraftTHeadDate}
											</SaveDraftDateTableCell>
										</Tooltip>
										<TableCell data-testid="new-order-save-draft-table-draft-header">
											{SaveDraftTHeadDraft}
										</TableCell>
										<TableCell data-testid="new-order-save-draft-table-account-header">
											{SaveDraftTHeadAccount}
										</TableCell>
										<TableCell data-testid="new-order-save-draft-table-designer-header">
											{SaveDraftTHeadDesigner}
										</TableCell>
										<TableCell data-testid="new-order-save-draft-table-product-link-header">
											{SaveDraftTHeadProductLine}
										</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</SaveDraftInteriorTableHeader>
								<TableBody>
									{drafts?.map((draft) => (
										<TableRow key={draft.draftOrderId}>{getDraftTableRow(draft)}</TableRow>
									))}
								</TableBody>
							</Table>
						</SaveDraftTableWrapper>
					) : (
						<SaveDraftNoDrafts />
					)}
				</SaveDraftTableContainer>
			)}
		</>
	);
};

export default SaveDraftTable;
