import styles from "../order-status-page-styles.module.css";
import { ApprovedAccountDescription, ApprovedAccountHeader, ReturnToMyOrdersButtonText } from "constants/text";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ErrorButtonWrapper } from "../ErrorModal.styles";

const StatusUnapprovedAccount = () => {
	const navigateTo = useNavigate();

	return (
		<div
			className={styles.errorContainer}
			data-testid="status-unapproved-error-container"
		>
			<div className={styles.errorContent}>
				<img
					src={import.meta.env.PUBLIC_URL + "/assets/tandem_logos/tandem.png"}
					alt="Tandem Logo"
					className={styles.tandemLogo}
					data-testid="status-unapproved-error-tandem-logo"
				/>
				<img
					src="/assets/UnauthorizedSadImage.svg"
					alt="status-unapproved access logo"
					className={styles.noServiceSVG}
					data-testid="status-unapproved-logo"
				/>

				<div>
					<h1 data-testid="status-unapproved-account-header">{ApprovedAccountHeader}</h1>
					<p
						data-testid="status-unapproved-account-description"
						className={styles.errorText}
					>
						{ApprovedAccountDescription}
					</p>
				</div>

				<ErrorButtonWrapper
					variant="contained"
					onClick={() => navigateTo("/projects")}
					data-testid="status-unapproved-account-home-button"
				>
					{ReturnToMyOrdersButtonText}
				</ErrorButtonWrapper>
			</div>
		</div>
	);
};

export default StatusUnapprovedAccount;
