export const typography = {
	body: {
		body1: {
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontSize: "1rem", // 16px
			fontStyle: "normal",
			fontWeight: 400,
			letterSpacing: "0.15px",
			lineHeight: "150%"
		},
		body2: {
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontSize: "0.875rem", // 14px
			fontStyle: "normal",
			fontWeight: 400,
			letterSpacing: "0.17px",
			lineHeight: "143%"
		}
	},
	caption: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontSize: "0.75rem", // 12px
		fontStyle: "normal",
		fontWeight: 400,
		letterSpacing: "0.4px",
		lineHeight: "166%"
	},
	chipLabel: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontSize: "0.875rem", // 14px
		fontStyle: "normal",
		fontWeight: 500,
		letterSpacing: "0.46px",
		lineHeight: "18px"
	},
	heading: {
		h1: {
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontSize: "6rem", // 96px
			fontStyle: "normal",
			fontWeight: 200,
			letterSpacing: "0.46px",
			lineHeight: "116.7%"
		},
		h2: {
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontSize: "3.75rem", // 60px
			fontStyle: "normal",
			fontWeight: 200,
			letterSpacing: "1px",
			lineHeight: "120%"
		},
		h3: {
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontSize: "3rem", // 48px
			fontStyle: "normal",
			fontWeight: 500,
			lineHeight: "116.7%"
		},
		h4: {
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontSize: "2.125rem", // 34px
			fontStyle: "normal",
			fontWeight: 200,
			letterSpacing: "1px",
			lineHeight: "123.5%"
		},
		h5: {
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontSize: "1.5rem", // 24px
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: "133.4%"
		},
		h6: {
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontSize: "1.25rem", // 20px
			fontStyle: "normal",
			fontWeight: 500,
			letterSpacing: "0.15px",
			lineHeight: "160%"
		}
	},
	overline: {
		fontFeatureSettings: "'clig' off, 'liga' off",
		fontSize: "0.75rem", // 12px
		fontStyle: "normal",
		fontWeight: 400,
		letterSpacing: "1px",
		lineHeight: "266%",
		textTransform: "uppercase"
	},
	subtitle: {
		subtitle1: {
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontSize: "1rem", // 16px
			fontStyle: "normal",
			fontWeight: 400,
			letterSpacing: "1px",
			lineHeight: "175%",
			textTransform: "uppercase"
		},
		subtitle2: {
			fontFeatureSettings: "'clig' off, 'liga' off",
			fontSize: "0.875rem", // 14px
			fontStyle: "normal",
			fontWeight: 500,
			letterSpacing: "0.1px",
			lineHeight: "157%"
		}
	}
} as const;
