import { Grid, Skeleton } from "@mui/material";
import styles from "pages/order-status-page-styles.module.css";

const OrderDetailsLineItemSkeleton = () => {
	return (
		<div className={styles.orderDetailsLineItemSkeleton}>
			<Grid
				container
				columns={8}
				spacing={2}
			>
				<Grid
					item
					lg={2}
					xs={3}
				>
					<Skeleton
						variant="rounded"
						animation="wave"
						height={10}
					/>
				</Grid>
				<Grid
					item
					lg={6}
					xs={3}
				>
					<Skeleton
						variant="rounded"
						animation="wave"
						height={10}
						className={styles.lowerLineItemSkeleton}
					/>
				</Grid>
				<Grid
					item
					lg={2}
					xs={8}
				>
					<Skeleton
						variant="rounded"
						animation="wave"
						height={10}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default OrderDetailsLineItemSkeleton;
