import { Button, Grid } from "@mui/material";
import {
	ReplacementsAddToOrderButtonText,
	ReplacementsCancelButtonText,
	ReplacementsSelectAPartError
} from "constants/text";
import styles from "pages/replacements-page-styles.module.css";
import { KeyboardEvent, MouseEvent } from "react";

interface FlyoutFooterProps {
	selectPartError: boolean;
	closeDrawer: (event: KeyboardEvent | MouseEvent) => void;
	addToOrderHandler: () => void;
}

const FlyoutFooter = ({ selectPartError, closeDrawer, addToOrderHandler }: FlyoutFooterProps) => {
	return (
		<Grid
			className={styles.footerHeight}
			container
			spacing={1}
		>
			{selectPartError && (
				<Grid
					item
					xl={12}
				>
					<p
						className={`body2  ${styles.errorText}`}
						data-testid="replacements-flyout-select-part-error-message"
					>
						{ReplacementsSelectAPartError}
					</p>
				</Grid>
			)}
			<Grid
				item
				xs={6}
				sx={{ paddingTop: "unset" }}
			>
				<Button
					fullWidth
					data-testid="replacements-flyout-cancel-button"
					variant="outlined"
					onClick={closeDrawer}
				>
					{ReplacementsCancelButtonText}
				</Button>
			</Grid>
			<Grid
				item
				xs={6}
				sx={{ paddingTop: "unset" }}
			>
				<Button
					fullWidth
					onClick={addToOrderHandler}
					data-testid="replacements-flyout-add-to-order-button"
					variant="contained"
				>
					{ReplacementsAddToOrderButtonText}
				</Button>
			</Grid>
		</Grid>
	);
};

export default FlyoutFooter;
