import { Box, Grid, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { convertToTitleCase } from "utils/string";
import { ORDER_DETAIL_CTA_PROBLEMS } from "constants/orderDetailsCTAProblems";
import { useEffect, useState } from "react";
import { StyledMuiGridContainer, StyledMuiLink } from "components/CTAProblem/ctaProblem.styles";

const DynamicDescription = ({ content }: DynamicDescriptionProps) => {
	return (
		<Typography
			data-testid="cta-problem-description"
			style={{ fontFamily: "Gibson Medium", fontSize: "var(--font-size-3)" }}
		>
			{content}
		</Typography>
	);
};

const CTAProblem = ({ orderDetail }: Props) => {
	const ctaContact = orderDetail?.ctaContact;

	const description = ORDER_DETAIL_CTA_PROBLEMS.find(
		(status) => status.statusReason === orderDetail.ctaReason
	)?.description;

	const containsEmail = description?.includes("@");

	const [dynamicEmail, setDynamicEmail] = useState();

	const isWarningProblem =
		orderDetail?.ctaReason !== "DELIVERY_DATE_CHANGED" && orderDetail?.ctaReason !== "PARTIALLY_SHIPPED";

	useEffect(() => {
		setDynamicEmail(ctaContact);
	}, [ctaContact]);

	const parts = description ? description.split("dealercare@cabinetworksgroup.com") : "";
	const dynamicEmailWithLink = (
		<>
			{parts[0]}
			<StyledMuiLink href={`mailto:${dynamicEmail}`}>{dynamicEmail}</StyledMuiLink>
			{parts[1]}
		</>
	);

	return (
		<Box
			marginTop="1rem"
			style={{ backgroundColor: "var(--white-50)", padding: "0 1.25rem 1.25rem 1.25rem", marginTop: 0 }}
		>
			<StyledMuiGridContainer
				container
				role="alert"
				isWarningProblem
			>
				<Grid
					item
					sx={{
						position: "absolute",
						top: "0.8125rem",
						left: "1rem"
					}}
				>
					{isWarningProblem ? (
						<WarningAmberIcon
							data-testid="cta-problem-warning-icon"
							aria-label="Warning"
						/>
					) : (
						<InfoOutlinedIcon
							data-testid="cta-problem-info-icon"
							aria-label="Info"
						/>
					)}
				</Grid>
				<Grid
					item
					sx={{ padding: "0.875rem 1rem 0.875rem 3.125rem" }}
				>
					<Typography
						style={{
							fontFamily: "Gibson Medium",
							fontSize: "var(--font-size-5)",
							marginBottom: "0.25rem"
						}}
						data-testid="cta-problem-cta-reason"
					>
						{convertToTitleCase(orderDetail.ctaReason!)}
					</Typography>
					<DynamicDescription content={containsEmail ? dynamicEmailWithLink : description} />
				</Grid>
			</StyledMuiGridContainer>
		</Box>
	);
};

interface Props {
	orderDetail: any;
}

interface DynamicDescriptionProps {
	content: React.ReactNode;
}

export default CTAProblem;
