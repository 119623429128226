import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const ErrorButtonWrapper = styled(Button)(({ variant, theme }) => ({
	...(variant === "contained" && {
		width: "343px",
		margin: "0 auto",
		[theme.breakpoints.down("sm")]: {
			width: "250px"
		}
	})
}));
