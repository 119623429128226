import { type FC } from "react";
import { NewOrdersLayoutContainer, StaticComponentBackground } from "components/NewOrders/NewOrders.styles";
import { Skeleton } from "@mui/material";
import {
	CommonSkeletonStyles,
	Header,
	InfoSkeleton,
	LineItemConfigHeaderSkeleton,
	LineItemSkeleton,
	LineItemSkeletonContainer,
	SkeletonCard,
	SkeletonContent
} from "pages/OrderPages/ReviewOrderPage/LoadingSkeleton/loadingSkeleton.styles";

const LoadingSkeleton: FC = () => (
	<NewOrdersLayoutContainer data-testid="new-order-review-loading-skeleton">
		<CommonSkeletonStyles>
			<StaticComponentBackground>
				<Header>
					<div>
						<Skeleton height={24} />
						<Skeleton height={24} />
					</div>
					<div>
						<Skeleton height={12} />
					</div>
					<div>
						<Skeleton height={12} />
					</div>
					<div>
						<Skeleton height={42} />
					</div>
				</Header>
			</StaticComponentBackground>
			<SkeletonContent>
				<SkeletonCard elevation={0}>
					<InfoSkeleton>
						<div>
							<Skeleton height={24} />
						</div>
						<div>
							<Skeleton height={40} />
						</div>
						<div>
							<Skeleton height={40} />
							<Skeleton height={40} />
						</div>
						<div>
							<Skeleton height={40} />
							<Skeleton height={40} />
						</div>
					</InfoSkeleton>
					<LineItemConfigHeaderSkeleton>
						<div>
							<Skeleton height={40} />
							<Skeleton height={40} />
						</div>
						<div>
							<Skeleton height={72} />
						</div>
						<LineItemSkeletonContainer>
							{Array(10)
								.fill(1)
								.map((index) => (
									<LineItemSkeleton key={index}>
										<div>
											<Skeleton height={24} />
											<Skeleton height={12} />
										</div>
										<div>
											<div>
												<Skeleton height={12} />
												<Skeleton height={12} />
											</div>
											<div>
												<Skeleton height={12} />
												<Skeleton height={12} />
											</div>
											<div>
												<Skeleton height={12} />
												<Skeleton height={12} />
											</div>
										</div>
									</LineItemSkeleton>
								))}
						</LineItemSkeletonContainer>
					</LineItemConfigHeaderSkeleton>
				</SkeletonCard>
			</SkeletonContent>
		</CommonSkeletonStyles>
	</NewOrdersLayoutContainer>
);

export default LoadingSkeleton;
