import { Grid } from "@mui/material";
import {
	CarrierText,
	ETAText,
	OrderNumberText,
	TrackingNumberText,
	TruckNumberText,
	UnavailableDataPlaceholderText
} from "constants/text";
import React from "react";
import { OrderDetailViewModel } from "data/api/v1";

interface HeaderProps {
	orderDetail: OrderDetailViewModel | null;
	trackingNumber: string[];
	truckNumber: string[];
}

export const OrderDetailsShipmentHeader = ({ orderDetail, trackingNumber, truckNumber }: HeaderProps) => {
	const eta = orderDetail?.eta;

	return (
		<div style={{ paddingLeft: "0.75rem", paddingRight: "0.75rem" }}>
			<Grid container>
				<Grid
					className="subtitle2"
					item
					xl={6}
					lg={4}
					md={3}
					xs={12}
					style={{ paddingTop: "5px", paddingBottom: "5px" }}
				>
					<span
						className="subtitle2"
						style={{ paddingRight: "5px" }}
					>
						{OrderNumberText}
					</span>
					<span className="body2">{orderDetail?.orderNumber ?? UnavailableDataPlaceholderText}</span>
				</Grid>

				<Grid
					className="subtitle2"
					item
					xs={12}
				>
					<span
						className="subtitle2"
						style={{ paddingRight: "5px" }}
					>
						{ETAText}
					</span>
					<span className="body2">{eta ?? UnavailableDataPlaceholderText}</span>
				</Grid>

				<Grid
					className="subtitle2"
					item
					xs={12}
				>
					<span
						className="subtitle2"
						style={{ paddingRight: "5px" }}
					>
						{CarrierText}
					</span>
					<span className="body2">
						{orderDetail?.shippingDetails?.carrierName ?? UnavailableDataPlaceholderText}
					</span>
				</Grid>

				{trackingNumber.length ? (
					<Grid
						className="subtitle2"
						item
						xs={12}
					>
						<span
							className="subtitle2"
							style={{ paddingRight: "5px" }}
						>
							{TrackingNumberText}
						</span>
						<span className="body2">{trackingNumber[0] ?? UnavailableDataPlaceholderText}</span>
					</Grid>
				) : undefined}

				{truckNumber.length ? (
					<Grid
						className="subtitle2"
						item
						xs={12}
					>
						<span
							className="subtitle2"
							style={{ paddingRight: "5px" }}
						>
							{TruckNumberText}
						</span>
						<span className="body2">{truckNumber ?? UnavailableDataPlaceholderText}</span>
					</Grid>
				) : undefined}
			</Grid>
		</div>
	);
};

export default OrderDetailsShipmentHeader;
