import { Grid } from "@mui/material";
import style from "pages/order-details-items-styles.module.css";
import { LineItemsInOrderText, SearchItemText } from "constants/text";
import { SyntheticEvent, useMemo } from "react";
import SuggestedSearch from "components/Common/SuggestedSearch/SuggestedSearch";
import { SelectPartsLineItemWrapper } from "components/Replacements/ReplacementHeaderText.styles";

interface SearchLineItemsProps {
	groupAllByNoneTrackingNumber: any;
	searchValue?: string | null;
	handleOrderSearch: (event: SyntheticEvent, values: string) => void;
	lineItemAndShipmentCount?: number;
}

const OrderDetailsSearchLineItems = ({
	groupAllByNoneTrackingNumber,
	searchValue,
	handleOrderSearch,
	lineItemAndShipmentCount
}: SearchLineItemsProps) => {
	const searchOptions = useMemo(() => {
		return groupAllByNoneTrackingNumber?.map(
			(order: { sku: string; description: string }) => order?.sku + " " + order?.description
		);
	}, [groupAllByNoneTrackingNumber]);

	return (
		<div className="line-item-header">
			<Grid
				container
				className={style.searchGridContainer}
			>
				<SelectPartsLineItemWrapper data-testid="orderDetails-lineItem-count">
					{lineItemAndShipmentCount} {LineItemsInOrderText}
				</SelectPartsLineItemWrapper>
				<Grid
					item
					xl={4}
					lg={5}
					xs={12}
				>
					<SuggestedSearch
						ariaLabel="search-orders"
						dataTestId="orderDetails-lineItem-search"
						dropDownOpenOnChar={3}
						placeholder={SearchItemText}
						handleSearch={(event, values) => handleOrderSearch(event, (values as string) ?? "")}
						id="replacements-search-line-items"
						searchOptions={searchOptions ?? []}
						searchValue={searchValue ?? ""}
						size="small"
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default OrderDetailsSearchLineItems;
