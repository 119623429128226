import { List, AutoSizer, CellMeasurerCache, CellMeasurer } from "react-virtualized";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { SingleCard } from "components";

const VirtualizedOrderList = ({ items, testId }: any) => {
	const loadedItems = useMemo(() => items, [items]);
	let listRef: any = useRef<List>(null);

	useEffect(() => {
		if (listRef.current) {
			listRef.current.recomputeRowHeights();
		}
	}, [items, listRef]);

	const cache = useMemo(
		() =>
			new CellMeasurerCache({
				defaultHeight: 500,
				minHeight: 50,
				fixedWidth: true
			}),
		[]
	);

	const handleResize = useCallback(() => {
		cache.clearAll();
		if (listRef.current) {
			listRef.current.recomputeRowHeights();
		}
	}, [cache]);

	useEffect(() => {
		const resizeObserver = new ResizeObserver(handleResize);
		resizeObserver.observe(document.body);
		return () => {
			resizeObserver.disconnect();
		};
	}, [handleResize]);

	const rowRenderer = useCallback(
		({ index, key, style, parent }: any) => {
			const order = loadedItems[index];

			return (
				<CellMeasurer
					key={key}
					cache={cache}
					parent={parent}
					columnIndex={0}
					rowIndex={index}
				>
					{({ registerChild }) => (
						<div
							ref={(element): void => {
								if (element && registerChild) {
									registerChild(element);
								}
							}}
							style={style}
						>
							<SingleCard
								key={order.orderId}
								order={order}
								data-testid={testId}
							/>
						</div>
					)}
				</CellMeasurer>
			);
		},
		[cache, loadedItems, testId]
	);

	return (
		<div
			style={{ height: "100vh" }}
			data-testid="order-list-project-orders"
		>
			<AutoSizer>
				{({ height, width }: any) => (
					<List
						ref={listRef}
						width={width}
						height={height}
						rowCount={loadedItems?.length}
						rowHeight={cache.rowHeight}
						rowRenderer={rowRenderer}
					/>
				)}
			</AutoSizer>
		</div>
	);
};

export default VirtualizedOrderList;
