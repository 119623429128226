import styled from "@emotion/styled";
import { Popper, TextField } from "@mui/material";

interface AutoCompleteError {
	isError: boolean;
}

export const AutoCompleteWrapper = styled.div<AutoCompleteError>(
	({ theme, isError }) =>
		isError && {
			color: theme.palette.error.main,
			span: {
				marginTop: "2px"
			}
		}
);

export const AutoCompleteTextField = styled(TextField)<AutoCompleteError>(({ theme, isError }) => ({
	"& .MuiInputBase-root": {
		fieldset: {
			borderColor: isError ? theme.palette.error.main + "!important" : theme.palette.grey[400]
		},
		backgroundColor: theme.project.colors.backgroundColors.main
	},
	"& .MuiFormLabel-root": {
		color: isError ? theme.palette.error.main + "!important" : theme.palette.text.primary
	}
}));

export const AutoCompletePopper = styled(Popper)(({ theme }) => ({
	div: {
		ul: {
			"& .MuiAutocomplete-option": {
				backgroundColor: theme.project.colors.backgroundColors.main + " !important"
			},
			"& .MuiAutocomplete-option.Mui-focused": {
				backgroundColor: theme.project.colors.allColors.hex.grayscale.gray100 + " !important"
			}
		}
	}
}));
