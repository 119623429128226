import { IMaskInput } from "react-imask";
import { forwardRef } from "react";

interface MaskedPhoneTextFieldProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const MaskedPhoneTextField = forwardRef<HTMLInputElement, MaskedPhoneTextFieldProps>(
	function TextMaskCustom(props, ref) {
		const { onChange, ...otherProps } = props;
		return (
			<IMaskInput
				{...otherProps}
				mask="(000) 000 - 0000"
				inputRef={ref}
				onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
				overwrite
			/>
		);
	}
);

export default MaskedPhoneTextField;
