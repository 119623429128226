import styled from "@emotion/styled";

interface BillToShipToBoxWrapperProps {
	hasBorder?: boolean;
	hasMinHeight?: boolean;
	hasSubtotal?: boolean;
}

export const BillToShipToBoxWrapper = styled.div<BillToShipToBoxWrapperProps>(
	({ theme, hasBorder = false, hasMinHeight = false, hasSubtotal }) => ({
		padding: 16,
		border: hasBorder ? "1px solid #E0E0E0" : "none",
		display: "flex",
		flexDirection: "column",
		minHeight: hasMinHeight ? "150px" : "auto",
		backgroundColor: theme.palette.background.default,
		b: {
			lineHeight: "var(--line-height-8)",
			fontSize: 14,
			letterSpacing: "var(--letter-spacing-6)",
			color: theme.palette.text.primary,
			fontWeight: 500
		},
		p: {
			lineHeight: "var(--line-height-6)",
			fontSize: 14,
			letterSpacing: "var(--letter-spacing-5)",
			color: theme.palette.text.secondary
		},
		h6: {
			margin: "0 0 0.5rem 0",
			fontWeight: 400,
			lineHeight: "var(--line-height-7)",
			fontSize: 16,
			letterSpacing: "var(--letter-spacing-7)",
			textTransform: "uppercase",
			color: theme.palette.text.primary
		},
		div: {
			display: "flex",
			width: "100%",
			marginRight: 24,
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column",
				gap: "8px"
			},
			div: {
				width: hasSubtotal ? "50%" : "100%",
				[theme.breakpoints.down("sm")]: {
					width: "100%"
				},
				flexDirection: "column",
				gap: 0,
				p: {
					margin: 0
				}
			}
		}
	})
);
