import { useState, useRef } from "react";
import styles from "./drag-drop.module.css";

export interface Props {
	onFileUpload: (file: any) => void;
	accept: string;
	children?: React.ReactNode;
}

const Dragdrop = ({ onFileUpload, accept, children }: Props) => {
	const [dragOver, setDragOver] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleDragOver: React.DragEventHandler<HTMLDivElement> = (event) => {
		event.preventDefault();
		setDragOver(true);
	};

	const handleDragLeave: React.DragEventHandler<HTMLDivElement> = (event) => {
		event.preventDefault();
		setDragOver(false);
	};

	const handleDrop: React.DragEventHandler<HTMLDivElement> = (event) => {
		event.preventDefault();
		setDragOver(false);
		onFileUpload(Array.from(event.dataTransfer.files));
	};

	const handleClick = () => {
		if (fileInputRef.current === null) return;

		(fileInputRef.current as any).click();
	};

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		onFileUpload(Array.from(event.target?.files || []));
	};

	return (
		<div
			className={`${styles.imageDrop} ${dragOver ? styles.dragOver : ""}`}
			onClick={handleClick}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
			data-testid="dragdrop"
		>
			{children}
			<input
				accept={accept}
				type="file"
				ref={fileInputRef}
				onChange={handleChange}
				style={{ display: "none" }}
				data-testid="dragdrop-input"
				multiple
			/>
		</div>
	);
};

export default Dragdrop;
