import styles from "containers/Orders/order-styles.module.css";
import {
	ChangeEvent,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useCallback,
	useMemo,
	useRef,
	useState
} from "react";
import { trackGA4Event } from "utils/googleAnalytics";
import OrderSearchDesktop from "./OrderSearchDesktop";
import OrderSearchMobile from "./OrderSearchMobile";
import { SelectChangeEvent } from "@mui/material";

export interface Props extends PropsWithChildren {
	isCareRole: boolean;
	isMobile: boolean;
	setCurrentSearch: Dispatch<SetStateAction<string>>;
	setPartialMatch: Dispatch<SetStateAction<string>>;
	partialMatch: string;
	currentSearch: string;
	invokeSearch: () => {};
	hasSearchQueryParam: boolean;
}

const OrderSearch = ({
	children: datePicker,
	isCareRole,
	isMobile,
	setCurrentSearch,
	setPartialMatch,
	partialMatch,
	currentSearch,
	invokeSearch,
	hasSearchQueryParam
}: Props) => {
	const didInitialLoad = useRef(false);
	const didReset = useRef(false);
	const didSearch = useRef(false);
	const didType = useRef(false);

	if (!didType.current && currentSearch?.length) {
		didType.current = true;
	}

	const searchLength = currentSearch?.trim().length;
	const [hasSearchValidationError, setHasSearchValidationError] = useState(false);

	const handleOnSearch = useCallback(() => {
		if (didType.current && searchLength < 3 && (searchLength > 0 || isCareRole)) {
			setHasSearchValidationError(true);
			return;
		}

		trackGA4Event({
			event: "Search - Field",
			eventCategory: "search",
			eventAction: "type",
			eventLabel: `Summary - ${currentSearch}`
		});

		invokeSearch();
		didSearch.current = true;
	}, [currentSearch, invokeSearch, isCareRole, searchLength]);

	if (!didInitialLoad.current && !isCareRole && !hasSearchQueryParam) {
		didInitialLoad.current = true;
		handleOnSearch();
	}

	if (didReset.current) {
		didReset.current = false;
		if (didSearch.current) {
			didSearch.current = false;
			handleOnSearch();
		}
	}

	const handleSearchFieldOnKeyDown = useCallback(
		(e: any) => {
			if (e.key === "Enter") {
				setHasSearchValidationError(searchLength < 3 && didType.current && (searchLength > 0 || isCareRole));
				handleOnSearch();
			} else {
				setHasSearchValidationError(false);
			}
		},
		[handleOnSearch, isCareRole, searchLength]
	);

	const handleSearchFieldOnChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => setCurrentSearch(e.target.value),
		[setCurrentSearch]
	);
	const handlePartialSearch = useCallback(
		(e: SelectChangeEvent) => {
			setPartialMatch(e.target.value);
		},
		[setPartialMatch]
	);

	const resetSearch = useCallback(() => {
		setCurrentSearch("");
		didReset.current = true;
	}, [setCurrentSearch]);

	const searchProps: OrderSearchCommonProps = useMemo(
		() => ({
			children: datePicker,
			hasSearchValidationError,
			handleOnSearch,
			handlePartialSearch,
			partialMatch,
			handleSearchFieldOnChange,
			handleSearchFieldOnKeyDown,
			isCareRole,
			resetSearch,
			searchValue: currentSearch
		}),
		[
			currentSearch,
			datePicker,
			handleOnSearch,
			handlePartialSearch,
			handleSearchFieldOnChange,
			handleSearchFieldOnKeyDown,
			hasSearchValidationError,
			isCareRole,
			partialMatch,
			resetSearch
		]
	);
	return (
		<div
			id="my-orders-header"
			className={styles.header}
			data-testid="search-orders-header"
		>
			{isMobile ? <OrderSearchMobile {...searchProps} /> : <OrderSearchDesktop {...searchProps} />}
		</div>
	);
};

export interface OrderSearchCommonProps extends PropsWithChildren {
	hasSearchValidationError: boolean;
	handleOnSearch: () => void;
	handleSearchFieldOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
	handleSearchFieldOnKeyDown: (event: any) => void;
	resetSearch: () => void;
	searchValue: string;
	isCareRole: boolean;
	handlePartialSearch: (event: SelectChangeEvent) => void;
	partialMatch: string;
}

export default OrderSearch;
