import { Box, Button } from "@mui/material";
import { EmptyShoppingCartProps } from "./EmptyShoppingCart";
import { ShoppingCartContinueButtonText, ShoppingCartProceedButtonText } from "constants/text";
import styles from "pages/replacements-page-styles.module.css";
import { useNavigate, useParams } from "react-router-dom";

const ShoppingCartFooter = ({ closeDrawer }: EmptyShoppingCartProps) => {
	const params = useParams();
	const navigateTo = useNavigate();
	const handleProceedWithOrders = () => {
		return navigateTo(`/replacements/reason/${params?.orderId}`);
	};

	return (
		<Box>
			<div className={styles.footerWrapper}>
				<Button
					fullWidth
					data-testid="shopping-cart-continue-selecting-parts"
					variant="outlined"
					onClick={closeDrawer}
				>
					{ShoppingCartContinueButtonText}
				</Button>
				<br />
				<Button
					fullWidth
					data-testid="shopping-cart-footer-proceed-with-order-button"
					variant="contained"
					onClick={handleProceedWithOrders}
				>
					{ShoppingCartProceedButtonText}
				</Button>
			</div>
		</Box>
	);
};

export default ShoppingCartFooter;
