import styled from "@emotion/styled";

export const AddressWrapper = styled.div(({ theme }) => ({
	display: "grid",
	gap: "1rem",
	gridTemplateColumns: "repeat(2, 1fr)",
	padding: "1rem",
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr"
	},
	div: {
		alignItems: "flex-start",
		margin: 0
	}
}));

export const CommentWrapper = styled.div({
	padding: "0 1rem 1rem 1rem",
	p: {
		margin: 0
	}
});
