import Toggle from "components/Common/Toggle/Toggle";
import { OpenOrdersToggleDivider, OpenOrdersToggleWrapper } from "./chipDropdownMenu.styles";

const OpenOrdersToggle = ({ isOpenOrdersToggledOn, handleOpenOrdersToggle }: OpenOrdersToggleProps) => {
	return (
		<div data-testid="open-orders-toggle-wrapper">
			<OpenOrdersToggleWrapper>
				<Toggle
					label="Open Orders"
					isToggledOn={isOpenOrdersToggledOn}
					onToggleChange={handleOpenOrdersToggle}
					testId="open-orders-toggle"
				/>
			</OpenOrdersToggleWrapper>
			<OpenOrdersToggleDivider />
		</div>
	);
};

interface OpenOrdersToggleProps {
	isOpenOrdersToggledOn: boolean;
	handleOpenOrdersToggle: () => void;
}

export default OpenOrdersToggle;
