import {
	StaticComponentBackground,
	StaticComponentGrid,
	Label,
	InfoText,
	StaticComponentTitle,
	NewOrderDetailsWrapper,
	StaticComponentGridDividerWrapper
} from "../NewOrders.styles";
import { Divider } from "@mui/material";
import {
	NewOrderReviewDesignerLabel,
	NewOrderReviewPOLabel,
	NewOrderReviewJobNameLabel,
	NewOrderOrderDetailsTitle,
	NewOrderShipToTitle,
	NewOrderAddressTitle,
	NewOrderRequestedDeliveryDate,
	NewOrderDeliveryContact,
	NewOrderShippingMethod,
	NewOrderShippingLabelNotes,
	NewOrderUnavailableDataPlaceholderText
} from "../constants";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { EditText } from "constants/text";
import { LinkButton } from "components/Common/Link";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

const ReviewOrderDetails = () => {
	const navigate = useNavigate();

	const handleEdit = () => {
		navigate("/new-order/shipping-details");
	};

	const isConfirmation = window.location.toString().includes("new-order/confirmation");
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const newOrderDetails = useSelector((state: RootState) => state.submittingNewOrder.newOrder);
	const allowLabelComments = useMemo(() => {
		return parsedCSV?.configurations?.map((account) => account.accountNumber?.allowLabelComments);
	}, [parsedCSV]);

	const confirmedDeliveryDate = newOrderDetails?.requestedDeliveryDate;
	const formattedDeliveryDate = `${confirmedDeliveryDate?.slice(5, 7)}/${confirmedDeliveryDate?.slice(8, 10)}/${confirmedDeliveryDate?.slice(0, 4)}`;

	return (
		<StaticComponentBackground>
			<NewOrderDetailsWrapper isConfirmation={isConfirmation}>
				<StaticComponentTitle>
					{NewOrderOrderDetailsTitle}
					{!isConfirmation && (
						<span>
							<LinkButton
								data-testid="new-order-review-edit"
								onClick={handleEdit}
							>
								{EditText}
							</LinkButton>
						</span>
					)}
				</StaticComponentTitle>
				<StaticComponentGrid>
					<Label>
						{NewOrderReviewDesignerLabel}
						<InfoText data-testid="new-order-review-designer-number">
							{newOrderDetails?.designerNumber
								? newOrderDetails.designerNumber
								: NewOrderUnavailableDataPlaceholderText}
						</InfoText>
					</Label>

					<Label>
						{NewOrderReviewPOLabel}
						<InfoText data-testid="new-order-review-po-number">
							{newOrderDetails?.poNumber
								? newOrderDetails.poNumber
								: NewOrderUnavailableDataPlaceholderText}
						</InfoText>
					</Label>

					<Label>
						{NewOrderReviewJobNameLabel}
						<InfoText data-testid="new-order-review-job-name">
							{newOrderDetails?.jobName
								? newOrderDetails.jobName
								: NewOrderUnavailableDataPlaceholderText}
						</InfoText>
					</Label>
				</StaticComponentGrid>

				<StaticComponentGridDividerWrapper>
					<Divider />
				</StaticComponentGridDividerWrapper>

				<StaticComponentTitle>{NewOrderShipToTitle}</StaticComponentTitle>
				<StaticComponentGrid>
					<Label>
						{NewOrderAddressTitle}
						<InfoText data-testid="new-order-review-address">
							{newOrderDetails?.shipToAddress?.city ? (
								<>
									{newOrderDetails?.shipToAddress?.name && (
										<div>{newOrderDetails.shipToAddress.name}</div>
									)}
									{newOrderDetails?.shipToAddress?.line1 && (
										<div>{newOrderDetails.shipToAddress.line1}</div>
									)}
									{newOrderDetails?.shipToAddress?.line2 && (
										<div>{newOrderDetails.shipToAddress.line2}</div>
									)}
									{newOrderDetails?.shipToAddress?.city}, {newOrderDetails?.shipToAddress?.state}{" "}
									{newOrderDetails?.shipToAddress?.zip}
								</>
							) : (
								NewOrderUnavailableDataPlaceholderText
							)}
						</InfoText>
					</Label>

					<Label>
						{NewOrderRequestedDeliveryDate}
						<InfoText data-testid="new-order-review-requested-delivery-date">
							{newOrderDetails?.requestedDeliveryDate
								? formattedDeliveryDate
								: NewOrderUnavailableDataPlaceholderText}
						</InfoText>
					</Label>

					<Label>
						{NewOrderDeliveryContact}
						<InfoText data-testid="new-order-review-delivery-contact">
							{newOrderDetails?.shipToAddress?.phoneNumber ? (
								<div>{newOrderDetails.shipToAddress?.phoneNumber}</div>
							) : (
								<div>{NewOrderUnavailableDataPlaceholderText}</div>
							)}
							{newOrderDetails?.shipToAddress?.email}
						</InfoText>
					</Label>

					<Label>
						{NewOrderShippingMethod}
						<InfoText data-testid="new-order-review-shipping-method">
							{newOrderDetails?.isCustomerPickup ? "Pickup" : "Delivery"}
						</InfoText>
					</Label>

					{allowLabelComments?.includes(true) && !newOrderDetails?.isCustomerPickup && (
						<Label>
							{NewOrderShippingLabelNotes}
							<InfoText data-testid="new-order-review-shipping-notes">
								{newOrderDetails?.labelComments}
							</InfoText>
						</Label>
					)}
				</StaticComponentGrid>
			</NewOrderDetailsWrapper>
		</StaticComponentBackground>
	);
};

export default ReviewOrderDetails;
