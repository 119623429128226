import { DialogTitle } from "../Dialog";
import {
	HeaderTitleStyles,
	NewOrderModalDisclaimer,
	UploadCSVDragDropButton,
	UploadErrorHeader,
	UploadErrorContent,
	UploadErrorWarning,
	UploadErrorWaringText,
	UploadCSVDragDropUploadFile,
	UploadModalCSVFileAttachmentViewBytes,
	CSVUploadIsUpdating,
	UploadModalCSVFileInfoContainer,
	UploadModalCSVFileInfoContainerButton
} from "./NewOrders.styles";
import {
	NewOrderModalImportCSV,
	NewOrderModalDisclaimerText,
	NewOrderModalDragFile,
	NewOrdersMaxUploadsError,
	NewOrderModalSupportedFiles,
	NewOrderModalLoadingText,
	NewOrderFileIncorrectTypeText,
	NewOrderSelectedFiles,
	NewOrderUnsupportedFile,
	NewOrderContinueButton,
	NewOrderImportFileError,
	NewOrderIncomingText
} from "./constants";
import { Content } from "pages/Replacements/components/AddNewAddressModal/addNewAddressModal.styles";
import Close from "@mui/icons-material/Close";
import UploadFile from "@mui/icons-material/UploadFile";
import { Button, LinearProgress, Typography } from "@mui/material";
import { BrowseFiles, FileDuplicateText, SupportedFilesHeader } from "constants/text";
import Dragdrop from "../Dragdrop/Dragdrop";
import { formatBytes, isValidCSVFile } from "utils/file";
import { useEffect, useState } from "react";
import { newOrderActions } from "features/newOrder";
import { useUploadCSVMutation } from "features/newOrderApi";
import { MaxFilesAlert } from "../ReplacementUploadModal/replacementUploadModal.styles";
import styles from "pages/order-status-page-styles.module.css";
import { useDispatch } from "react-redux";
import { NewOrderModalTracking } from "data/api/v1";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";

export type ErrorableFileState = {
	error: string;
	file: File;
};

interface CsvUploadProps {
	fileUploads?: File[];
	handleClose: () => void;
}

export const processFileUploadErrorStates = (
	file: File,
	maxMB: number,
	fileUploads: File[] = []
): string | undefined => {
	if (fileUploads.length > 0 && fileUploads.find((selectedFile: File) => selectedFile.name === file.name)) {
		return FileDuplicateText;
	}

	if (file.type !== "text/csv") {
		return NewOrderFileIncorrectTypeText;
	}

	return undefined;
};

const CSVUpload = ({ handleClose, fileUploads }: CsvUploadProps) => {
	const navigateTo = useNavigate();
	const [file, setFile] = useState<ErrorableFileState[]>([]);
	const [error, setError] = useState<string | undefined>();
	const [csvFile, setCSVFile] = useState<File[]>([]);
	const [uploadNewOrderCSV, { isLoading: isUpdating, error: uploadError, reset }] = useUploadCSVMutation();
	const dispatch = useDispatch();

	const uploadStatus = uploadError && "status" in uploadError ? uploadError.status === 400 : false;

	useEffect(() => {
		setError(undefined);
		setFile([]);
		setCSVFile([]);
	}, []);
	const updateFiles = (selectedFiles: Array<File>) => {
		reset();
		setCSVFile([]);
		setFile([]);
		const filteredSelectedFiles = selectedFiles.filter((file: File) => isValidCSVFile(file, fileUploads));

		if (filteredSelectedFiles.length > 1) {
			setError(NewOrdersMaxUploadsError);
			return;
		}

		setCSVFile(filteredSelectedFiles);

		const erroredFiles = selectedFiles.reduce(
			(accum: any, file: File) => {
				const error = processFileUploadErrorStates(file, 10, fileUploads);

				return {
					hasErrors: [!!error, accum.hasErrors].some(Boolean),
					files: [
						...accum.files,
						{
							file: file,
							error: error
						}
					]
				};
			},
			{ hasErrors: false, files: [] }
		);

		if (!erroredFiles.hasErrors) {
			return;
		}

		setFile((prev) => [...prev, ...erroredFiles.files]);
	};

	useEffect(() => {
		if (uploadError && "status" in uploadError) {
			if (uploadError.status === 401) {
				return navigateTo("/unauthorized");
			}

			if (uploadError.status === 500) {
				return navigateTo("/unavailable");
			}
		}
	}, [uploadError, navigateTo]);

	const submitCSV = async () => {
		const formData = new FormData();
		formData.append("file", csvFile[0]);

		try {
			await uploadNewOrderCSV(formData)
				.unwrap()
				.then((response) => dispatch(newOrderActions.addParsedCSV(response)));
			dispatch(newOrderActions.setModalState(NewOrderModalTracking.SELECT_BILLTO_ACCOUNT));
		} catch (error: any) {
			console.error(error);
		}
	};

	const onClose = () => {
		handleClose();
	};

	const errorFiles = file.length;

	return (
		<div>
			<HeaderTitleStyles>
				<DialogTitle
					data-testid="new-order-csv-upload-title"
					title={NewOrderModalImportCSV}
					handleClose={onClose}
				/>
			</HeaderTitleStyles>

			<Content>
				<NewOrderModalDisclaimer data-testid="new-order-csv-upload-disclaimer">
					{!isUpdating ? <p>{NewOrderModalDisclaimerText}</p> : ""}
				</NewOrderModalDisclaimer>

				{isUpdating ? (
					<CSVUploadIsUpdating>
						<img
							src="/assets/ImportingCSVAnimation.png"
							alt="csv-upload-gif"
							className={styles.noServiceSVG}
							data-testid="new-order-csv-upload-loading-image"
						/>

						<DialogTitle
							data-testid="new-order-csv-upload-loading-disclaimer"
							title={NewOrderModalLoadingText}
						/>

						{csvFile.map((file) => (
							<Typography
								data-testid="new-order-csv-upload-file-text"
								variant="body1"
								key={file.name}
							>
								{file.name}
								<UploadModalCSVFileAttachmentViewBytes data-testid="new-order-csv-upload-file-size">
									{formatBytes(file.size)}
								</UploadModalCSVFileAttachmentViewBytes>
							</Typography>
						))}

						<div>
							<LinearProgress
								color="primary"
								variant="indeterminate"
							/>
						</div>
					</CSVUploadIsUpdating>
				) : (
					<Dragdrop
						accept="csv"
						onFileUpload={updateFiles}
					>
						<UploadCSVDragDropUploadFile>
							<UploadFile />
						</UploadCSVDragDropUploadFile>

						<div>
							<p data-testid="new-order-csv-upload-drag-text">{NewOrderModalDragFile}</p>

							<UploadCSVDragDropButton>
								<Button
									variant="outlined"
									data-testid="new-order-csv-upload-browse-button"
								>
									{BrowseFiles}
								</Button>
							</UploadCSVDragDropButton>

							<p data-testid="new-order-csv-upload-supported-file-text">
								{SupportedFilesHeader}{" "}
								<span style={{ fontWeight: 500 }}>{NewOrderModalSupportedFiles}</span>
							</p>
						</div>
					</Dragdrop>
				)}

				{!!error && (
					<div style={{ width: "94%" }}>
						<MaxFilesAlert severity="error">
							<p data-testid="new-order-csv-upload-max-file-text">{error}</p>
							<span>
								<Close
									onClick={() => setError(undefined)}
									sx={{ width: "1.25rem", height: "1.25rem" }}
								/>
							</span>
						</MaxFilesAlert>
					</div>
				)}

				{csvFile.length > 0 && !uploadStatus && !isUpdating && (
					<div>
						<Typography
							variant="subtitle1"
							sx={{ textTransform: "uppercase", letterSpacing: "1px", marginTop: 1 }}
							data-testid="new-order-csv-upload-selected-file-text"
						>
							{NewOrderSelectedFiles}
						</Typography>
						{csvFile.map((file) => (
							<UploadModalCSVFileInfoContainer key={file.name}>
								<p data-testid="new-order-csv-upload-selected-file-name">
									{file.name}
									<UploadModalCSVFileAttachmentViewBytes data-testid="new-order-csv-upload-selected-file-size">
										{formatBytes(file.size)}
									</UploadModalCSVFileAttachmentViewBytes>
								</p>

								<UploadModalCSVFileInfoContainerButton>
									<Button
										data-testid="new-order-csv-upload-selected-file-continue-button"
										variant="contained"
										onClick={submitCSV}
									>
										{NewOrderContinueButton}
									</Button>
								</UploadModalCSVFileInfoContainerButton>
							</UploadModalCSVFileInfoContainer>
						))}
					</div>
				)}

				{(uploadStatus || (file.length > 0 && csvFile.length === 0)) && (
					<div>
						<UploadErrorHeader>
							<Typography data-testid="new-order-csv-upload-error-file-text">
								{uploadStatus ? NewOrderSelectedFiles : NewOrderIncomingText}
							</Typography>
						</UploadErrorHeader>

						<UploadErrorContent>
							{csvFile.map((file) => (
								<Typography
									data-testid="new-order-csv-upload-error-file-text"
									variant="body1"
									color="var(--red-700)"
									key={file.name}
								>
									{file.name}
									<UploadModalCSVFileAttachmentViewBytes data-testid="new-order-csv-upload-error-file-size">
										{formatBytes(file.size)}
									</UploadModalCSVFileAttachmentViewBytes>
								</Typography>
							))}

							{file.map(({ file, error }, index) => (
								<>
									{errorFiles === index + 1 && (
										<Typography
											data-testid="new-order-csv-upload-error-file-text"
											variant="body1"
											color="var(--red-700)"
											key={file.name}
										>
											{file.name}
											<UploadModalCSVFileAttachmentViewBytes data-testid="new-order-csv-upload-error-file-size">
												{formatBytes(file.size)}
											</UploadModalCSVFileAttachmentViewBytes>
										</Typography>
									)}
								</>
							))}

							<div>
								<LinearProgress
									color="error"
									variant="determinate"
									value={error ? 5 : 100}
								/>
							</div>

							<UploadErrorWarning>
								<ErrorOutlineIcon
									data-testid="new-order-csv-upload-error-unsupported-file-icon"
									aria-label="Warning"
								/>
								<UploadErrorWaringText data-testid="new-order-csv-upload-error-unsupported-file-text">
									{uploadStatus ? NewOrderImportFileError : NewOrderUnsupportedFile}
								</UploadErrorWaringText>
							</UploadErrorWarning>
						</UploadErrorContent>
					</div>
				)}
			</Content>
		</div>
	);
};

export default CSVUpload;
