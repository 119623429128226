import Grid, { gridClasses } from "@mui/material/Grid";
import styled from "@emotion/styled";
import Link from "@mui/material/Link";

interface StyledMuiGridProps {
	isWarningProblem: boolean;
}

export const StyledMuiGridContainer = styled(Grid)<StyledMuiGridProps>(({ isWarningProblem }) => ({
	position: "relative",
	[`&.${gridClasses.container}`]: {
		color: isWarningProblem ? "var(--orange-500)" : "var(--cwg-blue-900)",
		backgroundColor: isWarningProblem ? "var(--orange-50)" : "var(--cwg-blue-50)"
	}
}));

export const StyledMuiLink = styled(Link)({
	color: "var(--orange-500)",
	textDecorationColor: "var(--orange-500)"
});
