import { CWGBrandingContainer, CWGBrandingLogo } from "./cwgBranding.styles";

const CWGBranding = () => {
	return (
		<CWGBrandingContainer data-testid="tandem-branding">
			<CWGBrandingLogo
				src="/assets/tandem_logos/tandemReverse.png"
				alt="Tandem Logo"
			/>
		</CWGBrandingContainer>
	);
};

export default CWGBranding;
