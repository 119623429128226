import styled from "@emotion/styled";

interface StyledFormHelperTextProps {
	isLimitError: boolean | undefined;
}

export const StyledFormHelperText = styled.span<StyledFormHelperTextProps>(({ isLimitError, theme }) => ({
	alignItems: "center",
	display: "flex",
	fontFamily: '"Gibson Regular", sans-serif',
	fontSize: "0.75rem",
	fontWeight: "500",
	justifyContent: "space-between",
	lineHeight: "var(--line-height-6)",
	svg: {
		height: 20,
		marginRight: 8,
		width: 20
	},
	"& > span": {
		alignItems: "center",
		display: "flex",
		minHeight: 20,
		"&:nth-of-type(2)": {
			color: isLimitError ? theme.palette.error.main : theme.project.colors.fontColors.secondary
		}
	}
}));
