import { type FC } from "react";
import { MuiLink } from "components/Common/Link/link.styles";
import ChevronLeftRounded from "@mui/icons-material/ChevronLeftRounded";
import { LinkProps as MuiLinkProps } from "@mui/material/Link";

interface LinkProps extends MuiLinkProps<"button"> {
	hasBackIcon?: boolean;
}

const LinkButton: FC<LinkProps> = ({ children, hasBackIcon, type = "button", ...props }) => {
	return (
		<MuiLink
			{...props}
			component="button"
			type={type}
		>
			{hasBackIcon && <ChevronLeftRounded />} <>{children}</>
		</MuiLink>
	);
};

export default LinkButton;
