import styled from "@emotion/styled";

export const HeaderWrapper = styled.div(({ theme }) => ({
	"& > div:nth-of-type(1)": {
		color: theme.project.colors.fontColors.tertiary
	},
	"&:nth-of-type(1)": {
		color: theme.project.colors.fontColors.main,
		display: "flex",
		gap: 16,
		gridColumn: "span 3",
		"div:nth-of-type(1)": {
			lineHeight: "20px"
		},
		"div:nth-of-type(2) > div:nth-of-type(2)": {
			color: theme.project.colors.fontColors.secondary
		}
	},
	[theme.breakpoints.down("md")]: {
		"&:nth-of-type(1)": {
			gridColumn: "1 / -1"
		}
	}
}));

export const LineItemHeaderValue = styled.div({
	display: "flex",
	flexDirection: "column",
	fontSize: "var(--font-size-6)",
	gap: 8,
	"div:first-of-type": {
		fontWeight: 500
	}
});

export const LineItemContentDescription = styled.div(({ theme }) => ({
	fontSize: "var(--font-size-5)",
	[theme.breakpoints.down("md")]: {
		marginBottom: 6
	}
}));
