import styled from "@emotion/styled";
import { Grid } from "@mui/material";

interface ShipToContainerProps {
	hasBorder?: boolean;
}
export const ShipToContainer = styled.div<ShipToContainerProps>(({ theme, hasBorder = false }) => ({
	backgroundColor: theme.project.colors.backgroundColors.main,
	color: theme.project.colors.fontColors.main,
	"& > div:nth-of-type(2)": {
		...theme.project.typography.caption,
		color: theme.project.colors.fontColors.secondary,
		marginBottom: 16
	},
	...(hasBorder && {
		border: theme.project.borders.presets.outline,
		marginTop: 24,
		padding: 16
	})
}));

export const ShipToAddressGridItem = styled(Grid)(({ theme }) => ({
	color: theme.palette.error.main,
	span: {
		marginTop: "2px"
	}
}));

export const SubtitleText = styled.span(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle2
}));

export const ShipToLabel = styled.div(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle1,
	color: theme.project.colors.fontColors.secondary,
	marginBottom: 16
}));

export const InfoText = styled.div(({ theme }) => ({
	...theme.project.typography.body.body1,
	color: theme.project.colors.fontColors.main
}));

export const NewOrderRequestedDeliveryDate = styled.div(({ theme }) => ({
	marginTop: 16,
	"& .MuiInputBase-input": {
		width: 180,
		height: 8
	},
	"& .MuiFormHelperText-root": {
		width: 190
	},
	"& Button": {
		marginTop: 2,
		...theme.project.typography.body.body1,
		color: theme.project.colors.primaryColors.main,
		textDecoration: "underline",
		"&:hover": {
			textDecoration: "underline",
			cursor: "pointer"
		}
	},
	"& Button:nth-child(2)": {
		margin: "7px 0 0 8px",
		float: "right",
		color: theme.project.colors.fontColors.main
	}
}));
