import { LineItemBackground, LineItemErrorMessage, LineItemGridContainer } from "./LineItemGridStyles";
import { FC, ReactNode } from "react";
import LineItemSkeleton from "./LineItemSkeleton";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

interface LineItemGridProps {
	mainRowContent: ReactNode[];
	isLoading?: boolean;
	className?: string;
	modificationRows?: ReactNode[];
	error?: string | null | undefined;
	index?: number;
}
const LineItemGrid: FC<LineItemGridProps> = ({
	className,
	isLoading = false,
	mainRowContent,
	modificationRows,
	error,
	index = 0
}) => {
	return (
		<div>
			<LineItemSkeleton isLoading={isLoading} />
			<LineItemBackground
				isLoading={isLoading}
				variant="outlined"
			>
				{error && error[index] && (
					<LineItemErrorMessage data-testid="line-item-error-message">
						<ErrorOutline />
						<span>{error}</span>
					</LineItemErrorMessage>
				)}
				<LineItemGridContainer
					className={className}
					mainContentColumnCount={mainRowContent.length}
				>
					<div>
						{mainRowContent}
						<div>{modificationRows}</div>
					</div>
					{modificationRows && modificationRows.length > 0 && <div>{modificationRows}</div>}
				</LineItemGridContainer>
			</LineItemBackground>
		</div>
	);
};

export default LineItemGrid;
