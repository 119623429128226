import styled from "@emotion/styled";

interface ReplacementsFlyoutScrollableWrapperProps {
	isMobile: boolean;
}

export const ReplacementsFlyoutScrollableWrapper = styled.div<ReplacementsFlyoutScrollableWrapperProps>(
	({ isMobile }) => ({
		overflowY: "scroll",
		height: `calc(100vh - ${isMobile ? 200 : 150}px)`
	})
);
