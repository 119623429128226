import { forwardRef, HTMLAttributes } from "react";
import { ListboxKeepTyping } from "constants/text";
import {
	ListboxContainer,
	ListboxHeader
} from "pages/Replacements/components/AddNewAddressModal/addNewAddressModal.styles";

const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
	return (
		<div ref={ref}>
			<ListboxContainer>
				<ListboxHeader>{ListboxKeepTyping}</ListboxHeader>
			</ListboxContainer>
			<ul {...props} />
		</div>
	);
});

export default ListboxComponent;
