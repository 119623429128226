import { Tooltip, Typography } from "@mui/material";

interface CreateTooltipProps {
	title: string;
	testId: string;
	showText?: boolean;
	offset?: [number, number];
	open?: boolean;
	className?: string;
}

const TandemTooltip = ({
	title,
	testId,
	showText = true,
	offset = [-1, -15],
	open = false,
	className
}: CreateTooltipProps) => {
	return (
		<Tooltip
			className={className}
			title={
				title &&
				title.length > 0 && (
					<Typography
						style={{
							fontFamily: "Gibson Regular",
							fontSize: "var(--font-size-1)",
							lineHeight: "var(--line-height-17)"
						}}
					>
						{title}
					</Typography>
				)
			}
			PopperProps={{
				modifiers: [
					{
						name: "offset",
						options: {
							offset: offset
						}
					}
				]
			}}
			style={{
				cursor: "pointer",
				marginTop: 0
			}}
			placement="bottom-start"
			open={open}
		>
			<div>
				{showText && (
					<p
						className="body1"
						data-testid={`original-item-${testId}`}
					>
						<div>{title}</div>
					</p>
				)}
			</div>
		</Tooltip>
	);
};

export default TandemTooltip;
