import styled from "@emotion/styled";
import { Switch } from "@mui/material";

export const StyledSwitch = styled(Switch)({
	"& .MuiSwitch-switchBase": {
		"&.Mui-checked": {
			color: "var(--cwg-blue-900)",
			"& + .MuiSwitch-track": {
				backgroundColor: "var(--cwg-blue-800)"
			}
		}
	}
});
