import styles from "pages/replacements-page-styles.module.css";
import { Divider, Grid } from "@mui/material";
import {
	QuantityText,
	ReplacementReviewReplacementReasonText,
	ReplacementReviewUploadedImagesText
} from "constants/text";
import { ReplacementCartItem } from "features/cart";
import ReplacementReasonsHeader from "./ReplacementReasonsHeaderContainer";

interface SelectReplacementReason {
	item: ReplacementCartItem;
}

const SelectedReplacementReason = ({ item }: SelectReplacementReason) => {
	return (
		<div className={styles.selectReplacmentReasonWrapper}>
			<Grid
				container
				className={styles.selectReplacementReasonContainer}
			>
				<ReplacementReasonsHeader item={item} />
				{item.wholeCabinetQuantity > 0 && (
					<Grid container>
						<Grid
							container
							sm={6}
							xs={12}
							className={styles.replaceablePartInformationColumn}
						>
							<Grid item>
								<Grid
									item
									data-testid="reviewReplacementReason-sku-whole-cabinet"
								>
									<span className="body1">{item.sku}</span>
								</Grid>
								<Grid
									item
									data-testid="reviewReplacementReason-description-whole-cabinet"
								>
									<span className="body1">{item.description}</span>
								</Grid>
							</Grid>
							<Grid item>
								<Grid
									item
									data-testid="reviewReplacementReason-quantityColumn-whole-cabinet"
								>
									<span className="subtitle2">{QuantityText}</span>
								</Grid>
								<Grid
									item
									data-testid="reviewReplacementReason-partQuantity-whole-cabinet"
								>
									<span className="body1">{item.wholeCabinetQuantity}</span>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							container
							sm={6}
							xs={12}
							className={styles.reviewReplacementReasonColumn}
						>
							<Grid
								item
								xs={12}
							>
								<span
									className="subtitle2"
									data-testid="reviewReplacementReason-reason-subhead"
								>
									{ReplacementReviewReplacementReasonText}
								</span>
								<span
									className="body1"
									data-testid="reviewReplacementReason-replacement-reason"
								>
									{item.cabinetReplacementReason} - {item.cabinetReplacementProblem}
								</span>
							</Grid>
							<Grid
								item
								xs={12}
							>
								{item.cabinetFileUploads.length > 0 && (
									<div
										className={styles.imageUploadViewContainer}
										data-testid="reviewReplacementReason-replacement-reason-image"
									>
										<p className="subtitle2">{ReplacementReviewUploadedImagesText}</p>
										{item.cabinetFileUploads.map((file) => (
											<div
												key={file.name}
												className="body1"
												data-testid="reviewReplacementReason-replacement-reason-image-name"
											>
												{file.name}
											</div>
										))}
									</div>
								)}
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
						>
							<Divider />
						</Grid>
					</Grid>
				)}

				{item.replaceableParts.map(
					(part) =>
						part.partQuantity > 0 && (
							<Grid
								container
								key={part.id}
							>
								<Grid
									container
									sm={6}
									xs={12}
									className={styles.replaceablePartInformationColumn}
								>
									<Grid
										item
										xs={6}
									>
										<Grid>
											<span
												className="body1"
												data-testid="reviewReplacementReason-replace-part-sku"
											>
												{part.partSku}
											</span>
										</Grid>
										<Grid item>
											<span
												className="body1"
												data-testid="reviewReplacementReason-replace-part-description"
											>
												{part.description}
											</span>
										</Grid>
									</Grid>
									<Grid item>
										<Grid
											item
											data-testid="reviewReplacementReason-replace-part-quantity-title"
										>
											<span className="subtitle2">{QuantityText}</span>
										</Grid>
										<Grid
											item
											data-testid="reviewReplacementReason-replace-part-quantity"
										>
											<span className="body1">{part.partQuantity}</span>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									container
									sm={6}
									xs={12}
									className={styles.reviewReplacementReasonColumn}
								>
									<Grid item>
										<Grid item>
											<span
												className="subtitle2"
												data-testid="reviewReplacementReason-replace-replacement-reason-title"
											>
												{ReplacementReviewReplacementReasonText}
											</span>
										</Grid>
										<Grid item>
											<span
												className="body1"
												data-testid="reviewReplacementReason-replace-replacement-reason"
											>
												{part.partReplacementReason} - {part.partReplacementProblem}
											</span>
										</Grid>
										{part.partFileUploads.length > 0 && (
											<div
												className={styles.imageUploadViewContainer}
												data-testid="reviewReplacementReason-replacement-reason-part-image"
											>
												<p className="subtitle2">{ReplacementReviewUploadedImagesText}</p>
												{part.partFileUploads.map((file) => (
													<div
														key={file.name}
														className="body1"
														data-testid="reviewReplacementReason-replacement-reason-part-image-name"
													>
														{file.name}
													</div>
												))}
											</div>
										)}
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<Divider />
								</Grid>
							</Grid>
						)
				)}
			</Grid>
		</div>
	);
};

export default SelectedReplacementReason;
