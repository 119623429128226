import styles from "../../pages/replacements-page-styles.module.css";
import { Grid } from "@mui/material";
import {
	EditText,
	ReplacementReviewNoShipAddress,
	ReplacementReviewShippingMethodHeader,
	ReplacementReviewShippingToHeader,
	ReplacementShipToAddressSubheaderText,
	ReplacementShipToDeliveryContactSubheaderText,
	ReplacementsShipToFormShippingLabelNotes,
	ReplacementShipToFormDeliveryText,
	ReplacementShipToFormPickupText,
	UnavailableDataPlaceholderText
} from "constants/text";
import { useMemo, useState } from "react";
import { OrderDetailViewModel, ReplacementFormTracking } from "data/api/v1";
import ReplacementsShipToForm from "./ReplacementsShipToForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { OrderDetails, submittingReplacementOrderActions } from "features/submittingReplacementOrder";
import { ShipToAccordion } from "components/Replacements/replacementShipToForm.styles";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model";
import { formatPhoneNumber } from "components/Replacements/ReplacementsUtil";
import { CustomerAccountViewModel } from "data/api/v1/model/customer-account-view-model";
import { LinkButton } from "components/Common/Link";

export interface ReplacementShipToProps {
	orderDetail: OrderDetailViewModel | null;
	accounts: CustomerAccountViewModel | undefined;
}

const ReplacementShipTo = ({ orderDetail, accounts }: ReplacementShipToProps) => {
	const dispatch = useDispatch();
	const [editShippingInformation, setEditShippingInformation] = useState(false);
	const details: OrderDetails = useSelector((state: RootState) => state.submittingReplacementOrder.order);
	const shippingInfo: (ShipToViewModel | null)[] = useMemo(
		() => accounts?.billTos?.map((address) => address.shipTos)?.flat() ?? [],
		[accounts?.billTos]
	);

	const [apiAddress] = useMemo(() => shippingInfo?.filter((address) => address?.isSelected) ?? [], [shippingInfo]);

	const selectedShippingAddress = details.shippingDetails ?? apiAddress;

	const phoneNumber = formatPhoneNumber(selectedShippingAddress?.address.phoneNumber ?? undefined);
	const hasAddress = Boolean(selectedShippingAddress?.address.city);

	const editState = useSelector((state: RootState) => state.submittingReplacementOrder.editState);
	const editStateNone = editState === ReplacementFormTracking.NONE;

	const isReview = window.location.toString().includes("review");
	const handleEditShippingInformation = () => {
		setEditShippingInformation((prevState) => !prevState);
		const nextState =
			editState === ReplacementFormTracking.SHIPPING
				? ReplacementFormTracking.NONE
				: ReplacementFormTracking.SHIPPING;
		dispatch(submittingReplacementOrderActions.setEditState(nextState));
	};

	return (
		<Grid
			container
			className={`${styles.orderDetailContainer} ${isReview ? "" : styles.shippingAndBillingInfo} `}
		>
			<Grid
				container
				xs={12}
				justifyContent="space-between"
			>
				{editState !== ReplacementFormTracking.SHIPPING && (
					<Grid item>
						<span className="subtitle1">{ReplacementReviewShippingToHeader}</span>
					</Grid>
				)}
				<Grid item>
					{isReview && editStateNone && (
						<LinkButton
							data-testid="replacement-review-order-details-edit-ship-to-link"
							data-id="replacement-review-order-details-edit-ship-to-link"
							onClick={handleEditShippingInformation}
						>
							{EditText}
						</LinkButton>
					)}
				</Grid>
			</Grid>

			{editState !== ReplacementFormTracking.SHIPPING ? (
				<>
					<Grid
						container
						direction="column"
						xs={12}
						md={6}
					>
						<Grid item>
							<span className="subtitle2">{ReplacementShipToAddressSubheaderText}</span>
						</Grid>
						{!hasAddress && (
							<Grid container>
								<Grid item>
									<span className="body2">{UnavailableDataPlaceholderText}</span>
								</Grid>
								<Grid
									item
									xs={12}
								>
									<div
										className={styles.orderListErrorsWarning}
										data-testid="replacement-ship-to-use-edit-link"
									>
										<WarningAmber />
										<span data-testid="replacement-ship-to-use-edit-link">
											{ReplacementReviewNoShipAddress}
										</span>
									</div>
								</Grid>
							</Grid>
						)}
						{selectedShippingAddress && hasAddress && (
							<Grid
								container
								direction="column"
								className={styles.shipToComponentSection}
							>
								<span
									className="body2"
									data-testid="ship-to-name"
								>
									<Grid item>{selectedShippingAddress?.address.name}</Grid>
								</span>
								<span
									className="body2"
									data-testid="ship-to-line1"
								>
									<Grid item>{selectedShippingAddress?.address.line1}</Grid>
								</span>
								<span
									className="body2"
									data-testid="ship-to-line2"
								>
									<Grid item>{selectedShippingAddress?.address.line2}</Grid>
								</span>
								<span
									className="body2"
									data-testid="ship-to-line3"
								>
									<Grid item>{selectedShippingAddress?.address.line3}</Grid>
								</span>
								<span
									className="body2"
									data-testid="ship-to-city-state-zip"
								>
									<Grid item>
										{selectedShippingAddress?.address.city},{" "}
										{selectedShippingAddress?.address.state} {selectedShippingAddress?.address.zip}
									</Grid>
								</span>
							</Grid>
						)}
					</Grid>

					{selectedShippingAddress && (
						<Grid
							container
							direction="column"
							xs={12}
							md={6}
						>
							<Grid item>
								<span className="subtitle2">{ReplacementShipToDeliveryContactSubheaderText}</span>
							</Grid>
							<Grid item>
								<span
									className="body2"
									data-testid="ship-to-phone"
								>
									{phoneNumber && phoneNumber.length !== 0
										? phoneNumber
										: UnavailableDataPlaceholderText}
								</span>
							</Grid>
							<Grid
								item
								className={styles.shipToComponentSection}
							>
								<span
									className="body2"
									data-testid="ship-to-email"
								>
									{details?.shippingDetails?.address.email ?? ""}
								</span>
							</Grid>
							<Grid item>
								<span className="subtitle2">{ReplacementReviewShippingMethodHeader}</span>
							</Grid>
							<Grid item>
								<span
									className="body2"
									data-testid="replacement-ship-to-shipping-method"
								>
									{details?.isCustomerPickup
										? ReplacementShipToFormPickupText
										: ReplacementShipToFormDeliveryText}
								</span>
							</Grid>
						</Grid>
					)}
					{accounts?.allowLabelComments && !details.isCustomerPickup && (
						<Grid
							container
							xs={12}
							sm={6}
							direction="column"
						>
							<Grid
								item
								sx={{
									"@media (max-width: 900px)": {
										paddingTop: "1rem"
									}
								}}
							>
								<span className="subtitle2">{ReplacementsShipToFormShippingLabelNotes}</span>
								<br />
								<span
									className="body2"
									data-testid="ship-to-comments"
								>
									{(details?.labelComments
										? details?.labelComments
										: UnavailableDataPlaceholderText) ??
										(orderDetail?.comments
											? orderDetail?.comments
											: UnavailableDataPlaceholderText)}
								</span>
							</Grid>
						</Grid>
					)}
				</>
			) : (
				<ShipToAccordion
					elevation={0}
					expanded={editShippingInformation}
					className={styles.shippingInformationAccordion}
				>
					<ReplacementsShipToForm
						accounts={accounts}
						handleEditShippingInformation={handleEditShippingInformation}
					/>
				</ShipToAccordion>
			)}
		</Grid>
	);
};

export default ReplacementShipTo;
