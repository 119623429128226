import {
	EstimatedSubtotalCard,
	EstimatedSubtotalContainer,
	EstimatedSubtotalInnerContainer,
	EstimatedSubtotalTextBold,
	EstimatedSubtotalTextSmall
} from "components/NewOrders/ViewPriceEstimates/viewPriceEstimates.styles";
import {
	NewOrderViewPriceEstimateEstimatedSubtotal,
	NewOrderViewPriceEstimatePromotionalPricingText
} from "../constants";

interface EstimatedSubTotalProps {
	subtotal: number;
}

const EstimatedSubtotal = ({ subtotal }: EstimatedSubTotalProps) => {
	return (
		<EstimatedSubtotalCard>
			<EstimatedSubtotalContainer>
				<EstimatedSubtotalInnerContainer>
					<EstimatedSubtotalTextBold>{NewOrderViewPriceEstimateEstimatedSubtotal}</EstimatedSubtotalTextBold>
					<div data-testid="view-price-estimate-estimated-subtotal">
						{subtotal.toLocaleString("en-US", {
							style: "currency",
							currency: "USD"
						})}
					</div>
				</EstimatedSubtotalInnerContainer>
				<EstimatedSubtotalTextSmall>
					{NewOrderViewPriceEstimatePromotionalPricingText}
				</EstimatedSubtotalTextSmall>
			</EstimatedSubtotalContainer>
		</EstimatedSubtotalCard>
	);
};

export default EstimatedSubtotal;
