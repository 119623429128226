/**
 *
 * @export
 * @enum {string}
 */
export enum ReplacementFormTracking {
	SHIPPING = "SHIPPING",
	ORDER = "ORDER",
	NONE = "NONE"
}
