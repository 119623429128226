import { Accordion } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import styles from "pages/order-details-items-styles.module.css";
import AccordionDetails from "@mui/material/AccordionDetails";
import { OnBackorderAccordionText, OnBackorderText } from "constants/text";
import OrderDetailsOnBackorderAccordionGrid from "./OrderDetailsOnBackorderAccordionGrid";
import { LineItemProps } from "./OrderDetailsLineItem";
import { StyledAccordionSummary } from "components/OrderDetailsLineItem/orderDetailsOnBackorderAccordion.styles";
import { useState } from "react";

export const OrderDetailsOnBackorderAccordion = ({ orderDetail, lineItemsOnBackorderCount }: LineItemProps) => {
	const [accordionIsOpen, setAccordionIsOpen] = useState(true);
	return (
		<div className={styles.accordionBannerContainer}>
			<Accordion
				defaultExpanded
				data-testid="on-backorder-accordion"
				key={orderDetail?.orderId}
				onChange={() => setAccordionIsOpen((prev) => !prev)}
			>
				<StyledAccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ color: "var(--orange-500)" }} />}
					aria-controls="panel1a-content"
					id="panel1a-header-backorder"
					data-id={`on-backorder-accordion-${accordionIsOpen ? "open" : "closed"}`}
				>
					<Typography
						sx={{
							fontFamily: "Gibson Medium, sans-serif",
							fontSize: "var(--font-size-6)",
							color: "var(--orange-500)",
							display: "flex"
						}}
					>
						<img
							src="/assets/BackorderIcon.svg"
							alt="Order Details Backorder Logo"
							data-testid="backorder-logo"
							className={styles.backorderAndShippingIcon}
						/>
						<div style={{ display: "ruby" }}>
							{OnBackorderText} ({lineItemsOnBackorderCount})
						</div>
					</Typography>
				</StyledAccordionSummary>
				<AccordionDetails>
					<Typography
						sx={{
							fontFamily: "Gibson Medium, sans-serif",
							fontSize: "var(--font-size-4)",
							paddingTop: "0.5rem",
							paddingBottom: "0.5rem",
							color: "var(--orange-500)",
							display: "flex"
						}}
					>
						{OnBackorderAccordionText}
					</Typography>
					<div>
						{orderDetail?.lineItems?.map((item, index) => (
							<OrderDetailsOnBackorderAccordionGrid
								key={index}
								orderDetail={orderDetail}
								partsQuantityBackordered={item?.partInfo?.partsQuantityBackordered}
								parts={item.partInfo.parts}
								sku={item?.sku}
								itemDescription={item?.description}
								quantityBackordered={item?.quantityBackordered}
							/>
						))}
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default OrderDetailsOnBackorderAccordion;
