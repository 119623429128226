import { Skeleton } from "@mui/material";
import styles from "pages/order-status-page-styles.module.css";

const OrderDetailsHeaderSkeleton = () => {
	return (
		<div className={styles.orderDetailsSummarySkeleton}>
			<Skeleton animation="wave" />
			<Skeleton
				animation="wave"
				variant="rounded"
				height={150}
				sx={{ marginTop: "0.5rem" }}
			/>
		</div>
	);
};

export default OrderDetailsHeaderSkeleton;
