import { ConfirmationThanksContainer, ConfirmationThanksHeader } from "./ConfirmationThanks.styles";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { newOrderActions } from "features/newOrder";
import { submittingNewOrderActions } from "features/submittingNewOrder";
import { useDispatch } from "react-redux";
import Download from "@mui/icons-material/Download";
import { NewOrderExport } from "components/NewOrders/constants";
import { shippingDetailsActions } from "features/shippingDetails";

interface ConfirmationThanksProps {
	header: string;
	subheader: string;
	button: string;
}

const ConfirmationThanks = ({ header, subheader, button }: ConfirmationThanksProps) => {
	const dispatch = useDispatch();
	const navigateTo = useNavigate();
	const returnToOrders = () => {
		dispatch(newOrderActions.clearNewOrder());
		dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
		dispatch(shippingDetailsActions.clearShippingDetails());
		navigateTo("/");
	};

	return (
		<ConfirmationThanksContainer>
			<ConfirmationThanksHeader data-testid="new-order-confirmation-thank-you-header">
				<div>{header}</div>
				<div>
					{NewOrderExport}
					<Download />
				</div>
			</ConfirmationThanksHeader>

			<div data-testid="new-order-confirmation-thank-you-subheader">{subheader}</div>

			<Button
				variant="contained"
				onClick={returnToOrders}
				data-testid="new-order-confirmation-details-button"
			>
				{button}
			</Button>
		</ConfirmationThanksContainer>
	);
};

export default ConfirmationThanks;
