import styled from "@emotion/styled";
import { LoadingSkeletonProps } from "../Common/LineItemGrid/LineItemGridStyles";

export const ErrorBannerStyles = styled.div<LoadingSkeletonProps>(({ isLoading }) => ({
	display: isLoading ? "none" : "flex",
	padding: "1rem",
	backgroundColor: "var(--error-background)",
	margin: "1rem 0",
	color: "var(--red-700)",
	h1: {
		fontFamily: "Gibson Medium, san-serif",
		fontSize: "var(--font-size-5)",
		marginTop: "0.25rem",
		marginBottom: "0.25rem"
	},
	svg: {
		marginRight: "0.5rem"
	},
	"p, li": {
		fontFamily: "Gibson Regular, san-serif",
		fontSize: "var(--font-size-3)",
		marginTop: "0.25rem",
		marginLeft: "2rem"
	}
}));
