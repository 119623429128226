import { Divider, IconButton } from "@mui/material";
import useB2CAccount from "auth/useB2CAccount";
import { UserViewModel } from "data/api/v1";
import LeavePageModal from "components/LeavePageModal/LeavePageModal";
import { useState } from "react";
import { ReplacementCartItem } from "features/cart";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { UserInfoContainer, UserInfoLogoutIcon, UserName } from "./userInfo.styles";

interface UserInfoProps {
	user?: UserViewModel;
}

const UserInfo = ({ user }: UserInfoProps) => {
	const { logout } = useB2CAccount();
	const cartItems: ReplacementCartItem[] = useSelector((state: RootState) => state.cart.replacements);

	const handleLogout = async () => {
		if (!leavePageModalOpen) {
			if (cartItems && cartItems.length > 0 && window.location.href.includes("/replacements")) {
				setLeavePageModalOpen(true);
			} else {
				await logout();
			}
		}
	};

	const [leavePageModalOpen, setLeavePageModalOpen] = useState(false);

	return (
		<UserInfoContainer data-testid="user-info">
			<LeavePageModal
				open={leavePageModalOpen}
				url="logout"
				continueOrdering={() => setLeavePageModalOpen(false)}
			/>
			<Divider data-testid="user-info-divider-top" />
			<div>
				{user?.firstName !== undefined && (
					<UserName data-testid="user-info-user-designer-name">{`${user?.firstName} ${user?.lastName}`}</UserName>
				)}
				<IconButton
					onClick={handleLogout}
					data-id="user-info-sign-out-button"
				>
					<UserInfoLogoutIcon data-testid="user-information-logout" />
				</IconButton>
			</div>
			<Divider data-testid="user-info-divider-bottom" />
		</UserInfoContainer>
	);
};

export default UserInfo;
