import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "../data/api/v1/endpoints";
import { baseQueryWithRedirect } from "./apiUtils";
import { UserDetailsResponse } from "data/api/v1";

export const userApiSlice = createApi({
	reducerPath: "userApi",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		getUserDetails: builder.query<UserDetailsResponse, void>({
			query: () => endpoints.users.getUserDetails(),
			transformResponse: (response: any) => ({ user: response?.data }),
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		})
	})
});

export const { useGetUserDetailsQuery } = userApiSlice;
