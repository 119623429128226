import { InferType, object, string } from "yup";
import { PONumberRequiredText } from "constants/text";

export const PO_NUMBER_MAX_LENGTH = 25;
export const JOB_NAME_MAX_LENGTH = 30;

export const schema = object({
	poNumber: string().max(PO_NUMBER_MAX_LENGTH).required(PONumberRequiredText).nullable(),
	jobName: string().max(JOB_NAME_MAX_LENGTH).nullable()
});

export type OrderDetailsFields = InferType<typeof schema>;
