import { type FC, useEffect, useMemo, useState } from "react";
import {
	AddNewAddressLink,
	EmailLabel,
	PhoneLabel,
	PhoneLabelOptional,
	ReplacementReviewAddress,
	ReplacementReviewShippingToHeader,
	ReplacementsCancelButtonText,
	ReplacementShipToFormDeliveryText,
	ReplacementShipToFormPickupText,
	ReplacementShipToFormUsingInfoText,
	ReplacementShipToOrderContactText,
	ReplacementShipToShippingMethodText,
	ReplacementsShipToFormShippingLabelNotes,
	ReplacementsShipToFormShippingLabelSubheader,
	RequiredFieldsText,
	SaveButtonText
} from "constants/text";
import {
	NewOrderRequestedDateHelperText,
	NewOrderRequestedDateLabel,
	NewOrderRequestedDateLaterDateButton,
	NewOrderRemoveButton,
	SelectAnAddress
} from "components/NewOrders/constants";
import { AddressFormFields } from "pages/Replacements/forms/AddressForm/schema";
import AddNewAddressModal from "pages/Replacements/components/AddNewAddressModal/AddNewAddressModal";
import AddressFormProvider from "pages/Replacements/forms/AddressForm/AddressFormProvider";
import { Button, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { AddAddress, ReplacementsShipToFormTextField } from "components/Replacements/replacementShipToForm.styles";
import { getFormFieldProps } from "utils/form";
import MaskedPhoneTextField from "components/Replacements/components/MaskedPhoneTextField/MaskedPhoneTextField";
import styles from "pages/replacements-page-styles.module.css";
import {
	DELIVERY_INSTRUCTIONS_MAX_LENGTH,
	END_BUSINESS_DAY_RANGE,
	schema,
	START_BUSINESS_DAY_RANGE,
	type FormSchema as ShipToFormFields
} from "components/Replacements/ShipToForm/schema";
import { schema as contextSchema } from "components/NewOrders/NewOrderShippingDetailsForm/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	InfoText,
	ShipToAddressGridItem,
	ShipToContainer,
	ShipToLabel,
	SubtitleText,
	NewOrderRequestedDeliveryDate
} from "components/ShipToForm/shipToForm.styles";
import ConditionalForm from "./ConditionalForm";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model";
import AutoComplete from "components/Common/Autocomplete/Autocomplete";
import { format, addBusinessDays } from "date-fns";
import { LinkButton } from "../Common/Link";

interface DefaultFormValues {
	email?: string;
	phoneNumber?: string | null;
	deliveryInstructions?: string;
	address?: ShipToViewModel;
}

interface ShipToFormProps {
	allowLabelComments: boolean;
	analyticsDataId: string;
	combinedAddresses: ShipToViewModel[];
	customerPickupAllowed: boolean;
	defaultFormValues?: DefaultFormValues | null;
	handleAddNewAddressModalSubmit: (address: AddressFormFields) => void;
	handleOnCancel: () => void;
	handleOnSave: (fields: ShipToFormFields | AddressFormFields, formIsCustomerPickup?: boolean) => void;
	handleSetShippingInformation: (address: ShipToViewModel | undefined) => void;
	hasFormContext?: boolean;
	isCustomerPickupDefault?: boolean;
	showCancelSaveButtons?: boolean;
}

const ShipToForm: FC<ShipToFormProps> = ({
	allowLabelComments,
	analyticsDataId,
	combinedAddresses,
	customerPickupAllowed,
	defaultFormValues,
	handleAddNewAddressModalSubmit,
	handleOnCancel,
	handleOnSave,
	handleSetShippingInformation,
	hasFormContext,
	isCustomerPickupDefault,
	showCancelSaveButtons = false
}) => {
	const [isCustomerPickup, setIsCustomerPickup] = useState<boolean>(isCustomerPickupDefault ?? false);
	const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);

	const defaultValues = useMemo(
		() =>
			defaultFormValues ?? {
				email: "",
				phoneNumber: "",
				deliveryInstructions: "",
				address: undefined
			},
		[defaultFormValues]
	);

	const contextFormProps = useFormContext<ShipToFormFields>();
	const localFormProps = useForm<ShipToFormFields>({
		mode: "onChange",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		defaultValues
	});

	const { control, formState, handleSubmit, register, setValue, trigger, watch, clearErrors, getValues } = useMemo(
		() => (hasFormContext ? contextFormProps : localFormProps),
		[contextFormProps, hasFormContext, localFormProps]
	);

	const [isRequestedDeliveryDate, setIsRequestedDeliveryDate] = useState<boolean>(
		Boolean(getValues().requestedDeliveryDate)
	);

	const address = watch("address");

	const isPhoneRequired = !isCustomerPickup && address && !address.shipToId;

	useEffect(() => {
		if (defaultFormValues) {
			const selectedAddress = combinedAddresses.find((address) => address.isSelected);
			if (defaultValues?.address || !combinedAddresses.find((address) => address.isSelected)) {
				setValue("address", defaultValues?.address);
			} else if (selectedAddress && !defaultValues?.address) {
				setValue("address", selectedAddress);
			}

			setValue("phoneNumber", defaultValues.phoneNumber);
			setValue("email", defaultValues.email);
			setValue("deliveryInstructions", defaultValues.deliveryInstructions);
		}
	}, [
		defaultFormValues,
		defaultValues,
		defaultValues.address,
		defaultValues.email,
		defaultValues.phoneNumber,
		defaultValues.deliveryInstructions,
		setValue,
		combinedAddresses
	]);

	const formFieldData = { formState, register, schema: contextSchema };
	const shipToFormLabelComments = watch("deliveryInstructions") ?? "";

	const handleShippingSelection = (event: any) => {
		if (event.target.value === ReplacementShipToFormDeliveryText) {
			setIsCustomerPickup(false);
			setValue("isCustomerPickup", false);
		} else {
			setIsCustomerPickup(true);
			setValue("isCustomerPickup", true);
		}
	};

	const [resetAutocomplete, setResetAutocomplete] = useState(false);

	const getComparableString = (shipTo: ShipToViewModel): string => {
		return (
			shipTo.address.line1?.trim() ??
			shipTo.address.line2?.trim() ??
			shipTo.address.line3?.trim() ??
			shipTo.address.city?.trim() ??
			shipTo.address.state?.trim() ??
			shipTo.address.zip?.trim() ??
			""
		);
	};

	const sortAddresses = (addresses: ShipToViewModel[]): ShipToViewModel[] => {
		return addresses
			.filter((address, index, self) => {
				const hasOnlyUndefinedValues =
					address.address && Object.values(address.address).every((value) => value === undefined);

				if (!address.address || hasOnlyUndefinedValues) {
					return false;
				}
				// keeping this code until we confirm we want duplicates in the dropdown or not
				// const aComparable = getComparableString(address).toLowerCase();
				// return index === self.findIndex((other) => getComparableString(other).toLowerCase() === aComparable);
				return true;
			})
			.sort((a, b) => {
				const aComparable = getComparableString(a).toLowerCase();
				const bComparable = getComparableString(b).toLowerCase();

				if (aComparable < bComparable) return -1;
				if (aComparable > bComparable) return 1;
				return 0;
			});
	};

	const handleRequestedDeliveryDate = () => {
		setValue("requestedDeliveryDate", "");
		clearErrors("requestedDeliveryDate");
		setIsRequestedDeliveryDate((prevState) => !prevState);
		setDeliveryDateType("text");
	};

	const isConfirmation = window.location.toString().includes("new-order/shipping-details");

	const [deliveryDateType, setDeliveryDateType] = useState(getValues().requestedDeliveryDate ? "date" : "text");

	const getBusinessDayDate = (days: number) => {
		return format(addBusinessDays(new Date(), days), "yyyy-MM-dd");
	};

	const fiveMinFormatted = getBusinessDayDate(START_BUSINESS_DAY_RANGE);
	const ninetyFormatted = getBusinessDayDate(END_BUSINESS_DAY_RANGE);

	return (
		<ShipToContainer hasBorder={hasFormContext}>
			<ShipToLabel>{ReplacementReviewShippingToHeader}</ShipToLabel>
			<div>{RequiredFieldsText}</div>
			<ConditionalForm
				hasForm={showCancelSaveButtons}
				onSubmit={handleSubmit((formValues) => handleOnSave(formValues, isCustomerPickup))}
			>
				<Grid container>
					<Grid container>
						<Grid
							item
							xs={12}
						>
							<SubtitleText>{ReplacementShipToShippingMethodText}</SubtitleText>
						</Grid>
						{customerPickupAllowed ? (
							<Grid
								item
								xs={12}
							>
								<FormControl>
									<RadioGroup
										row
										onChange={handleShippingSelection}
									>
										<FormControlLabel
											value="Delivery"
											checked={!isCustomerPickup}
											control={<Radio size="small" />}
											label={<span className="body1">{ReplacementShipToFormDeliveryText}</span>}
											className="body1"
											data-testid="shipToForm-delivery-radioButton"
										/>
										<FormControlLabel
											value="Pickup"
											checked={isCustomerPickup}
											control={<Radio size="small" />}
											label={<span className="body1">{ReplacementShipToFormPickupText}</span>}
											className="body1"
											data-testid="shipToForm-pickup-radioButton"
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
						) : (
							<Grid
								item
								xs={12}
							>
								<span className="body1">Delivery</span>
							</Grid>
						)}
					</Grid>

					{isConfirmation && (
						<NewOrderRequestedDeliveryDate>
							{!isRequestedDeliveryDate ? (
								<LinkButton
									data-testid="new-order-requested-delivery-date-button"
									onClick={handleRequestedDeliveryDate}
								>
									{NewOrderRequestedDateLaterDateButton}
								</LinkButton>
							) : (
								<>
									<TextField
										{...getFormFieldProps({
											name: "requestedDeliveryDate",
											...formFieldData,
											helperText: NewOrderRequestedDateHelperText
										})}
										data-testid="new-order-requested-delivery-date-field"
										label={NewOrderRequestedDateLabel}
										type={deliveryDateType}
										placeholder="MM/DD/YYYY"
										onFocus={() => setDeliveryDateType("date")}
										onBlur={() => trigger("requestedDeliveryDate")}
										InputLabelProps={{ shrink: true }}
										InputProps={{
											inputProps: {
												min: fiveMinFormatted,
												max: ninetyFormatted
											}
										}}
									/>

									<LinkButton
										data-testid="new-order-requested-delivery-date-remove-button"
										onClick={handleRequestedDeliveryDate}
									>
										{NewOrderRemoveButton}
									</LinkButton>
								</>
							)}
						</NewOrderRequestedDeliveryDate>
					)}

					<Grid
						item
						xs={12}
						sx={{ padding: "1rem 0" }}
					>
						<Divider />
					</Grid>
					{!isCustomerPickup && (
						<Grid container>
							<Grid
								item
								xs={12}
								sx={{ marginBottom: "8px" }}
							>
								<span className="subtitle2">{ReplacementReviewAddress}</span>
							</Grid>
							{combinedAddresses.length > 0 && (
								<ShipToAddressGridItem
									item
									xs={12}
								>
									<Controller
										name="address"
										control={control}
										render={({ field: { onChange, value = null }, fieldState: { error } }) => {
											let defaultValue = null;
											if (defaultFormValues?.address) {
												defaultValue = defaultFormValues;
											} else {
												const selectedAddress = combinedAddresses.find(
													(address) => address.isSelected
												);
												if (selectedAddress) {
													defaultValue = selectedAddress;
												}
											}
											return (
												<AutoComplete
													key={String(resetAutocomplete)}
													options={sortAddresses(combinedAddresses)}
													value={value}
													getOptionLabel={(option: ShipToViewModel | undefined) => {
														const formatAddress = (address: ShipToViewModel): string => {
															return `${address.address.line1?.trim() ? address.address.line1 + " " : ""}${address.address.line2?.trim() ? address.address.line2 + " " : ""}${address.address.line3?.trim() ? address.address.line3 + " " : ""}${address.address.city}, ${address.address.state} ${address.address.zip}`;
														};
														return option ? formatAddress(option) : "";
													}}
													onChange={(event, value) => {
														handleSetShippingInformation(value);
														onChange(value);
														trigger("phoneNumber");
													}}
													defaultValue={defaultValue}
													isError={!!error}
													errorText={error?.message}
													label={SelectAnAddress}
													dataTestId="replacementsShipToForm-address-select"
													required
													disableClearable
												/>
											);
										}}
									/>
								</ShipToAddressGridItem>
							)}
							{/* role is for GA targeting */}
							<AddAddress
								onClick={() => setIsNewAddressModalOpen(true)}
								role="button"
								data-id={analyticsDataId}
							>
								{AddNewAddressLink}
							</AddAddress>
							<Grid
								item
								xs={12}
								sx={{ padding: "1rem 0" }}
							>
								<Divider />
							</Grid>
						</Grid>
					)}
					<Grid
						container
						spacing={2}
						justifyContent="space-between"
					>
						<Grid
							item
							xs={12}
						>
							<span className="subtitle2">{ReplacementShipToOrderContactText}</span>
							<br />
							<InfoText>{ReplacementShipToFormUsingInfoText}</InfoText>
						</Grid>
						<Grid
							xs={12}
							sm={6}
							item
						>
							<Controller
								control={control}
								render={({ field: { onChange, value = "" } }) => (
									<ReplacementsShipToFormTextField
										{...getFormFieldProps({
											name: "phoneNumber",
											schema: contextSchema,
											formState,
											required: isPhoneRequired
										})}
										InputLabelProps={{
											shrink: Boolean(watch("phoneNumber"))
										}}
										data-testid="shipToForm-phoneNumber-textField"
										label={isPhoneRequired ? PhoneLabel : PhoneLabelOptional}
										size="small"
										InputProps={{
											inputComponent: MaskedPhoneTextField as any
										}}
										onChange={onChange}
										value={value}
									/>
								)}
								name="phoneNumber"
							/>
						</Grid>
						<Grid
							xs={12}
							sm={6}
							item
						>
							<ReplacementsShipToFormTextField
								{...getFormFieldProps({ name: "email", ...formFieldData })}
								InputLabelProps={{
									shrink: Boolean(watch("email"))
								}}
								label={EmailLabel}
								type="email"
								size="small"
								data-testid="shipToForm-email-textField"
							/>
						</Grid>
					</Grid>
					{!isCustomerPickup && allowLabelComments && (
						<Grid container>
							<Grid
								item
								xs={12}
								sx={{ padding: "1rem 0" }}
							>
								<Divider />
							</Grid>
							<Grid item>
								<span className="subtitle2">{ReplacementsShipToFormShippingLabelNotes}</span>
								<br />
								<InfoText>{ReplacementsShipToFormShippingLabelSubheader}</InfoText>
							</Grid>
							<Grid
								item
								xs={12}
								className={styles.deliveryInstructionsInputField}
							>
								<ReplacementsShipToFormTextField
									{...getFormFieldProps({
										name: "deliveryInstructions",
										...formFieldData,
										characterLimit: {
											currentLength: shipToFormLabelComments.length,
											limit: DELIVERY_INSTRUCTIONS_MAX_LENGTH
										}
									})}
									label="Shipping Label Notes (optional)"
									value={shipToFormLabelComments}
									multiline
									rows={4}
									data-testid="shipToForm-labelCommentsTextField"
								/>
							</Grid>
						</Grid>
					)}
					{showCancelSaveButtons && (
						<Grid
							container
							spacing={2}
							justifyContent="space-between"
							className={styles.shipToFormButtons}
						>
							<Grid
								item
								xs={6}
							>
								<Button
									variant="outlined"
									fullWidth
									onClick={handleOnCancel}
									sx={{ marginLeft: 0 }}
									data-testid="shipToForm-cancel-button"
									data-id="shipToForm-cancel-button"
								>
									{ReplacementsCancelButtonText}
								</Button>
							</Grid>
							<Grid
								item
								xs={6}
							>
								<Button
									data-testid="shipToForm-save-button"
									data-id="shipToForm-save-button"
									variant="contained"
									fullWidth
									sx={{ marginLeft: 0 }}
									type="submit"
								>
									{SaveButtonText}
								</Button>
							</Grid>
						</Grid>
					)}
				</Grid>
				<AddressFormProvider>
					<AddNewAddressModal
						handleClose={() => setIsNewAddressModalOpen(false)}
						open={isNewAddressModalOpen}
						onSubmit={(address) => {
							handleAddNewAddressModalSubmit(address);
							setResetAutocomplete((prev) => !prev);
							setIsNewAddressModalOpen(false);
							const shippingAddress: ShipToViewModel = {
								shipToId: null,
								isActive: true,
								isSelected: true,
								address: {
									name: address.fullName,
									email: address.email,
									phoneNumber: address.contactPhoneNumber,
									line1: address.address,
									line2: address.address2,
									line3: null,
									city: address.city,
									state: address.state,
									zip: address.zip,
									county: address.county
								}
							};
							setValue("address", shippingAddress);
							setValue("phoneNumber", shippingAddress.address.phoneNumber);
							setValue("email", shippingAddress.address.email);
							clearErrors("address");
						}}
					/>
				</AddressFormProvider>
			</ConditionalForm>
		</ShipToContainer>
	);
};

export default ShipToForm;
