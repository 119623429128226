import { Chip } from "@mui/material";
import { checkForAndReplaceNullEmptyWhitespace, convertToTitleCase } from "utils/string";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import { brandCheck } from "utils/order";
import {
	ArrowContainer,
	ArrowForward,
	AssociatedOrdersData,
	CardContainer,
	CardContainerGrid,
	CardDataStyles,
	CardHeader
} from "../OrderDetailsHeader.styles";
import {
	DoorStyleText,
	ETATextOnly,
	FinishText,
	OrderDateText,
	OrderNumberText,
	PONumberText,
	SingleCardTruckText
} from "constants/text";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { formatDateToMMDDYYYY } from "utils/date";
import { SingleCardOrderStatusPillChip, SingleCardOrderStatusReasonPillChip } from "../../SingleCard/SingleCard.styles";
import { OrderSummaryViewModel } from "data/api/v1";

interface IndividualLinkedOrderCardProps {
	goToOrderDetails: (orderId: string) => void;
	associatedOrder: OrderSummaryViewModel;
}

const IndividualLinkedOrderCard = ({ goToOrderDetails, associatedOrder }: IndividualLinkedOrderCardProps) => {
	return (
		<CardContainer
			onClick={() => goToOrderDetails(checkForAndReplaceNullEmptyWhitespace(associatedOrder?.orderId) as string)}
			data-testid="linkedOrders-content"
		>
			<CardContainerGrid>
				<div>
					<CardHeader>
						<h2>{associatedOrder?.jobName}</h2>
						<Chip
							label={convertToTitleCase(associatedOrder?.orderType)}
							sx={{
								color: "var(--text-primary)",
								fontFamily: ["Gibson Medium", "sans-serif"].join(","),
								minWidth: "max-content",
								height: 24,
								margin: "0 0 0.5rem 0",
								"& .MuiChip-label": {
									fontSize: "var(--font-size-4)",
									letterSpacing: 0.46
								}
							}}
							data-testid="orderDetailsHeader-associatedOrders-chip"
						/>
					</CardHeader>

					{associatedOrder?.parentBrand && (
						<StyledSummaryCardLogo
							data-testid="linkedOrders-brand"
							parentBrand={associatedOrder.parentBrand}
							src={
								brandCheck(associatedOrder.parentBrand)
									? `/assets/manufacture_logos/${associatedOrder.parentBrand}.png`
									: "/assets/tandem_logos/cwg_logo.png"
							}
							alt={
								brandCheck(associatedOrder.parentBrand)
									? associatedOrder.parentBrand
									: "CabinetworksGroup Logo"
							}
						/>
					)}

					<AssociatedOrdersData>
						<CardDataStyles className="subtitle2">{PONumberText}</CardDataStyles>
						<span className="body2">
							{checkForAndReplaceNullEmptyWhitespace(associatedOrder?.poNumber)}
						</span>
					</AssociatedOrdersData>

					<AssociatedOrdersData>
						<CardDataStyles className="subtitle2">{OrderNumberText}</CardDataStyles>
						<span className="body2">
							{checkForAndReplaceNullEmptyWhitespace(associatedOrder?.orderNumber)}
						</span>
					</AssociatedOrdersData>

					<AssociatedOrdersData>
						<CardDataStyles className="subtitle2">{DoorStyleText}</CardDataStyles>
						<span className="body2">
							{checkForAndReplaceNullEmptyWhitespace(associatedOrder?.doorStyle)}
						</span>
					</AssociatedOrdersData>

					<AssociatedOrdersData>
						<CardDataStyles className="subtitle2">{FinishText}</CardDataStyles>
						<span className="body2">{checkForAndReplaceNullEmptyWhitespace(associatedOrder?.finish)}</span>
					</AssociatedOrdersData>

					{associatedOrder?.orderDate && (
						<AssociatedOrdersData>
							<CardDataStyles className="subtitle2">{OrderDateText}</CardDataStyles>
							<span className="body2">
								{formatDateToMMDDYYYY(new Date(String(associatedOrder.orderDate)))}
							</span>
						</AssociatedOrdersData>
					)}

					<AssociatedOrdersData>
						<CardDataStyles className="subtitle2">{ETATextOnly}</CardDataStyles>
						<span className="body2">{checkForAndReplaceNullEmptyWhitespace(associatedOrder?.eta)}</span>
					</AssociatedOrdersData>

					{associatedOrder?.truckNumber && (
						<AssociatedOrdersData>
							<CardDataStyles className="subtitle2">{SingleCardTruckText}</CardDataStyles>
							<span className="body2">{associatedOrder.truckNumber}</span>
						</AssociatedOrdersData>
					)}

					{associatedOrder?.status && (
						<SingleCardOrderStatusPillChip
							label={convertToTitleCase(associatedOrder.status)}
							orderStatus={associatedOrder.status}
							sx={{
								width: "fit-content",
								height: "unset",
								marginBottom: !associatedOrder?.statusReason ? "unset !important" : "0.5rem !important"
							}}
						/>
					)}

					{associatedOrder?.statusReason && (
						<SingleCardOrderStatusReasonPillChip
							label={convertToTitleCase(associatedOrder.statusReason)}
							orderStatus={associatedOrder.statusReason}
							sx={{
								width: "fit-content",
								height: "unset",
								marginBottom:
									associatedOrder?.status && associatedOrder?.statusReason
										? "unset !important"
										: "0.5rem !important"
							}}
						/>
					)}
				</div>

				<ArrowContainer>
					<ArrowForward>
						<ArrowForwardIosIcon />
					</ArrowForward>
				</ArrowContainer>
			</CardContainerGrid>
		</CardContainer>
	);
};

export default IndividualLinkedOrderCard;
