import {
	BuildNewOrderLineItemsContainer,
	BuildNewOrderLineItemsFlexbox,
	BuildNewOrderLineItemsHeader,
	BuildNewOrderLineItemsHeaderWrapper,
	NewOrderFlowButtons
} from "components/NewOrders/NewOrders.styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import { Accordion, Button } from "@mui/material";
import {
	NewOrderBuildGridDoorStyleColumnHeader,
	NewOrderBuildGridFinishColumnHeader,
	NewOrderBuildGridQuantityColumnHeader,
	NewOrderBuildOrderLabel,
	NewOrderCancelButton,
	NewOrderDialogSubtitle,
	NewOrderDialogTitle,
	NewOrderEdit,
	NewOrderEmptyBullet1,
	NewOrderEmptyBullet2,
	NewOrderPriceEstimate,
	NewOrderRemoveButton,
	NewOrderRemoveItem,
	NewOrderSubtitle
} from "components/NewOrders/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";
import { LineItemAccordionDetails } from "components/Common/LineItemGrid/LineItemGridStyles";
import { useValidateOrderRequestMutation } from "features/newOrderApi";
import { newOrderActions } from "features/newOrder";
import { BuildLineItemGrid } from "components/NewOrders/BuildNewOrderLineItems/buildNewOrderLineItems.styles";
import Header from "components/Common/LineItemGrid/Header/Header";
import Modification from "components/Common/LineItemGrid/Modification/Modification";
import { EmptyFlyoutHeaderText, LineItemsInOrderText } from "constants/text";
import { ImportOrderViewModel } from "data/api/v1/model/import-order-view-model";
import AccountBillToContent from "../AccountBillToContent";
import { useCheckForBillToAccountErrors } from "../utils/NewOrderUtils";
import NewOrdersSubheader from "../NewOrdersSubheader/NewOrdersSubheader";
import { NewOrderLabelSkeleton } from "../EstimatePageSkeletons/estimateLoadingSkeleton.styles";
import EstimateHeaderLoadingSkeleton from "../EstimatePageSkeletons/EstimateHeaderLoadingSkeleton";
import ErrorBanner from "../../Replacements/ErrorBanner";
import { submittingNewOrderActions } from "features/submittingNewOrder";
import LeaveFlowDialog from "../../Common/LeaveFlowDialog/LeaveFlowDialog";
import { ImportItemViewModel } from "data/api/v1/model/import-item-view-model";
import { ImportConfigurationViewModel } from "data/api/v1";
import { LinkButton } from "../../Common/Link";
import DefaultMessage from "../../Common/DefaultMessage/DefaultMessage";
import { shippingDetailsActions } from "features/shippingDetails";

const BuildNewOrderLineItems = () => {
	const navigateTo = useNavigate();
	const dispatch = useDispatch();
	const [validateOrderRequest, { isLoading }] = useValidateOrderRequestMutation();
	const [lineItemErrors, setLineItemErrors] = useState<(string | undefined | null)[][]>([]);
	const [showErrorBanner, setShowErrorBanner] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const parsedCSV: ImportOrderViewModel | undefined = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const csvConfigurations = useMemo(() => {
		return parsedCSV?.configurations?.map((config) => config ?? []);
	}, [parsedCSV]);
	const [updatedItems, setUpdatedItems] = useState<ImportConfigurationViewModel[] | undefined>(csvConfigurations);
	const selectedRemovedItem = useRef<ImportItemViewModel | undefined>();
	const selectedRemovedConfiguration = useRef<ImportConfigurationViewModel | undefined>();

	const handleGeneratePriceEstimate = async () => {
		dispatch(submittingNewOrderActions.clearDraftError());
		setTimeout(() => window.scrollTo(0, 0), 0);
		const apiInput = updatedItems?.map((config) => {
			const lineItems = config.items.flatMap((lineItem) => {
				const parentLineItem = {
					sku: lineItem.userCode,
					optionCode: null,
					optionValue: null,
					quantity: lineItem.quantity,
					lineNumber: lineItem.lineNumber
				};
				if (lineItem?.modifications && lineItem.modifications?.length > 0) {
					const modLineItems = lineItem.modifications.map((mod) => {
						return {
							sku: mod.userCode,
							optionCode: mod.userCode,
							// NOTE: This will be another field to use for optionvalue but still getting info on how to parse in backend
							optionValue: mod.userCode,
							quantity: mod.quantity,
							lineNumber: mod.lineNumber
						};
					});
					return [parentLineItem, ...modLineItems];
				}
				return parentLineItem;
			});

			return {
				accountId: config.accountNumber?.accountId,
				billTo: config?.billToNumber?.billToId,
				parentBrand: config?.parentBrand,
				productLineCode: config?.productLineCode,
				styleCode: config?.doorStyleCode,
				finishCode: config?.finishCode,
				constructionCode: config?.constructionCode,
				packagingCode: config?.packagingCode,
				speciesCode: config?.speciesCode,
				shapeCode: config?.shapeCode,
				lineItems: lineItems
			};
		});

		try {
			await validateOrderRequest(apiInput)
				.unwrap()
				.then(async (response) => {
					dispatch(newOrderActions.addValidatedOrder(response));
					const foundConfigError = response?.configurations?.find((config) => config.errorMessage);
					let hasError = false;
					const foundLineItemError = response?.configurations?.map((config) =>
						config.lineItems.map((lineItem) => {
							if (lineItem.errorMessage) {
								hasError = true;
							}
							return lineItem.errorMessage;
						})
					);

					if (foundConfigError || hasError) {
						setShowErrorBanner(true);
					}
					setLineItemErrors(foundLineItemError);

					if (!foundConfigError && !hasError) {
						dispatch(newOrderActions.addParsedCSV({ ...parsedCSV, configurations: updatedItems }));
						navigateTo("/new-order/view-price-estimates");
					}
				});
		} catch (error: any) {
			if (error.status === 400) {
				return setShowErrorBanner(true);
			}

			if (error.status === 401) {
				return navigateTo("/unauthorized");
			}

			if (error.status >= 500) {
				return navigateTo("/unavailable");
			}
		}
	};

	const [searchValue, setSearchValue] = useState<string | null>("");
	const [searchOptions, setSearchOptions] = useState<string[]>([]);

	useEffect(() => {
		updatedItems?.forEach((config) => {
			setSearchOptions((prev) =>
				prev.concat(config.items.map((item) => `${item.userCode} - ${item.description}`))
			);
		});
	}, [updatedItems]);

	const handleLineItemSearch = (_event: SyntheticEvent, values: string | null) => {
		const foundSearch = searchOptions.find((option) =>
			option.toUpperCase().includes((values as string).toUpperCase())
		);
		setSearchValue(foundSearch ? values : "");
	};

	const handleOpenRemoveModal = (item: ImportItemViewModel) => {
		setShowDialog(true);
		selectedRemovedItem.current = item;
	};

	const handleRemoveClose = () => {
		selectedRemovedItem.current = undefined;
		setShowDialog(false);
	};

	const handleConfirm = () => {
		getUpdatedItems();
		selectedRemovedItem.current = undefined;
		setShowDialog(false);
	};

	const noLineItems = useMemo(() => {
		return updatedItems?.every((itemsArray) => itemsArray.items.length === 0);
	}, [updatedItems]);

	const generatePrice = useCheckForBillToAccountErrors(handleGeneratePriceEstimate);
	const dialogState = useSelector((state: RootState) => state.newOrder.leaveNewOrderFlow);

	if (dialogState?.state === "pause" && noLineItems) {
		dispatch(newOrderActions.clearNewOrder());
		dispatch(newOrderActions.resetNewOrderFlow());
		dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
		dispatch(shippingDetailsActions.clearShippingDetails());
		navigateTo("/new-order");
	}

	if (noLineItems || noLineItems === undefined) {
		dispatch(newOrderActions.clearNewOrder());
		dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
		dispatch(shippingDetailsActions.clearShippingDetails());
		dispatch(newOrderActions.resetNewOrderFlow());
	}

	const shuffleLineItems = (configurations: ImportConfigurationViewModel[]): ImportConfigurationViewModel[] => {
		let lineItemNumber = 1;
		return configurations.map((config) => ({
			...config,
			items: config.items
				.filter((configItem) => configItem.lineNumber !== selectedRemovedItem?.current?.lineNumber)
				.map((item) => {
					const newModifications = item.modifications?.map((modification, index) => ({
						...modification,
						lineNumber: `${lineItemNumber}.${index + 1}`
					}));
					const newLineItem = {
						...item,
						lineNumber: String(lineItemNumber),
						modifications: newModifications ?? null
					};
					lineItemNumber += 1;
					return newLineItem;
				})
		}));
	};

	const getUpdatedItems = () => {
		setUpdatedItems((prevState) => {
			if (prevState) {
				return shuffleLineItems(prevState)?.filter((configuration) => configuration.items.length > 0);
			}
		});
	};

	const [removeGroup, setRemoveGroup] = useState(false);

	const handleRemoveGroup = (config: ImportConfigurationViewModel) => {
		setRemoveGroup(true);
		selectedRemovedConfiguration.current = config;
	};

	const handleDialogClose = () => {
		setRemoveGroup(false);
		selectedRemovedConfiguration.current = undefined;
	};

	const getUpdatedConfigs = () => {
		const newUpdatedConfigs = updatedItems?.filter(
			(configuration) => configuration !== selectedRemovedConfiguration?.current
		);
		if (newUpdatedConfigs) setUpdatedItems(shuffleLineItems(newUpdatedConfigs));
	};

	const handleGlobalConfirm = () => {
		getUpdatedConfigs();
		setRemoveGroup(false);
		selectedRemovedConfiguration.current = undefined;
	};

	return (
		<BuildNewOrderLineItemsContainer>
			<NewOrderLabelSkeleton isLoading={isLoading} />
			<NewOrdersSubheader
				isLoading={isLoading}
				title={NewOrderBuildOrderLabel}
				dataTestId="new-order-build-label-header"
				hasSearch
				handleSearch={(event, values) => handleLineItemSearch(event, (values as string) ?? "")}
				searchOptions={searchOptions}
				searchValue={searchValue ?? ""}
				autoCompleteId="build-new-order-line-items-search"
			/>
			{showErrorBanner && (
				<ErrorBanner
					isLoading={isLoading}
					newOrderLineItemErrors
				/>
			)}
			{noLineItems || !updatedItems ? (
				<DefaultMessage
					dataTestId="default-message-empty-order"
					asset="/assets/NoOrdersSadBoxImage.svg"
					altMessage="Default message no line items"
					title={EmptyFlyoutHeaderText}
					subtitle={NewOrderSubtitle}
					bullets={[NewOrderEmptyBullet1, NewOrderEmptyBullet2]}
					removeHeight
				/>
			) : undefined}
			<BuildNewOrderLineItemsFlexbox>
				{Array.isArray(updatedItems)
					? updatedItems.map((configuration, configIndex) => {
							const lineItems = configuration.items.map(
								(item) => `${item.userCode} - ${item.description}`
							);
							const foundAccordionSearch = lineItems.find((item) =>
								item.toUpperCase().includes((searchValue as string).toUpperCase())
							);
							if (foundAccordionSearch) {
								return (
									<>
										<LeaveFlowDialog
											dialogState={showDialog}
											firstButtonText={NewOrderCancelButton.toUpperCase()}
											handleOpenDraftModal={handleConfirm}
											handleDialogClose={handleRemoveClose}
											handleDialogExitOrder={handleRemoveClose}
											secondButtonText={NewOrderRemoveItem}
											subtitle={NewOrderDialogSubtitle}
											title={NewOrderDialogTitle}
										/>
										<Accordion
											defaultExpanded
											key={configuration.doorStyleCode}
										>
											<BuildNewOrderLineItemsHeaderWrapper
												isLoading={isLoading}
												expandIcon={<ExpandMoreIcon />}
											>
												<BuildNewOrderLineItemsHeader>
													<div data-testid="new-order-build-label">
														{configuration?.parentBrand}, {configuration.doorStyle},{" "}
														{configuration.finish}
													</div>
													<div data-testid="new-order-build-table-count">
														{configuration?.items.length} {LineItemsInOrderText}
													</div>
												</BuildNewOrderLineItemsHeader>
											</BuildNewOrderLineItemsHeaderWrapper>
											<EstimateHeaderLoadingSkeleton isLoading={isLoading} />
											{!isLoading && (
												<AccountBillToContent
													csvContents={parsedCSV}
													index={configIndex}
													isBuildOrderPage
													removeGroup={removeGroup}
													handleDialogClose={handleDialogClose}
													handleRemoveGroup={() => handleRemoveGroup(configuration)}
													handleGlobalConfirm={handleGlobalConfirm}
													updateButtons
												/>
											)}
											<LineItemAccordionDetails>
												{configuration?.items?.map((item, itemIndex) => {
													const foundSearch = `${item.userCode} - ${item.description}`
														.toUpperCase()
														.includes((searchValue as string).toUpperCase());
													if (foundSearch) {
														return (
															<BuildLineItemGrid
																isLoading={isLoading}
																key={`${item.userCode}-${item.lineNumber}`}
																index={itemIndex}
																error={lineItemErrors[configIndex]?.[itemIndex]}
																mainRowContent={[
																	<Header
																		description={item?.description ?? ""}
																		key={`${item.userCode}-${item.lineNumber}-header`}
																		header={item?.userCode ?? ""}
																		lineNumber={item?.lineNumber ?? ""}
																	/>,
																	<div
																		key={`${item.userCode}-${item.lineNumber}-empty`}
																	/>,
																	<div
																		key={`${item.userCode}-${item.lineNumber}-door-style`}
																	>
																		<div>
																			{NewOrderBuildGridDoorStyleColumnHeader}
																		</div>
																		<div data-testid="line-item-grid-data-door-style">
																			{configuration.doorStyle}
																		</div>
																	</div>,
																	<div
																		key={`${item.userCode}-${item.lineNumber}-finish`}
																	>
																		<div>{NewOrderBuildGridFinishColumnHeader}</div>
																		<div data-testid="line-item-grid-data-finish">
																			{configuration.finish}
																		</div>
																	</div>,
																	<div
																		key={`${item.userCode}-${item.lineNumber}-qty`}
																	>
																		<div>
																			{NewOrderBuildGridQuantityColumnHeader}
																		</div>
																		<div data-testid="line-item-grid-data-quantity">
																			{item.quantity}
																		</div>
																	</div>,
																	// if this isn't inline the styles don't get applied
																	<div
																		style={{
																			flexDirection: "column",
																			gap: "unset"
																		}}
																		key={`${item.userCode}-${item.lineNumber}-remove`}
																	>
																		<LinkButton
																			onClick={() => {}}
																			data-testid="line-item-edit-link"
																		>
																			{NewOrderEdit}
																		</LinkButton>

																		<LinkButton
																			variant="remove"
																			onClick={() => handleOpenRemoveModal(item)}
																			data-testid="line-item-remove-link"
																		>
																			{NewOrderRemoveButton}
																		</LinkButton>
																	</div>
																]}
																modificationRows={item.modifications?.map(
																	(modification: any) => (
																		<Modification
																			description={modification.description}
																			key={modification.userCode}
																			extendedPrice={modification.extendedPrice}
																			lineNumber={modification.lineNumber}
																			listPrice={modification.listPrice}
																			title={modification.userCode}
																		/>
																	)
																)}
															/>
														);
													} else {
														return <></>;
													}
												})}
											</LineItemAccordionDetails>
										</Accordion>
									</>
								);
							} else {
								return <></>;
							}
						})
					: undefined}
			</BuildNewOrderLineItemsFlexbox>
			{noLineItems === false && noLineItems !== undefined && (
				<NewOrderFlowButtons isLoading={isLoading}>
					<Button
						data-testid="new-order-build-continue-button"
						variant="contained"
						onClick={generatePrice}
					>
						{NewOrderPriceEstimate}
					</Button>
				</NewOrderFlowButtons>
			)}
		</BuildNewOrderLineItemsContainer>
	);
};

export default BuildNewOrderLineItems;
