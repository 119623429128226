import { useState } from "react";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import { SaveDraftButton } from "./SaveDraft.styles";
import { SaveDraftText } from "../constants";
import SaveDraftModal from "./SaveDraftModal";
import { CancelButtonText, SaveButtonText } from "constants/text";

const SaveDraft = () => {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpenCloseToggle = () => {
		setIsOpen((prev) => !prev);
	};

	return (
		<>
			<SaveDraftButton
				variant="text"
				onClick={handleOpenCloseToggle}
				data-testid="save-draft-button"
			>
				<SaveOutlined />
				{SaveDraftText}
			</SaveDraftButton>
			<SaveDraftModal
				handleOpenCloseSaveDraftModalToggle={handleOpenCloseToggle}
				saveDraftModalIsOpen={isOpen}
				firstButtonText={SaveButtonText}
				secondButtonText={CancelButtonText}
			/>
		</>
	);
};

export default SaveDraft;
