import {
	AccountBillToSkeleton,
	BuildNewOrderLineItemsHeaderSkeleton,
	EstimateLoadingSkeletonContainer
} from "./estimateLoadingSkeleton.styles";
import { Skeleton } from "@mui/material";

interface EstimateHeaderLoadingSkeletonProps {
	isLoading: boolean;
}
const EstimateHeaderLoadingSkeleton = ({ isLoading }: EstimateHeaderLoadingSkeletonProps) => {
	return (
		<EstimateLoadingSkeletonContainer
			data-testid="estimate-header-skeleton"
			isLoading={isLoading}
		>
			<BuildNewOrderLineItemsHeaderSkeleton>
				<Skeleton
					width={241}
					height={40}
				/>
				<Skeleton
					width={133}
					height={40}
				/>
			</BuildNewOrderLineItemsHeaderSkeleton>
			<AccountBillToSkeleton />
		</EstimateLoadingSkeletonContainer>
	);
};

export default EstimateHeaderLoadingSkeleton;
