import WarningAmber from "@mui/icons-material/WarningAmber";
import { Typography } from "@mui/material";
import { OrderListErrorsWarningMessage } from "constants/text";
import { OrderListErrorWarningWrapper } from "./OrderList.styles";
import useWindowSettings from "hooks/useWindowSettings";

const OrderListErrorsWarning = () => {
	const { isMobile } = useWindowSettings();

	return (
		<OrderListErrorWarningWrapper isMobile={isMobile}>
			<WarningAmber />
			<Typography data-testid="order-list-errors-warning-message">{OrderListErrorsWarningMessage}</Typography>
		</OrderListErrorWarningWrapper>
	);
};

export default OrderListErrorsWarning;
