import { type FC, type FormEventHandler, type PropsWithChildren } from "react";

interface ConditionalFormProps extends PropsWithChildren {
	hasForm: boolean;
	onSubmit: FormEventHandler<HTMLFormElement> | undefined;
}

const ConditionalForm: FC<ConditionalFormProps> = ({ children, hasForm, onSubmit }) => {
	return (
		<>
			{hasForm ? (
				<form
					noValidate
					onSubmit={onSubmit}
				>
					{children}
				</form>
			) : (
				<div>{children}</div>
			)}
		</>
	);
};

export default ConditionalForm;
