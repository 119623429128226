import { configureStore } from "@reduxjs/toolkit";
import windowSettingsReducer from "../features/windowSettings";
import cartReducer from "../features/cart";
import submittingReplacementOrderSlice from "features/submittingReplacementOrder";
import submittedReplacementOrdersSlice from "features/submittedReplacementOrders";
import submittingNewOrderSlice from "features/submittingNewOrder";
import shippingDetailsSlice from "features/shippingDetails";
import newOrderSlice from "features/newOrder";
import { userApiSlice } from "../features/userApi";
import { orderApiSlice } from "../features/orderApi";
import { write } from "../hooks/useSession";
import { imageApiSlice } from "features/imageApi";
import { addressApiSlice } from "features/addressApi";
import { accountApiSlice } from "features/accountApi";
import { newOrderApiSlice } from "features/newOrderApi";
import { designerApiSlice } from "features/designerApi";

export const store = configureStore({
	reducer: {
		windowSettings: windowSettingsReducer,
		cart: cartReducer,
		submittingReplacementOrder: submittingReplacementOrderSlice,
		submittedReplacementOrders: submittedReplacementOrdersSlice,
		newOrder: newOrderSlice,
		submittingNewOrder: submittingNewOrderSlice,
		shippingDetails: shippingDetailsSlice,
		[orderApiSlice.reducerPath]: orderApiSlice.reducer,
		[userApiSlice.reducerPath]: userApiSlice.reducer,
		[imageApiSlice.reducerPath]: imageApiSlice.reducer,
		[addressApiSlice.reducerPath]: addressApiSlice.reducer,
		[newOrderApiSlice.reducerPath]: newOrderApiSlice.reducer,
		[accountApiSlice.reducerPath]: accountApiSlice.reducer,
		[designerApiSlice.reducerPath]: designerApiSlice.reducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(orderApiSlice.middleware)
			.concat(userApiSlice.middleware)
			.concat(imageApiSlice.middleware)
			.concat(addressApiSlice.middleware)
			.concat(accountApiSlice.middleware)
			.concat(newOrderApiSlice.middleware)
			.concat(designerApiSlice.middleware)
});

store.subscribe(() => {
	const {
		cart,
		submittingReplacementOrder,
		submittedReplacementOrders,
		submittingNewOrder,
		newOrder,
		shippingDetails
	} = store.getState();
	write("_cart", cart);
	write("_submittingReplacementOrder", submittingReplacementOrder);
	write("_submittedReplacementOrders", submittedReplacementOrders);
	write("_submittingNewOrder", submittingNewOrder);
	write("_newOrder", newOrder);
	write("_shippingDetails", shippingDetails);
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
