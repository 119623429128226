import styled from "@emotion/styled";

export const ShippingDetailsHeaderWrapper = styled.div({
	width: "100%",
	height: "2rem",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	p: {
		textTransform: "uppercase",
		margin: "0",
		fontSize: "var(--font-size-1)",
		letterSpacing: "1px"
	}
});
