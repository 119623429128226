import styled from "@emotion/styled";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

interface StyledShoppingCardOutlinedIconProps {
	size: string | number;
}

export const ShoppingCartHeaderWrapper = styled.div({
	width: "369px",
	backgroundColor: "var(--white-50)",
	zIndex: 1000,
	paddingBottom: "1rem"
});

export const StyledShoppingCartOutlinedIcon = styled(ShoppingCartOutlinedIcon)<StyledShoppingCardOutlinedIconProps>(
	({ size }) => ({
		width: size,
		height: size,
		color: "var(--gray-800)"
	})
);
