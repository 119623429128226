import { type FC } from "react";
import styles from "../order-status-page-styles.module.css";
import { ErrorButtonWrapper } from "../ErrorModal.styles";
import {
	ReturnToSignInButtonText,
	UnauthorizedErrorHeader,
	UnauthorizedErrorDescription,
	UnauthorizedErrorDisclaimer,
	UnauthorizedErrorCode
} from "constants/text";
import useB2CAccount from "auth/useB2CAccount";

const Unauthorized: FC = () => {
	const { logout } = useB2CAccount();

	return (
		<div
			className={styles.errorContainer}
			data-testid="unauthorized-container"
		>
			<div className={styles.errorContent}>
				<img
					src="/assets/tandem_logos/tandem.png"
					alt="Tandem Logo"
					className={styles.tandemLogo}
					data-testid="unauthorized-tandem-logo"
				/>

				<img
					src="/assets/UnauthorizedSadImage.svg"
					alt="Unauthorized access logo"
					className={styles.noServiceSVG}
					data-testid="unauthorized-logo"
				/>

				<div
					className={styles.errorErrorCode}
					data-testid="unauthorized-error-code"
				>
					{UnauthorizedErrorCode}
				</div>

				<div data-testid="unauthorized-content">
					<h1 data-testid="unauthorized-header">{UnauthorizedErrorHeader}</h1>
					<p
						data-testid="unauthorized-description"
						className={styles.errorText}
					>
						{UnauthorizedErrorDescription}
					</p>
				</div>

				<ErrorButtonWrapper
					variant="contained"
					onClick={logout}
					data-testid="unauthorized-signin-button"
				>
					{ReturnToSignInButtonText}
				</ErrorButtonWrapper>

				<div
					className={styles.errorDisclaimer}
					data-testid="unauthorized-disclaimer"
				>
					{UnauthorizedErrorDisclaimer}
				</div>
			</div>
		</div>
	);
};

export default Unauthorized;
