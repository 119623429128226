import React from "react";
import styles from "../order-status-page-styles.module.css";
import {
	ServiceUnavailableHeader,
	ServiceUnavailableText,
	ReturnToDashboardButtonText,
	AuthErrorErrorCode
} from "constants/text";
import { ErrorButtonWrapper } from "../ErrorModal.styles";
import { salesforceBaseURL } from "constants/endpoints";

const AuthErrorPage = () => {
	return (
		<div
			className={styles.errorContainer}
			data-testid="service-unavailable-container"
		>
			<div className={styles.errorContent}>
				<img
					src="/assets/tandem_logos/tandem.png"
					alt="Tandem Logo"
					className={styles.tandemLogo}
					data-testid="service-unavailable-tandem-logo"
				/>
				<img
					src="/assets/UserAuthServiceError.svg"
					alt="User auth service error logo"
					className={styles.noServiceSVG}
					data-testid="service-unavailable-image"
				/>
				<p className={styles.errorErrorCode}>{AuthErrorErrorCode}</p>
				<h1 data-testid="service-unavailable-header">{ServiceUnavailableHeader}</h1>
				<p
					data-testid="service-unavailable-description"
					className={styles.errorText}
				>
					{ServiceUnavailableText}
				</p>
				<a
					href={salesforceBaseURL}
					style={{ textDecoration: "none" }}
				>
					<ErrorButtonWrapper
						variant="contained"
						data-testid="service-unavailable-signin-button"
					>
						{ReturnToDashboardButtonText}
					</ErrorButtonWrapper>
				</a>
			</div>
		</div>
	);
};

export default AuthErrorPage;
