import { Divider, Grid } from "@mui/material";
import styles from "pages/replacements-page-styles.module.css";
import SelectReplacementReasonAndProblemDropdowns from "./SelectReplacementReasonAndProblemDropdowns";
import { Part, ReplacementCartItem } from "features/cart";
import { SetStateAction } from "react";
import { QuantityText } from "../../constants/text";

interface SelectReplacementReasonProps {
	item: ReplacementCartItem;
	part?: Part;
	reasonSelectionError: boolean;
	setReasonSelectionError: (event: SetStateAction<boolean>) => void;
}

const SelectReplacementReason = ({
	item,
	part,
	reasonSelectionError,
	setReasonSelectionError
}: SelectReplacementReasonProps) => {
	return (
		<Grid container>
			<Grid
				sm={6}
				xs={12}
				container
				justifyContent="space-between"
				className={styles.replaceablePartInformationColumn}
			>
				<Grid
					item
					xs={6}
					data-testid="selectReplacementReason-partSku"
				>
					{part ? (
						<div>
							<span className="subtitle1">{part.partSku}</span>
							<br />
							<span className="subtitle1">{part.description}</span>
						</div>
					) : (
						<div>
							<span className="subtitle1">{item.sku}</span>
							<br />
							<span className="subtitle1">{item.description}</span>
						</div>
					)}
				</Grid>
				<Grid
					item
					data-testid="selectReplacementReason-partQuantityTitle"
				>
					{part ? (
						<div className={styles.selectReplacementReasonQuantity}>
							<span className="subtitle2">{QuantityText}</span>
							<span className="subtitle1">{part.partQuantity}</span>
						</div>
					) : (
						<div className={styles.selectReplacementReasonQuantity}>
							<span className="subtitle2">{QuantityText}</span>
							<span className="subtitle1">{item.wholeCabinetQuantity}</span>
						</div>
					)}
				</Grid>
			</Grid>
			<Grid
				container
				sm={6}
				xs={12}
				justifyContent="center"
				alignItems="center"
				direction="column"
				data-testid={`replacementReason-selectReplacementReasonsDropdownContainer-${part?.partSku ?? item.sku}`}
			>
				{!part && item.wholeCabinetQuantity > 0 && (
					<SelectReplacementReasonAndProblemDropdowns
						item={item}
						reasonSelectionError={reasonSelectionError}
						setReasonSelectionError={setReasonSelectionError}
					/>
				)}
				{part && part.partQuantity > 0 && (
					<SelectReplacementReasonAndProblemDropdowns
						item={item}
						part={part}
						reasonSelectionError={reasonSelectionError}
						setReasonSelectionError={setReasonSelectionError}
					/>
				)}
			</Grid>
			<Grid
				item
				xs={12}
			>
				<Divider />
			</Grid>
		</Grid>
	);
};
export default SelectReplacementReason;
