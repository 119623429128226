import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const SaveDraftButton = styled(Button)({
	display: "flex",
	alignItems: "center",
	gap: "8px",
	fontWeight: 600,
	margin: 0,
	verticalAlign: "middle",
	letterSpacing: ".4px",
	lineHeight: "24px",
	textTransform: "uppercase"
});

export const SaveDraftModalHeader = styled.div(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	padding: "16px 24px",
	"& .MuiSvgIcon-root": {
		cursor: "pointer",
		[theme.breakpoints.down("md")]: {
			position: "absolute",
			top: 20,
			right: 28
		}
	},
	div: {
		display: "flex",
		flexDirection: "column",
		h6: {
			margin: 0,
			color: theme.palette.text.secondary
		},
		p: {
			margin: 0,
			fontSize: 14,
			letterSpacing: ".17px",
			lineHeight: "20px"
		}
	}
}));

export const SaveDraftModalBody = styled.div<{ isError: boolean }>(({ theme, isError }) => ({
	padding: "0 24px 24px 24px",
	p: {
		marginTop: 0,
		fontSize: 14,
		letterSpacing: ".17px",
		lineHeight: "20px"
	},
	"& .MuiTextField-root": {
		width: "100%",
		"& label:not(.MuiInputLabel-shrink)": {
			top: "-7px"
		},
		label: {
			color: isError ? theme.project.colors.semanticColors.error + "!important" : "auto"
		},
		div: {
			input: {
				"&::placeholder": {
					color: theme.project.colors.fontColors.secondary,
					opacity: 1
				},
				lineHeight: 24,
				height: 40,
				padding: 0
			},
			fieldset: {
				borderColor: isError ? theme.project.colors.semanticColors.error + "!important" : "auto",
				borderWidth: "1px !important"
			},
			padding: "0 12px"
		}
	},
	span: {
		color: isError ? theme.project.colors.semanticColors.error : "auto"
	}
}));

export const SaveDraftModalButtons = styled.div(({ theme }) => ({
	display: "flex",
	gap: 16,
	marginTop: 16,
	button: {
		width: "100%",
		height: 42,
		fontSize: 15
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column-reverse"
	}
}));

export const IsLoadingBody = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	h6: {
		textAlign: "center",
		margin: "23px 0 47px",
		[theme.breakpoints.down("md")]: {
			margin: "12px 0 36px"
		}
	},
	div: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		gap: 0,
		span: {
			lineHeight: "24px"
		},
		"span:last-of-type": {
			span: {
				backgroundColor: theme.project.colors.primaryColors.main
			}
		},
		div: {
			color: theme.project.colors.primaryColors.main,
			flexDirection: "row",
			gap: "8px",
			marginTop: "5px",
			span: {
				fontWeight: 500,
				fontSize: 14
			},
			svg: {
				width: 22
			}
		}
	}
}));
