import styled from "@emotion/styled";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";

export const StyledStepper = styled(Stepper)(({ theme }) => ({
	width: "100%",
	[theme.breakpoints.up("md")]: {
		maxWidth: 640
	}
}));

export const StyledStep = styled(Step)(({ theme }) => ({
	"& .MuiStepLabel-root .Mui-completed": {
		color: theme.project.colors.primaryColors.main
	},
	"& .MuiStepLabel-root .Mui-active": {
		color: theme.project.colors.primaryColors.main
	},
	"& .MuiStepLabel-labelContainer .Mui-completed": {
		color: theme.project.colors.fontColors.main,
		fontWeight: 400
	},
	"& .MuiStepLabel-labelContainer .Mui-active": {
		color: theme.project.colors.fontColors.main
	},
	"& .Mui-disabled > svg": {
		fill: theme.palette.grey[50],
		border: `1px solid ${theme.project.colors.primaryColors.main}`,
		borderRadius: "100%",
		".MuiStepIcon-text": {
			fill: theme.project.colors.primaryColors.main
		}
	},

	[theme.breakpoints.down("md")]: {
		"& .MuiStepLabel-labelContainer .Mui-completed": {
			display: "none"
		},
		"& .MuiStepLabel-labelContainer .Mui-disabled": {
			display: "none"
		}
	}
}));
