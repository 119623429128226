import { Drawer, Grid, Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { MouseEventHandler, useEffect, useRef, useState, KeyboardEvent, MouseEvent } from "react";
import styles from "pages/order-details-items-styles.module.css";
import {
	DoorStyleText,
	ExtendedPriceText,
	FinishText,
	ItemAffectedBackorderText,
	ModificationsText,
	QuantityText,
	UnavailableDataPlaceholderText,
	PartsAddedText,
	OrderReplacementText
} from "constants/text";
import { LineItemModificationViewModel, MeasurementsViewModel } from "data/api/v1";
import OrderDetailsLineItemFlyout, { OrderDetailProps } from "./OrderDetailsLineItemFlyout";
import useWindowSettings from "hooks/useWindowSettings";
import { ReplaceablePartsViewModel } from "data/api/v1/model/replaceable-parts-view-model";
import { toFormattedPrice } from "utils/string";
import { useDispatch } from "react-redux";
import { cartActions } from "features/cart";
import FlyoutHandler from "components/Replacements/FlyoutHandler";
import { ReplacementControls, StyledCheckCircleOutlineIcon } from "./OrderDetailsLineItem.styles";
import { SelectPartsOrderDetailsWrapper } from "components/Replacements/ReplacementHeaderText.styles";

enum LineItemVariant {
	Standard = "standard",
	Replacement = "replacement"
}
export interface LineItemGridProps {
	id?: string | null;
	description?: string | null;
	doorStyle?: string | null;
	finish?: string | null;
	quantityOrdered?: number | null;
	modifications?: Array<LineItemModificationViewModel> | null;
	extendedPrice?: number | null | string;
	partsQuantityBackordered?: number | null;
	partInfoPartCount?: number | null;
	measurements?: MeasurementsViewModel | null;
	logo: any;
	listPrice: number | undefined;
	sku?: string | null;
	quantityBackordered?: number | null;
	arrivalDate?: Date | null;
	orderType?: string | null;
	construction?: string | null;
	hinge?: string | null;
	parts?: any;
	lineItemNumber?: number | null;
	itemNumber?: string | null;
	isReplacementEligible?: boolean | null;
	variant?: LineItemVariant | string | null;
	replaceableParts?: Array<ReplaceablePartsViewModel> | null;
	isPartAdded?: boolean;
	lineItemId?: string | null;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const StandardLineItems = (props: LineItemGridProps) => (
	<>
		<Grid
			paddingRight=".75rem"
			item
			md={1.5}
			xs={12}
		>
			<p
				className="subtitle2"
				style={{ marginBottom: "unset" }}
			>
				{DoorStyleText}
			</p>
			<span className="body1">{props.doorStyle ?? UnavailableDataPlaceholderText}</span>
		</Grid>
		<Grid
			paddingRight=".75rem"
			item
			md={1.5}
			xs={12}
		>
			<p
				className="subtitle2"
				style={{ marginBottom: "unset" }}
			>
				{FinishText}
			</p>
			<span className="body1">{props.finish ?? UnavailableDataPlaceholderText}</span>
		</Grid>
		<Grid
			paddingRight=".75rem"
			item
			xl={1.3}
			md={1.5}
			xs={12}
		>
			<p
				className="subtitle2"
				style={{ marginBottom: "unset" }}
			>
				{ModificationsText}
			</p>
			<span
				className="body1"
				style={{ marginBottom: "unset" }}
			>
				{props.modifications?.length ? "Yes" : "No"}
			</span>
		</Grid>
		<Grid
			paddingRight=".75rem"
			item
			lg={1}
			md={1.3}
			xs={12}
		>
			<p
				className="subtitle2"
				style={{ marginBottom: "unset" }}
			>
				{QuantityText}
			</p>
			<span className="body1">{props.quantityOrdered ?? UnavailableDataPlaceholderText}</span>
		</Grid>
		<Grid
			item
			xl={1.5}
			lg={1.3}
			md={1.5}
			xs={12}
		>
			{props.orderType !== "BACKORDER" && (
				<>
					<p
						className="subtitle2"
						style={{ marginBottom: "unset" }}
					>
						{ExtendedPriceText}
					</p>
					<span className="body1">{toFormattedPrice(props.extendedPrice)}</span>
				</>
			)}
		</Grid>
		<Grid
			className={styles.lineItemsGridArrowForwardIcon}
			item
			md={1}
			xl={0.25}
			lg={0.75}
		>
			<ArrowForwardIosIcon />
		</Grid>
	</>
);

const ReplacementLineItems = (props: LineItemGridProps) => (
	<>
		<Grid
			paddingRight=".75rem"
			item
			md={1.5}
			xs={12}
		>
			<p
				className="subtitle2"
				style={{ marginBottom: "unset" }}
			>
				{DoorStyleText}
			</p>
			<span className="body1">{props.doorStyle ?? UnavailableDataPlaceholderText}</span>
		</Grid>
		<Grid
			paddingRight=".75rem"
			item
			md={1.5}
			xs={12}
		>
			<p
				className="subtitle2"
				style={{ marginBottom: "unset" }}
			>
				{FinishText}
			</p>
			<span className="body1">{props.finish ?? UnavailableDataPlaceholderText}</span>
		</Grid>
		<Grid
			paddingRight=".75rem"
			item
			xl={1.3}
			md={1.5}
			xs={12}
		>
			<p
				className="subtitle2"
				style={{ marginBottom: "unset" }}
			>
				{ModificationsText}
			</p>
			<span
				className="body1"
				style={{ marginBottom: "unset" }}
			>
				{props.modifications?.length ? "Yes" : "No"}
			</span>
		</Grid>
		<Grid
			paddingRight=".75rem"
			item
			lg={1}
			md={1.3}
			xs={12}
		>
			<p
				className="subtitle2"
				style={{ marginBottom: "unset" }}
			>
				{QuantityText}
			</p>
			<span
				className="body1"
				style={{ marginBottom: "unset" }}
			>
				{props.quantityOrdered ?? UnavailableDataPlaceholderText}
			</span>
		</Grid>
		<Grid
			item
			xs
		>
			<ReplacementControls>
				{props?.isReplacementEligible && (
					<Button
						onClick={props.onClick}
						variant="outlined"
						data-testid="line-item-order-replacement-button"
					>
						{OrderReplacementText}
					</Button>
				)}
				{props?.isPartAdded && (
					<div>
						<StyledCheckCircleOutlineIcon size={20} />
						<p>{PartsAddedText}</p>
					</div>
				)}
			</ReplacementControls>
		</Grid>
	</>
);

const OrderDetailsLineItemGrid = (props: LineItemGridProps) => {
	const [showDrawer, setShowDrawer] = useState(false);
	const { isMobile } = useWindowSettings();
	const skuDescRef = useRef<HTMLSpanElement>(null);
	const [skuDescHeight, setSkuDescHeight] = useState<number | undefined>(undefined);
	const dispatch = useDispatch();

	const [showReplacement, setShowReplacement] = useState(false);
	const [showShoppingCart, setShowShoppingCart] = useState(false);

	const toggleReplacement = () => setShowReplacement(true);

	useEffect(() => {
		if (showReplacement) {
			setShowReplacement(false);
		}
		if (showShoppingCart) {
			setShowShoppingCart(false);
		}
	}, [showReplacement, showShoppingCart]);

	const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
		if (
			event.type === "keydown" &&
			((event as KeyboardEvent).key === "Tab" || (event as KeyboardEvent).key === "Shift")
		) {
			return;
		}

		setShowDrawer(open);
		dispatch(cartActions.cancelEdit());
	};

	const handleOnClick = (id: string) => {
		dispatch(cartActions.startEdit(id));
		toggleReplacement();
	};

	const updateHeight = () => {
		if (skuDescRef.current) setSkuDescHeight(skuDescRef.current.offsetHeight);
	};

	useEffect(() => {
		window.addEventListener("resize", updateHeight);
		updateHeight();

		return () => window.removeEventListener("resize", updateHeight);
	}, []);

	const rootStyle =
		props.variant === LineItemVariant.Replacement
			? styles.orderDetailPlacement
			: styles.orderDetailsLineItemContainer;

	return (
		<div
			data-testid="order-details-line-item"
			id="order-details-line-item"
		>
			{/* role is for GA targeting */}
			<Grid
				onClick={props.variant !== LineItemVariant.Replacement ? toggleDrawer(true) : (undefined as any)}
				container
				className={rootStyle}
				sx={{ width: "inherit" }}
				data-id="order-details-line-item"
				role="button"
			>
				{(props.partsQuantityBackordered || props.quantityBackordered !== 0) &&
					props.orderType !== "BACKORDER" && (
						<Grid container>
							<Grid
								sx={{
									display: "flex",
									alignItems: "center"
								}}
							>
								<img
									src="/assets/BackorderIcon.svg"
									alt="Order Details Backorder Logo"
									data-testid="order-detail-line-items-backorder-logo"
									style={{
										display:
											!props.partsQuantityBackordered &&
											props.quantityBackordered === 0 &&
											isMobile
												? "none"
												: "",
										width: "20px"
									}}
								/>
								<p
									className="subtitle2"
									data-testid="order-detail-line-items-backorder-text"
									style={{ color: "var(--orange-500)", margin: "2px 0 0 .5rem" }}
								>
									{ItemAffectedBackorderText}
								</p>
							</Grid>
						</Grid>
					)}
				<SelectPartsOrderDetailsWrapper>
					{props.lineItemNumber}
					{
						// below empty span is always going to be the same height as the sku's description span. this is to make sure that the lineItemNumber text is always aligned with the sku text
					}
					<span
						className="body1"
						style={{ height: skuDescHeight }}
					></span>
				</SelectPartsOrderDetailsWrapper>
				<Grid
					paddingRight=".75rem"
					item
					sx={{ display: "grid", alignItems: "center" }}
					xl={4.65}
					lg={3.95}
					md={3.2}
					sm={10}
					xs={9}
				>
					<h6 style={{ margin: 0, color: "var(--text-primary)", paddingTop: "5px" }}>{props.sku}</h6>
					<span
						ref={skuDescRef}
						className="body1"
						style={{ paddingTop: "5px" }}
					>
						{props.description}
					</span>
				</Grid>
				{
					{
						[props.variant as string]: <StandardLineItems {...props} />,
						[LineItemVariant.Replacement]: (
							<ReplacementLineItems
								onClick={() => handleOnClick(props.id!)}
								{...props}
							/>
						)
					}[props.variant as string]
				}
			</Grid>

			{props.variant !== LineItemVariant.Replacement ? (
				<Drawer
					anchor="right"
					open={showDrawer}
					onClose={toggleDrawer(false)}
				>
					<OrderDetailsLineItemFlyout
						{...(props as OrderDetailProps)}
						closeDrawer={toggleDrawer(false)}
					/>
				</Drawer>
			) : (
				<FlyoutHandler
					showButtons
					lineItemGridProps={props}
					showReplacementTrigger={showReplacement}
				/>
			)}
		</div>
	);
};

export default OrderDetailsLineItemGrid;
