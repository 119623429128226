import { useState, useEffect } from "react";
import getOrdersModuleAssetUrl from "../mocks/getOrdersModuleAssetUrl";
import { getFileNameBasedOnCurrentDateTime } from "../utils/file";

const useMyOrdersPDFGenerator = (
	orders: any[],
	searchFilters: any,
	searchDates: any,
	totalStatuses: string[],
	currentSearch: string
) => {
	const [pdfData, setPdfData] = useState<PDFData>({ blob: null, loading: false, error: null });

	const generateMyOrdersPDF = () => {
		const worker = getOrdersModuleAssetUrl();

		worker.postMessage({
			orders,
			searchFilters,
			searchDates,
			totalStatuses,
			currentSearch
		});

		worker.onmessage = (e) => {
			if (e.data.blob) {
				setPdfData({ blob: e.data.blob, loading: false, error: null });
			} else if (e.data.error) {
				setPdfData((prevData) => ({ ...prevData, loading: false, error: e.data.error }));
			}
		};

		worker.onerror = (error) => {
			setPdfData((prevData) => ({
				...prevData,
				loading: false,
				error: error instanceof Error ? error : new Error("An unknown error occurred")
			}));
		};

		setPdfData((prevData) => ({ ...prevData, loading: true }));
	};

	useEffect(() => {
		if (pdfData.blob) {
			const url = window.URL.createObjectURL(pdfData.blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${getFileNameBasedOnCurrentDateTime("MyOrders")}.pdf`);
			document.body.appendChild(link);
			link.click();
			setPdfData({ blob: null, loading: false, error: null });
		}
	}, [pdfData.blob]);

	return { pdfData, generateMyOrdersPDF };
};

interface PDFData {
	blob: Blob | null;
	loading: boolean;
	error: Error | null;
}

export default useMyOrdersPDFGenerator;
