import { Skeleton } from "@mui/material";
import { LineItemLeftSkeletons, LineItemRightSkeletons, LineItemSkeletonContainer } from "./LineItemGridStyles";

interface LoadingSkeletonProps {
	isLoading?: boolean;
}
const LineItemSkeleton = ({ isLoading }: LoadingSkeletonProps) => {
	return (
		<LineItemSkeletonContainer
			data-testid="line-item-skeleton"
			isLoading={isLoading}
		>
			<LineItemLeftSkeletons>
				<Skeleton height={24} />
				<Skeleton height={12} />
			</LineItemLeftSkeletons>
			<LineItemRightSkeletons>
				<Skeleton />
				<Skeleton />
			</LineItemRightSkeletons>
			<LineItemRightSkeletons>
				<Skeleton />
				<Skeleton />
			</LineItemRightSkeletons>
			<LineItemRightSkeletons>
				<Skeleton />
				<Skeleton />
			</LineItemRightSkeletons>
		</LineItemSkeletonContainer>
	);
};

export default LineItemSkeleton;
