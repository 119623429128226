import { checkboxClasses } from "@mui/material/Checkbox";
import { typographyClasses } from "@mui/material/Typography";
import styled from "@emotion/styled";

interface DatePickerWrapperProps {
	widthValue: string;
}

export const DatePickerWrapper = styled.section<DatePickerWrapperProps>(({ widthValue }) => ({
	fontWeight: 300,
	width: widthValue,
	position: "relative",
	[`.${checkboxClasses.checked}`]: {
		color: "var(--cwg-blue-900)"
	},
	[`.${typographyClasses.body1}`]: {
		fontFamily: ["Gibson", "sans-serif"].join(","),
		fontWeight: 400
	},
	[`.${typographyClasses.subtitle1}`]: {
		color: "var(--text-secondary)"
	}
}));
