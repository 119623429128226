import styled from "@emotion/styled";
import { InputLabel } from "@mui/material";

export const ReplacementReasonAndProblemInputLabel = styled(InputLabel)({
	fontFamily: "Gibson Regular, sans-serif",
	color: "var(--text-secondary)"
});

export const ErrorTextContainer = styled.div({
	alignItems: "center",
	display: "flex",
	gap: 4,
	marginTop: 4,
	p: {
		fontSize: "0.875rem",
		fontWeight: 500,
		lineHeight: "143%",
		margin: 0
	}
});
