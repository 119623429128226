import { type FC } from "react";
import { Wrapper } from "components/Common/LineItemGrid/Modification/modification.styles";

interface ModificationProps {
	description: string | undefined;
	extendedPrice: number | undefined;
	lineNumber: string | undefined;
	listPrice: number | undefined;
	title: string | undefined;
}

const Modification: FC<ModificationProps> = (props) => {
	const { description, extendedPrice, lineNumber, listPrice, title } = props;
	const rowCount = typeof extendedPrice !== "undefined" && typeof listPrice !== "undefined" ? 2 : 1;

	return (
		<Wrapper rowCount={rowCount}>
			<div>
				<div data-testid="line-item-grid-modifications-line-number">{lineNumber}</div>
				<div>
					<div data-testid="line-item-grid-modifications-line-title">{title}</div>
					<div data-testid="line-item-grid-modifications-line-description">{description}</div>
				</div>
			</div>
			<div>
				<div data-testid="line-item-grid-modifications-line-list-price">
					{listPrice?.toLocaleString("en-US", {
						style: "currency",
						currency: "USD"
					})}
				</div>
				<div data-testid="line-item-grid-modifications-line-extended-price">
					{extendedPrice?.toLocaleString("en-US", {
						style: "currency",
						currency: "USD"
					})}
				</div>
			</div>
		</Wrapper>
	);
};

export default Modification;
