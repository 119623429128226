import { type FieldError, type FieldValues, type FormState, type Path, type UseFormRegister } from "react-hook-form";
import { type AnyObject, type ObjectSchema } from "yup";
import { ReactNode } from "react";
import HelperText from "components/Form/HelperText/HelperText";
import { CharacterLimitExceeded } from "constants/text";

export interface CharacterLimit {
	currentLength: number;
	limit: number;
}

interface FormFieldProps<T extends FieldValues> {
	formState: FormState<T>;
	name: Path<T>;
	register?: UseFormRegister<T>;
	error?: string;
	id?: string;
	required?: boolean;
	schema?: ObjectSchema<T>;
	helperText?: string | ReactNode | null;
	characterLimit?: CharacterLimit;
}

// Returns the initial value of whether a field is required based on a Yup Schema definition.
// If a field can change whether it is required, the required field should be provided to getFormFieldProps.
// If not using Yup, this function may not be required or would need to be rewritten.
const getIsFieldRequired = <T extends AnyObject>(schema: ObjectSchema<T>, name: Path<T>) => {
	type SchemaSpec = { spec: Record<string, boolean> };
	type SchemaType = {
		fields: AnyObject;
	} & SchemaSpec &
		ObjectSchema<T>;

	const isOptional =
		name.split(".").reduce((accum, current: string) => {
			if (accum && accum.fields && accum.fields[current]) {
				return accum.fields[current];
			}

			return accum;
		}, schema as SchemaType)?.spec?.optional ?? true;

	return !isOptional;
};

const getFieldErrorMessage = <T extends FieldValues>(formState: FormState<T>, name: Path<T>): string | undefined => {
	const { errors } = formState;

	// For each accessor split from the form notation, drill down a level until the end to reach the errors object
	return name.split(".").reduce(
		(accum, current: string) => {
			if (accum && accum[current]) {
				return accum[current];
			}

			return accum;
			// the errors type is a bit ambiguous but can be either any Field Value or a FieldError object (which is what we want to return)
		},
		errors as unknown extends FieldError ? FieldError : T
	)?.message;
};

export const getFormFieldProps = <T extends FieldValues>(props: FormFieldProps<T>) => {
	const { characterLimit, helperText, error, formState, id, name, register, required, schema } = props;
	const errorMessage = getFieldErrorMessage(formState, name);
	const registeredFields = register ? register(name) : { name };
	const isRequired = required ?? (schema ? getIsFieldRequired(schema, name) : false);
	const isCharacterLimitError = characterLimit && characterLimit.currentLength > characterLimit.limit;
	const message = errorMessage ?? helperText;
	const helperLimitConfig = characterLimit ? { ...characterLimit, isLimitError: isCharacterLimitError } : undefined;
	const renderedHelperText = (
		<HelperText
			isError={isCharacterLimitError || Boolean(errorMessage)}
			text={isCharacterLimitError ? CharacterLimitExceeded : message}
			characterLimit={helperLimitConfig}
		/>
	);

	return {
		...registeredFields,
		id: id ?? name,
		error: isCharacterLimitError || Boolean(error ?? typeof errorMessage !== "undefined"),
		...((characterLimit || message) && { helperText: renderedHelperText }),
		required: isRequired
	};
};
