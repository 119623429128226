// Added based current swagger as of 8.18.2023

import { isTestEnv } from "constants/environment";

export const baseURL = isTestEnv ? new URL("", window.location.origin).href : `${import.meta.env.VITE_APP_BASEURL}`;

const endpoints = {
	addresses: {
		getSuggestions: (address = ":address") => {
			return `${baseURL}/api/v1/addresses/suggestions/${address}`;
		}
	},
	accounts: {
		getAccounts: (productLineCode?: string | null) => {
			return `${baseURL}/api/v1/accounts?productLineCode=${productLineCode}`;
		},
		getBillToShipTos: (accountId: string, billToId: string) => {
			return `${baseURL}/api/v1/accounts/${accountId}/bill-tos/${billToId}/ship-tos`;
		},
		getBillToInfo: (accountId: string, billToId: string) => {
			return `${baseURL}/api/v1/accounts/${accountId}/bill-tos/${billToId}`;
		},
		getAccountsByOrderId: (orderId: string) => {
			return `${baseURL}/api/v1/accounts?orderId=${orderId}`;
		}
	},
	designers: {
		getDesigners: (accountId: string) => {
			return `${baseURL}/api/v1/accounts/${accountId}/designers`;
		}
	},
	images: {
		getUploadDataById: (tandemOrderId: string) => {
			return `${baseURL}/api/v1/images/${tandemOrderId}/upload`;
		}
	},
	orders: {
		getAllOrders: () => {
			return `${baseURL}/api/v1/orders`;
		},
		getOrdersById: (orderId: string) => {
			return `${baseURL}/api/v2/orders/${orderId}`;
		},
		getAccountsById: (orderId: string) => {
			return `${baseURL}/api/v1/orders/${orderId}/accounts`;
		},
		postReplacementOrder: () => {
			return `${baseURL}/api/v1/orders/replacements`;
		},
		getLinkedOrders: (orderId: string) => {
			return `${baseURL}/api/v1/orders/${orderId}/linked-orders`;
		},
		draftOrders: () => {
			return `${baseURL}/api/v1/orders/drafts`;
		},
		deleteDraft: (draftId: string) => {
			return `${baseURL}/api/v1/orders/drafts/${draftId}`;
		}
	},
	users: {
		getUserDetails: () => {
			return `${baseURL}/api/v1/users`;
		}
	},
	version: {
		getAPIVersion: () => {
			return `${baseURL}/api/version`;
		}
	},
	health: {
		getAPIHealth: () => {
			return `${baseURL}/health`;
		}
	},
	newOrder: {
		csvUpload: () => {
			return `${baseURL}/api/v1/orders/import`;
		},
		postNewOrder: () => `${baseURL}/api/v1/orders`,
		validateOrderRequest: () => {
			return `${baseURL}/api/v1/products/validate`;
		},
		getBillToShipTosNewOrders: () => {
			return `${baseURL}/api/v1/accounts/bill-tos/ship-tos`;
		}
	}
};

export default endpoints;
