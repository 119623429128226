import { createSlice } from "@reduxjs/toolkit";
import { read } from "hooks/useSession";

const submittedReplacementOrders = read("_submittedReplacementOrders");

const initialState = submittedReplacementOrders || {
	orders: [] as Array<string>
};

const submittedReplacementOrdersSlice = createSlice({
	name: "submittedReplacementOrders",
	initialState,
	reducers: {
		updateOrders: (state, action) => {
			state.orders = [...state.orders, action.payload];
		}
	}
});

export const submittedReplacementOrdersActions = submittedReplacementOrdersSlice.actions;
export default submittedReplacementOrdersSlice.reducer;
