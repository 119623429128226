import { type FC } from "react";
import {
	Button,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography
} from "@mui/material";
import {
	minCharCountText,
	myOrdersText,
	searchBarHelperText,
	searchHeaderButtonText,
	searchOrdersText
} from "constants/text";
import CloseIcon from "@mui/icons-material/Close";
import { OrderSearchCommonProps } from "./OrderSearch";
import { OrderSearchDesktopInputLine, OrderSearchTextField } from "./OrderSearchDesktop.styles";
import { ExactMatchText, PartialMatchText, ShowMeText } from "../NewOrders/constants";

const OrderSearchDesktop: FC<OrderSearchCommonProps> = ({
	children: datePicker,
	hasSearchValidationError,
	searchValue,
	handleSearchFieldOnChange,
	handleSearchFieldOnKeyDown,
	handleOnSearch,
	handlePartialSearch,
	partialMatch,
	isCareRole,
	resetSearch
}: OrderSearchCommonProps) => {
	const handleChange = (event: SelectChangeEvent) => {
		handlePartialSearch(event);
	};

	return (
		<Grid
			container
			spacing={0.5}
		>
			<Grid
				item
				sm={12}
			>
				<h5 style={{ marginTop: "1rem" }}>{myOrdersText}</h5>
			</Grid>
			<OrderSearchDesktopInputLine isCareRole={isCareRole}>
				<OrderSearchTextField
					size="small"
					required
					error={hasSearchValidationError}
					label={searchOrdersText}
					helperText={hasSearchValidationError && minCharCountText}
					data-testid="search-header-input-field"
					value={searchValue}
					onChange={handleSearchFieldOnChange}
					onKeyDown={handleSearchFieldOnKeyDown}
					InputProps={{
						endAdornment: (
							<InputAdornment
								position="start"
								onClick={() => {
									resetSearch();
								}}
							>
								{searchValue !== undefined && searchValue.length >= 3 && <CloseIcon cursor="pointer" />}
							</InputAdornment>
						)
					}}
					InputLabelProps={{
						required: false
					}}
				/>

				{isCareRole && (
					<FormControl size="small">
						<InputLabel>{ShowMeText}</InputLabel>
						<Select
							value={partialMatch}
							label={ShowMeText}
							onChange={handleChange}
							data-TestId="showme-search-dropdown"
						>
							<MenuItem value="false">{ExactMatchText}</MenuItem>
							<MenuItem value="true">{PartialMatchText}</MenuItem>
						</Select>
					</FormControl>
				)}

				{datePicker}
				<Button
					variant="contained"
					data-testid="search-header-button"
					data-id="search-header-button"
					onClick={handleOnSearch}
					aria-label="Search Orders"
					size="small"
				>
					{searchHeaderButtonText}
				</Button>
			</OrderSearchDesktopInputLine>
			<Grid
				item
				xs={12}
				style={{ marginTop: "1rem" }}
			>
				<Typography
					style={{
						fontWeight: 400,
						fontSize: ".75rem",
						color: "var(--text-secondary)",
						letterSpacing: 0.5,
						marginTop: hasSearchValidationError ? -5 : -20
					}}
				>
					{searchBarHelperText}
				</Typography>
			</Grid>
		</Grid>
	);
};

export default OrderSearchDesktop;
