import { type SyntheticEvent, useEffect, useState } from "react";
import { DialogTitle, FooterActions } from "components/Dialog";
import {
	AutocompleteOption,
	BottomSection,
	Content,
	Divider,
	FieldGroup,
	RequiredFieldsText,
	SplitRow,
	StyledDialog
} from "pages/Replacements/components/AddNewAddressModal/addNewAddressModal.styles";
import { useFormContext } from "react-hook-form";
import { AddressFormFields, FULL_NAME_MAX_LENGTH, schema } from "pages/Replacements/forms/AddressForm/schema";
import TextField from "@mui/material/TextField";
import { getFormFieldProps } from "utils/form";
import { themeMap } from "styles/themes/baseTheme";
import { ThemeProvider } from "@emotion/react";
import {
	AddNewAddressTitle,
	Address2Label,
	AddressHelperText,
	AddressLabel,
	CityLabel,
	ContactInformationNotice,
	ContactInformationTitle,
	CountyLabel,
	EmailLabel,
	FullNameHelperText,
	FullNameLabel,
	PhoneLabel,
	RequiredFieldNotice,
	StateLabel,
	ZipLabel
} from "constants/text";
import Autocomplete from "@mui/material/Autocomplete";
import ListboxComponent from "pages/Replacements/components/AddNewAddressModal/components/ListboxComponent";
import { addressApiSlice, useLazyGetAddressSuggestionsQuery } from "features/addressApi";
import useDebounce from "hooks/useDebounce";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { states } from "constants/states";
import { AddressSuggestionViewModel } from "data/api/v1/model/address-suggestion-view-model";
import MaskedPhoneTextField from "pages/Replacements/components/AddNewAddressModal/components/MaskedPhoneTextField";
import HelperText from "components/Form/HelperText/HelperText";
import { useDispatch } from "react-redux";

interface AddNewAddressModalProps {
	handleClose: () => void;
	onSubmit: (address: AddressFormFields) => void;
	open: boolean;
}

const AddNewAddressModal = ({ handleClose, onSubmit, open }: AddNewAddressModalProps) => {
	const dispatch = useDispatch();
	const [clearKey, setClearKey] = useState(new Date().getTime());
	const { formState, handleSubmit, register, reset, setValue, watch } = useFormContext<AddressFormFields>();
	const formFieldData = { formState, register, schema };
	const stateValue = watch("state") ?? "";

	const [trigger, { data: addressSuggestions }] = useLazyGetAddressSuggestionsQuery();
	const fullNameInput = watch("fullName") || "";
	const addressInput = watch("address");
	const debouncedAddressInput = useDebounce(addressInput, 300);
	const options = addressSuggestions?.slice(0, 15) ?? [];

	useEffect(() => {
		if (debouncedAddressInput && debouncedAddressInput.length > 4) {
			trigger(debouncedAddressInput);
		}
		if (!debouncedAddressInput) {
			dispatch(addressApiSlice.util.resetApiState());
		}
	}, [debouncedAddressInput, dispatch, trigger]);

	const handleAddressSelect = (_event: SyntheticEvent, value: AddressSuggestionViewModel | string | null) => {
		if (value && typeof value !== "string") {
			value.line2 && setValue("address2", value.line2);
			value.city && setValue("city", value.city, { shouldValidate: true });
			value.state && setValue("state", value.state, { shouldValidate: true });
			value.zip && setValue("zip", value.zip, { shouldValidate: true });
			value.county && setValue("county", value.county, { shouldValidate: true });
		}
	};

	const closeAndClear = () => {
		reset();
		setClearKey(new Date().getTime());
		handleClose();
	};

	const handleClearIndicatorClick = () => {
		setClearKey(new Date().getTime());
		dispatch(addressApiSlice.util.resetApiState());
	};

	const handleFormSubmit = (values: AddressFormFields) => {
		onSubmit(values);
		closeAndClear();
	};

	return (
		<ThemeProvider theme={themeMap.defaultTheme}>
			{/** Temporary ThemeProvider until it can fit properly above the whole app **/}
			<StyledDialog
				onClose={closeAndClear}
				open={open}
			>
				<form
					onSubmit={(event) => {
						event.stopPropagation();
						handleSubmit(handleFormSubmit)(event);
					}}
					noValidate
				>
					<DialogTitle
						title={AddNewAddressTitle}
						handleClose={closeAndClear}
					/>
					<Content>
						<RequiredFieldsText>{RequiredFieldNotice}</RequiredFieldsText>
						<FieldGroup>
							<TextField
								{...getFormFieldProps({
									name: "fullName",
									helperText: FullNameHelperText,
									characterLimit: {
										currentLength: fullNameInput.length,
										limit: FULL_NAME_MAX_LENGTH
									},
									...formFieldData
								})}
								InputLabelProps={{ shrink: Boolean(fullNameInput) || undefined }}
								label={FullNameLabel}
								size="small"
							/>
						</FieldGroup>
						<Autocomplete
							key={clearKey}
							freeSolo={true}
							getOptionLabel={(option) => {
								return typeof option === "string" ? option : (option.line1 ?? "");
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									{...getFormFieldProps({
										name: "address",
										...formFieldData,
										helperText: AddressHelperText
									})}
									label={AddressLabel}
									size="small"
								/>
							)}
							renderOption={(props, option) => {
								return (
									<AutocompleteOption
										{...props}
										key={JSON.stringify(option)}
									>
										<div>{option.line1}</div>
										{option.line2 && <div>{option.line2}</div>}
										<div>
											{option.city}, {option.state}, {option.zip}
										</div>
									</AutocompleteOption>
								);
							}}
							componentsProps={{ clearIndicator: { onClick: handleClearIndicatorClick } }}
							ListboxComponent={ListboxComponent}
							onChange={handleAddressSelect}
							options={options}
						/>
						<TextField
							{...getFormFieldProps({ name: "address2", ...formFieldData })}
							InputLabelProps={{ shrink: Boolean(watch("address2")) || undefined }}
							label={Address2Label}
							size="small"
						/>
						<TextField
							{...getFormFieldProps({ name: "city", ...formFieldData })}
							InputLabelProps={{ shrink: Boolean(watch("city")) || undefined }}
							label={CityLabel}
							size="small"
						/>
						<SplitRow>
							<FormControl>
								<InputLabel
									id="state-label"
									htmlFor="state"
									size="small"
								>
									{StateLabel}
									<b> *</b>
								</InputLabel>
								<Select
									{...getFormFieldProps({ name: "state", helperText: null, ...formFieldData })}
									label={StateLabel}
									labelId="state-label"
									value={stateValue}
									size="small"
								>
									{states.map((state) => (
										<MenuItem
											key={state}
											value={state}
										>
											{state}
										</MenuItem>
									))}
								</Select>
								{formState.errors.state && (
									<FormHelperText error>
										<HelperText
											isError
											name="state"
											text={formState.errors.state.message}
										/>
									</FormHelperText>
								)}
							</FormControl>
							<TextField
								{...getFormFieldProps({ name: "zip", ...formFieldData })}
								InputLabelProps={{ shrink: Boolean(watch("zip")) || undefined }}
								label={ZipLabel}
								size="small"
								inputProps={{
									maxLength: 10
								}}
							/>
							<TextField
								{...getFormFieldProps({ name: "county", ...formFieldData })}
								InputLabelProps={{ shrink: Boolean(watch("county")) || undefined }}
								label={CountyLabel}
								size="small"
							/>
						</SplitRow>
						<Divider />
					</Content>
					<BottomSection>
						<div>
							<div>{ContactInformationTitle}</div>
							<div>{ContactInformationNotice} </div>
							<div>{RequiredFieldNotice}</div>
						</div>
						<TextField
							{...getFormFieldProps({ name: "contactPhoneNumber", ...formFieldData })}
							InputLabelProps={{ shrink: Boolean(watch("contactPhoneNumber")) || undefined }}
							label={PhoneLabel}
							size="small"
							InputProps={{
								inputComponent: MaskedPhoneTextField as any
							}}
						/>
						<TextField
							{...getFormFieldProps({ name: "email", ...formFieldData })}
							InputLabelProps={{ shrink: Boolean(watch("email")) || undefined }}
							label={EmailLabel}
							size="small"
						/>
					</BottomSection>
					<FooterActions
						handleClose={closeAndClear}
						submitsForm
						isAddNewAddressModal
					/>
				</form>
			</StyledDialog>
		</ThemeProvider>
	);
};

export default AddNewAddressModal;
