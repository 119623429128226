/* tslint:disable */
/* eslint-disable */
/**
 * Marketplace
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */

//Below enum is in chronological order, do NOT re-organize without talking to design first!
export enum OrderStatus {
	ACCEPTED = "ACCEPTED",
	RECEIVED = "RECEIVED",
	SUBMITTEDWITHERRORS = "SUBMITTED_WITH_ERRORS",
	HOLD = "HOLD",
	SCHEDULEDFORPRODUCTION = "SCHEDULED_FOR_PRODUCTION",
	INPRODUCTION = "IN_PRODUCTION",
	PRODUCTIONCOMPLETE = "PRODUCTION_COMPLETE",
	HEADEDTOCROSSDOCK = "HEADED_TO_CROSS_DOCK",
	ENROUTEVIACARRIER = "EN_ROUTE_VIA_CARRIER",
	DELIVERED = "DELIVERED",
	INVOICED = "INVOICED",
	CANCELLED = "CANCELLED",
	UNKNOWN = "UNKNOWN"
}
