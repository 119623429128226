/**
 *
 * @export
 * @enum {string}
 */
export enum NewOrderModalTracking {
	CSV_UPLOAD = "CSV_UPLOAD",
	SELECT_BILLTO_ACCOUNT = "SELECT_BILLTO_ACCOUNT",
	NONE = "NONE"
}
