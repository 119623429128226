import { Card, skeletonClasses } from "@mui/material";
import styled from "@emotion/styled";

export const Header = styled.div({
	display: "grid",
	padding: 16,
	gap: 10,
	"div:nth-of-type(1)": {
		display: "flex",
		justifyContent: "space-between",
		"span:nth-of-type(1)": {
			width: 115
		},
		"span:nth-of-type(2)": {
			maxWidth: 190,
			minWidth: 177
		}
	},
	"div:nth-of-type(4) span": {
		width: 230
	}
});

export const InfoSkeleton = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: 24,
	"div:nth-of-type(1) span": {
		width: 194
	},
	"div:nth-of-type(3), div:nth-of-type(4)": {
		display: "grid",
		gap: 24,
		gridTemplateColumns: "1fr 1fr",
		[theme.breakpoints.down("md")]: {
			gridTemplateColumns: "1fr"
		}
	}
}));

export const LineItemSkeletonContainer = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: 24
});

export const LineItemSkeleton = styled.div(({ theme }) => ({
	display: "grid",
	gap: 48,
	gridTemplateColumns: "3fr 2fr",
	div: {
		display: "flex",
		width: "100%"
	},
	"& > div:nth-of-type(1)": {
		flexDirection: "column",
		gap: 8
	},
	"& > div:nth-of-type(2)": {
		alignItems: "center",
		gap: 22,
		div: {
			flexDirection: "column",
			gap: 8
		}
	},
	[theme.breakpoints.down("md")]: {
		gap: 16,
		gridTemplateColumns: "repeat(5, 1fr)",
		"& > div:nth-of-type(1)": {
			gridColumn: "span 5"
		},
		"& > div:nth-of-type(2)": {
			gridColumn: "span 2",
			"& > div:nth-of-type(n + 2)": {
				display: "none"
			}
		}
	}
}));

export const LineItemConfigHeaderSkeleton = styled.div(({ theme }) => ({
	display: "grid",
	gap: 24,
	"& > div:nth-of-type(1)": {
		display: "flex",
		justifyContent: "space-between",
		"span:nth-of-type(1)": {
			maxWidth: 241
		},
		"span:nth-of-type(2)": {
			maxWidth: 133
		},
		span: {
			flex: 1
		}
	},
	"& > div:nth-of-type(3)": {
		display: "flex",
		flexDirection: "column"
	},
	[theme.breakpoints.down("md")]: {
		"& > div:nth-of-type(1)": {
			display: "none"
		},
		"& > div:nth-of-type(2)": {
			span: {
				// MUI applies the height prop as an inline style, so to avoid a show/hide with another span this is acceptable
				height: "158px !important"
			}
		}
	}
}));

export const SkeletonCard = styled(Card)(({ theme }) => ({
	borderRadius: 0,
	display: "flex",
	flexDirection: "column",
	gap: 48,
	padding: 24,
	[theme.breakpoints.down("md")]: {
		gap: 24
	}
}));

export const SkeletonContent = styled.div({
	padding: 20
});

export const CommonSkeletonStyles = styled.div({
	[`.${skeletonClasses.root}`]: {
		transform: "unset"
	}
});
