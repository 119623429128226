import styles from "../order-status-page-styles.module.css";
import {
	AccessDeniedDescription,
	AccessDeniedHeader,
	PendingApprovalDescription,
	PendingApprovalHeader,
	ReturnToSignInButtonText
} from "constants/text";
import useB2CAccount from "auth/useB2CAccount";
import { useParams } from "react-router-dom";
import { ErrorButtonWrapper } from "../ErrorModal.styles";

const StatusUnapproved = () => {
	const { status } = useParams();
	const { logout } = useB2CAccount();

	return (
		<div
			className={styles.errorContainer}
			data-testid="status-unapproved-error-container"
		>
			<div className={styles.errorContent}>
				<img
					src="/assets/tandem_logos/tandem.png"
					alt="Tandem Logo"
					className={styles.tandemLogo}
					data-testid="status-unapproved-error-tandem-logo"
				/>
				<img
					src="/assets/UnauthorizedSadImage.svg"
					alt="status-unapproved access logo"
					className={styles.noServiceSVG}
					data-testid="status-unapproved-logo"
				/>
				{status === "pending" && (
					<div>
						<h1 data-testid="status-unapproved-pending-approval-header">{PendingApprovalHeader}</h1>
						<p
							data-testid="status-unapproved-pending-approval-description"
							className={styles.errorText}
						>
							{PendingApprovalDescription}
						</p>
					</div>
				)}

				{status === "denied" && (
					<div>
						<h1 data-testid="status-unapproved-denied-header">{AccessDeniedHeader}</h1>
						<p
							data-testid="status-unapproved-denied-description"
							className={styles.errorText}
						>
							{AccessDeniedDescription}
						</p>
					</div>
				)}

				<ErrorButtonWrapper
					variant="contained"
					onClick={logout}
					data-testid="status-unapproved-signin-button"
				>
					{ReturnToSignInButtonText}
				</ErrorButtonWrapper>
			</div>
		</div>
	);
};

export default StatusUnapproved;
