import styled from "@emotion/styled";

export const OriginalItemTextContainer = styled.div({
	"div:nth-of-type(1) p": {
		fontSize: "1rem"
	},
	"div:nth-of-type(2) p": {
		fontSize: "0.875rem"
	}
});
