import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useCallback } from "react";

const useB2CAccount = () => {
	const { accounts, instance, inProgress } = useMsal();
	const currentAccount = accounts[0];

	const logout = useCallback(async () => {
		if (inProgress === InteractionStatus.None && currentAccount) {
			const redirectConfig = {
				account: instance.getAccountByHomeId(currentAccount.homeAccountId)
			};

			sessionStorage.clear();
			await instance.logoutRedirect(redirectConfig);
		}
	}, [currentAccount, inProgress, instance]);

	return {
		logout
	};
};

export default useB2CAccount;
