import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

export const AzureB2CPolicies = {
	names: {
		signIn: import.meta.env.VITE_APP_B2C_SIGNUP_SIGNIN
	},
	authorities: {
		signIn: {
			authority: `${import.meta.env.VITE_APP_B2C_INSTANCE}/${import.meta.env.VITE_APP_B2C_TENANT}/${import.meta.env.VITE_APP_B2C_SIGNUP_SIGNIN}`
		}
	},
	authorityDomain: import.meta.env.VITE_APP_B2C_INSTANCE
};

export const loggerCallback = (level: LogLevel, message: string, containsPii: boolean) => {
	if (containsPii) {
		return;
	}
	switch (level) {
		case LogLevel.Error:
			console.error(message);
			return;
		case LogLevel.Info:
			console.info(message);
			return;
		case LogLevel.Verbose:
			console.debug(message);
			return;
		case LogLevel.Warning:
			console.warn(message);
			return;
		default:
			return;
	}
};

export const msalConfig = {
	auth: {
		clientId: import.meta.env.VITE_APP_B2C_CLIENT_ID,
		authority: AzureB2CPolicies.authorities.signIn.authority,
		knownAuthorities: [AzureB2CPolicies.authorityDomain],
		redirectUri: import.meta.env.VITE_APP_AZB2C_REDIRECT_URI,
		postLogoutRedirectUri: import.meta.env.VITE_APP_AZB2C_LOGOUT_REDIRECT_URI,
		navigateToLoginRequestUrl: false
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: true
	},
	system: {
		allowRedirectInIframe: true,
		loggerOptions: {
			loggerCallback
		}
	}
};

export const msalInstance = new PublicClientApplication(msalConfig);
