import styled from "@emotion/styled";

interface ShoppingCartFlyoutScrollableWrapperProps {
	isMobile: boolean;
	isReplacementsPage: boolean;
	isDetailsPage?: boolean;
}

export const ShoppingCartFlyoutScrollableWrapper = styled.div<ShoppingCartFlyoutScrollableWrapperProps>(
	({ isMobile, isReplacementsPage, isDetailsPage }) => {
		let pxForCalculation;
		if (isMobile && isReplacementsPage) {
			pxForCalculation = 298;
		} else if (!isMobile && isReplacementsPage) {
			pxForCalculation = 253;
		} else if (isMobile && !isReplacementsPage) {
			pxForCalculation = 181;
		} else {
			pxForCalculation = 136;
		}

		if (isMobile && isDetailsPage) {
			pxForCalculation = 165;
		} else if (!isMobile && isDetailsPage) {
			pxForCalculation = 120;
		}

		return {
			overflowY: "scroll",
			height: `calc(100vh - ${pxForCalculation}px)`
		};
	}
);
