import { OrderDetailViewModel } from "data/api/v1";
import { arrayGroupBy } from "utils/array";

export const GetBackorderCount = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order?.partInfo?.partsQuantityBackordered !== undefined && order?.partInfo?.partsQuantityBackordered > 0
	);
	const lineItemsCabinetOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order.quantityBackordered !== undefined &&
			order.quantityBackordered !== null &&
			order.quantityBackordered > 0
	);
	const hasCabinetBackorder = lineItemsCabinetOnBackorder?.map((e) => e?.quantityBackordered)?.length;
	const hasPartBackorder = lineItemsOnBackorder?.map((e) => e?.partInfo?.partsQuantityBackordered)?.length;
	return Number(
		hasPartBackorder !== undefined && hasCabinetBackorder !== undefined && hasPartBackorder + hasCabinetBackorder
	);
};

export const PartsQuantityBackorderedLength = (orderDetail: OrderDetailViewModel) => {
	const lineItemsOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order?.partInfo?.partsQuantityBackordered !== undefined && order?.partInfo?.partsQuantityBackordered > 0
	);
	return lineItemsOnBackorder?.map((e) => e?.partInfo?.partsQuantityBackordered)?.length;
};

export const BackorderedCabinetCount = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsCabinetOnBackorder = orderDetail?.lineItems?.filter(
		(order) =>
			order.quantityBackordered !== undefined &&
			order.quantityBackordered !== null &&
			order.quantityBackordered > 0
	);

	return lineItemsCabinetOnBackorder?.map((e) => e?.quantityBackordered)?.length;
};

export const GroupAllTrackingNumbers = (orderDetail: OrderDetailViewModel): string[] => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];

	const groupAllByTrackingNumber = arrayGroupBy(
		lineItemsMap,
		(product: { trackingNumber: string | null; truckNumber: string | null }) => {
			return product.trackingNumber?.length && !product.truckNumber
				? product.trackingNumber
				: !product.trackingNumber;
		}
	);

	return [...(groupAllByTrackingNumber as any)].map((e) => (e[0]?.length > 1 ? e : []));
};

export const GroupAllTruckNumbers = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];
	const groupAllByTruckNumber = arrayGroupBy(
		lineItemsMap,
		(product: { trackingNumber: string | null; truckNumber: string | null }) => {
			return product.truckNumber?.length && !product.trackingNumber ? product.truckNumber : !product.truckNumber;
		}
	);

	return [...(groupAllByTruckNumber as any)].map((e) => (e[0]?.length > 1 ? e : []));
};

export const GroupTrackingAndTruckingNumbers = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];

	const trackingAndTrucking = arrayGroupBy(
		lineItemsMap,
		(product: { trackingNumber: string | null; truckNumber: string | null }) => {
			return product.trackingNumber && product.truckNumber;
		}
	);

	return [...(trackingAndTrucking as any)].map((e) => (e[0]?.length > 1 ? e : []));
};

export const GetNormalLineItems = (orderDetail: OrderDetailViewModel | null) => {
	const lineItemsMap = orderDetail?.lineItems?.map ? orderDetail.lineItems.map((e) => e) : [];

	return arrayGroupBy(lineItemsMap, (product: { trackingNumber: string | null; truckNumber: string | null }) => {
		return (
			(!product.trackingNumber && !product.truckNumber) ||
			product.trackingNumber === null ||
			product.truckNumber === null
		);
	});
};
