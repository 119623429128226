import { InputAdornment } from "@mui/material";
import { FilterSearch, FilterSearchWrapper } from "./chipDropdownMenu.styles";
import { FilterType } from "./filterType";
import Close from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import { useEffect, useRef } from "react";

const ChipDropdownMenuSearch = ({
	filterType,
	handleSearch,
	cancelSearch,
	isSearched,
	value
}: ChipDropdownMenuSearchProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const handleCancelSearch = () => {
		if (inputRef.current) {
			inputRef.current.value = "";
		}
		cancelSearch();
	};

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.value = value;
		}
	}, [value]);

	return (
		<FilterSearchWrapper data-testid="chip-dropdown-menu-search">
			<FilterSearch
				size="small"
				fullWidth
				label={`Search by ${filterType}`}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							{isSearched ? (
								<Close
									onClick={() => handleCancelSearch()}
									sx={{ cursor: "pointer" }}
									data-testid="chip-dropdown-menu-search-close-icon"
								/>
							) : (
								<Search data-testid="chip-dropdown-menu-search-search-icon" />
							)}
						</InputAdornment>
					)
				}}
				onKeyDown={(e) => e.stopPropagation()}
				onChange={(e) => handleSearch(e.target.value)}
				inputRef={inputRef}
				data-testid="chip-dropdown-menu-search-input"
			/>
		</FilterSearchWrapper>
	);
};

interface ChipDropdownMenuSearchProps {
	filterType: FilterType;
	handleSearch: (query: string) => void;
	cancelSearch: () => void;
	isSearched: boolean;
	value: string;
}

export default ChipDropdownMenuSearch;
