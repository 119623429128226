import { salesforceBaseURL } from "constants/endpoints";
import { CabinetworksGroupApplication } from "data/api/v1/model/cabinetworks-group-application";

export const getNavigationLink = (
	hasSubMenu: boolean,
	appLink: CabinetworksGroupApplication | undefined,
	link: string | undefined | null
): string => {
	if (!hasSubMenu) {
		const partialLink = link ?? "";

		switch (appLink) {
			case CabinetworksGroupApplication.Salesforce:
				return salesforceBaseURL + partialLink;
			case CabinetworksGroupApplication.Tandem:
			default:
				return partialLink;
		}
	}

	return "";
};
