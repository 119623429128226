/* tslint:disable */

/**
 * Marketplace
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum OrderStatusReason {
	MISSINGINFORMATION = "MISSING_INFORMATION",
	INCORRECTPRICING = "INCORRECT_PRICING",
	ITEMSONBACKORDER = "ITEMS_ON_BACKORDER",
	DELIVERYDATECHANGED = "DELIVERY_DATE_CHANGED",
	PRODUCTVALIDATIONHOLD = "PRODUCT_VALIDATION_HOLD",
	CUSTOMERHOLD = "CUSTOMER_HOLD",
	PROCESSINGHOLD = "PROCESSING_HOLD",
	PARTIALLY_SHIPPED = "PARTIALLY_SHIPPED"
}
