export const allColors = {
	hex: {
		blue: {
			cwg: {
				cwgBlue50: "#e3f7fb",
				cwgBlue100: "#b7eaf5",
				cwgBlue200: "#8adcf0",
				cwgBlue300: "#60ceeb",
				cwgBlue400: "#42c3ea",
				cwgBlue500: "#28b8e9",
				cwgBlue600: "#1faadb",
				cwgBlue700: "#1096c8",
				cwgBlue800: "#0f85b5",
				cwgBlue900: "#006594",
				vectorBlue: "#55bbda"
			},
			light: {
				lightBlue50: "#E1F7FD",
				lightBlue100: "#B3EBF9",
				lightBlue200: "#81DDF4",
				lightBlue300: "#4DCFED",
				lightBlue400: "#23C6E7",
				lightBlue500: "#00BCE1",
				lightBlue600: "#00ACCD",
				lightBlue700: "#0097B2",
				lightBlue800: "#008399",
				lightBlue900: "#00616D"
			}
		},
		grayscale: {
			black50: "#000000",
			white50: "#FFFFFF",
			gray50: "#fcfcfc",
			gray100: "#f7f7f7",
			gray200: "#f2f2f2",
			gray300: "#e9e9e9",
			gray400: "#c7c7c7",
			gray500: "#a9a9a9",
			gray600: "#7f7f7f",
			gray700: "#6a6a6a",
			gray750: "#565759",
			gray800: "#4b4b4b",
			gray900: "#292929"
		},
		green: {
			green50: "#f4f8e9",
			green100: "#e4edc7",
			green200: "#d2e1a3",
			green300: "#c0d57f",
			green400: "#b2cc62",
			green500: "#a4c346",
			green600: "#94b33e",
			green650: "#869931",
			green700: "#7ea034",
			green800: "#6a8c2b",
			green900: "#476a1b"
		},
		orange: {
			amber50: "#f8e8c6",
			orange50: "#f9eae8",
			orange100: "#f5cdbf",
			orange200: "#eeac95",
			orange300: "#e78a6a",
			orange400: "#e1714a",
			orange500: "#dc582a",
			orange600: "#d85025",
			orange700: "#d3471f",
			orange800: "#ce3d19",
			orange900: "#c52d0f"
		},
		red: {
			red50: "#feecef",
			red100: "#fdcfd5",
			red200: "#ed9da0",
			red300: "#e3787b",
			red400: "#ed5a5b",
			red500: "#f34a43",
			red600: "#e44242",
			red700: "#d2393b",
			red800: "#c53335",
			red900: "#b5292a"
		}
	},
	rgb: {
		blue: {
			cwg: {
				cwgBlue900: "0, 101, 148"
			}
		},
		grayscale: {
			black: "0,0,0",
			white: "255,255,255"
		}
	}
} as const;
