import styles from "pages/replacements-page-styles.module.css";
import { Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { getAllItemsCount } from "./ReplacementsUtil";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import CurrentStepIcon from "./CurrentStepIcon";
import FlyoutHandler from "./FlyoutHandler";
import { StyledShoppingCartOutlinedIcon } from "./ShoppingCartHeader.styles";

interface ShoppingCartIconProps {
	showButtons: boolean;
}

const ShoppingCartIcon = ({ showButtons }: ShoppingCartIconProps) => {
	const [showReplacement, setShowReplacement] = useState(false);
	const [showShoppingCart, setShowShoppingCart] = useState(false);

	const toggleShoppingCart = () => setShowShoppingCart(true);

	useEffect(() => {
		if (showReplacement) {
			setShowReplacement(false);
		}
		if (showShoppingCart) {
			setShowShoppingCart(false);
		}
	}, [showReplacement, showShoppingCart]);

	const cartItems = useSelector((state: RootState) => state.cart.replacements);
	const allItemsSum = getAllItemsCount(cartItems);

	return (
		<div>
			<Grid item>
				<div className={styles.shoppingCartIconContainer}>
					<IconButton onClick={toggleShoppingCart}>
						<StyledShoppingCartOutlinedIcon size="30px" />
						<div
							className={styles.shoppingCartIcon}
							onClick={toggleShoppingCart}
							data-testid="shoppingCartItemCount"
						>
							{allItemsSum > 0 && (
								<CurrentStepIcon
									text={String(allItemsSum)}
									nextRoute={false}
								/>
							)}
						</div>
					</IconButton>
				</div>
			</Grid>

			<FlyoutHandler
				showButtons={showButtons}
				dataTestId="replacement-shopping-cart-container"
				showShoppingCartTrigger={showShoppingCart}
			/>
		</div>
	);
};

export default ShoppingCartIcon;
