import styles from "../../../containers/Orders/order-styles.module.css";
import { SaveDraftNoOrdersHeader, SaveDraftNoOrdersSubheader } from "../constants";

const SaveDraftNoDrafts = () => {
	return (
		<div className={styles.noOrdersErrorBannerContainer}>
			<div>
				<img
					src="/assets/NoOrdersSadBoxImage.svg"
					alt="No Save Draft Orders banner"
					className={styles.noOrdersErrorBannerContainerImage}
					data-testid="no-save-draft-orders-icon"
				/>
				<h5 data-testid="no-save-draft-orders-header">{SaveDraftNoOrdersHeader}</h5>
				<p data-testid="no-save-draft-orders-subheader">{SaveDraftNoOrdersSubheader}</p>
			</div>
		</div>
	);
};

export default SaveDraftNoDrafts;
