import { PropsWithChildren } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "pages/Replacements/forms/AddressForm/schema";

const AddressFormProvider = ({ children }: PropsWithChildren) => {
	const formMethods = useForm({
		mode: "onSubmit",
		resolver: yupResolver(schema),
		reValidateMode: "onChange"
	});

	return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export default AddressFormProvider;
