import { Card, ClickAwayListener, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import DateRange from "@mui/icons-material/DateRange";
import DatePickerCalendar from "./DatePickerCalendar";
import { formatDateToMMDDYYYY, isValidDate } from "utils/date";

interface DatePickerDateFieldProps {
	label: string;
	value?: Date;
	firstValidDate?: Date;
	secondValidDate?: Date;
	onChange: (date: Date) => void;
}

const DatePickerDateField = ({ label, value, firstValidDate, secondValidDate, onChange }: DatePickerDateFieldProps) => {
	const [dateValue, setDateValue] = useState<Date>(value || new Date());
	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const [dateInputText, setDateInputText] = useState(formatDateToMMDDYYYY(dateValue));

	const handleClickOutside = () => {
		const newDateValue = new Date(dateInputText);
		if (isValidDate(newDateValue)) {
			setDateValue(newDateValue);
			setDateInputText(formatDateToMMDDYYYY(newDateValue));
			onChange(newDateValue);
		} else {
			setDateInputText(String(dateValue));
		}
		setShowCalendar(false);
	};

	const onHandleDateChange = (date: Date) => {
		if (isValidDate(date)) {
			setDateInputText(formatDateToMMDDYYYY(date));
			setDateValue(date);
			if (onChange) {
				onChange(date);
			}
			setShowCalendar(false);
		}
	};

	const onHandleDateInputChange = (e: string) => {
		setDateInputText(e);
		const newDate = new Date(e);
		if (isValidDate(newDate)) {
			onChange(newDate);
		}
	};

	const fromDateInvalid =
		(firstValidDate !== undefined && firstValidDate) > (secondValidDate !== undefined && secondValidDate);

	return (
		<div style={{ padding: "10px 0 10px 0" }}>
			<TextField
				onClick={() => setShowCalendar(true)}
				onChange={(e) => onHandleDateInputChange(e.target.value)}
				fullWidth
				id="date-range-picker"
				label={label}
				variant="outlined"
				size="small"
				value={dateInputText}
				error={fromDateInvalid}
				helperText={
					fromDateInvalid ? '"To" date must be later than "From" date to execute search properly.' : ""
				}
				inputProps={{ "data-testid": `${label}-date-range-picker-text-field` }}
				InputProps={{
					startAdornment: (
						<InputAdornment
							position="start"
							data-testid="date-range-picker-calendar-icon"
						>
							<DateRange />
						</InputAdornment>
					)
				}}
			/>
			{showCalendar && (
				<ClickAwayListener onClickAway={handleClickOutside}>
					<Card
						id={`date-picker-${label.replaceAll(" ", "")}-calendar`}
						sx={{
							mt: "1rem",
							flexDirection: "column"
						}}
					>
						<DatePickerCalendar
							value={dateValue}
							firstValidDate={firstValidDate}
							secondValidDate={secondValidDate}
							onChange={onHandleDateChange}
						/>
					</Card>
				</ClickAwayListener>
			)}
		</div>
	);
};

export default DatePickerDateField;
