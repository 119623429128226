import { Box, List, ListItem } from "@mui/material";
import styles from "pages/replacements-page-styles.module.css";
import { ItemAddedText, ItemsAddedText, YourOrderText } from "constants/text";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { getAllItemsCount } from "./ReplacementsUtil";
import { ShoppingCartHeaderWrapper } from "./ShoppingCartHeader.styles";

interface ShoppingCartHeaderProps {
	closeDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const ShoppingCartHeader = ({ closeDrawer }: ShoppingCartHeaderProps) => {
	const cartItems = useSelector((state: RootState) => state.cart.replacements);
	const allItemsSum = getAllItemsCount(cartItems);

	return (
		<ShoppingCartHeaderWrapper>
			<Box className={styles.headerTablet}>
				<List>
					<div className={styles.yourOrderText}>
						<h1>{YourOrderText}</h1>
					</div>
					<div className={styles.header}>
						<CloseIcon
							className={styles.closeIcon}
							onClick={closeDrawer}
							data-testid="shopping-cart-flyout-close-button"
						/>
					</div>
					{cartItems?.length ? (
						<ListItem className={`${styles.yourOrderText} ${styles.yourOrderTextListItem}`}>
							<CheckCircleOutlineIcon />
							<h3>
								{allItemsSum === 1
									? `${allItemsSum} ${ItemAddedText}`
									: `${allItemsSum} ${ItemsAddedText}`}
							</h3>
						</ListItem>
					) : undefined}
				</List>
			</Box>
		</ShoppingCartHeaderWrapper>
	);
};

export default ShoppingCartHeader;
