import styled from "@emotion/styled";
import { Autocomplete, Popper, TextField, autocompleteClasses } from "@mui/material";

export const StyledSuggestedSearch = styled(Autocomplete)({
	backgroundColor: "var(--white-50)",
	[`& .${autocompleteClasses.popupIndicator}`]: {
		transform: "none"
	}
}) as typeof Autocomplete;

export const SuggestedSearchTextField = styled(TextField)(({ theme }) => ({
	div: {
		div: {
			display: "flex",
			justifyContent: "flex-end"
		},
		input: {
			width: "100% !important"
		}
	},
	"& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
		color: theme.project.colors.fontColors.secondary,
		opacity: 1
	}
}));

export const SuggestedSearchPopper = styled(Popper)(({ theme }) => ({
	div: {
		ul: {
			"& .MuiAutocomplete-option": {
				backgroundColor: theme.project.colors.backgroundColors.main + " !important"
			},
			"& .MuiAutocomplete-option.Mui-focused": {
				backgroundColor: theme.project.colors.allColors.hex.grayscale.gray100 + " !important"
			}
		}
	}
}));
