import {
	OrderSummary400ErrorHeader,
	OrderSummary400ErrorText,
	OrderSummary403ErrorHeader,
	OrderSummary403ErrorText,
	OrderSummaryDefaultErrorHeader,
	OrderSummaryDefaultErrorText
} from "constants/text";
import styles from "containers/Orders/order-styles.module.css";
import useWindowSettings from "hooks/useWindowSettings";
import { useMemo } from "react";

const NoOrdersErrorBanner = ({ statusCode }: Props) => {
	const { isMobile } = useWindowSettings();

	const imageSrc =
		statusCode === 400 || statusCode === 403
			? "/assets/NoOrdersSadBoxImage.svg"
			: "/assets/UserAuthServiceError.svg";

	const getStatusText = (statusCode: number) => {
		if (statusCode === 400) {
			return { header: OrderSummary400ErrorHeader, subheader: OrderSummary400ErrorText };
		} else if (statusCode === 403) {
			return { header: OrderSummary403ErrorHeader, subheader: OrderSummary403ErrorText };
		} else {
			return { header: OrderSummaryDefaultErrorHeader, subheader: OrderSummaryDefaultErrorText };
		}
	};

	const { header, subheader } = useMemo(() => getStatusText(statusCode), [statusCode]);

	return (
		<div
			className={styles.noOrderSummaryErrorBannerContainer}
			style={isMobile ? { marginTop: 0, height: "calc(100vh - 245px)" } : { marginTop: 0 }}
			data-testid="order-summary-api-failure"
		>
			<img
				src={imageSrc}
				alt="Order Summary Error"
				className={styles.noOrdersErrorBannerContainerImage}
				data-testid="order-summary-error-sad-box-img"
			/>
			<span
				className={styles.noOrderSummaryErrorBannerStatus}
				data-testid="order-summary-error-status-code"
			>
				(Error Code: {statusCode})
			</span>
			<h5 data-testid="order-summary-error-header">{header}</h5>
			<p data-testid="order-summary-error-subheader">{subheader}</p>
		</div>
	);
};

interface Props {
	statusCode: number;
}

export default NoOrdersErrorBanner;
