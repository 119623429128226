import { useEffect, useState } from "react";
import { OrderDetailViewModel, OrderLineItemViewModel } from "data/api/v1";
import { Accordion, AccordionDetails, Chip, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import style from "pages/order-details-items-styles.module.css";
import { ItemsText, ItemText, ShipmentText } from "constants/text";
import { convertToTitleCase } from "utils/string";
import OrderDetailsLineItemGrid from "./OrderDetailsLineItemGrid";
import OrderDetailsShipmentHeader from "./OrderDetailsShipmentHeader";
import { ORDER_STATUS_REASONS } from "constants/orderStatusHeaderStatusReasons";
import { StyledAccordionSummary } from "components/OrderDetailsLineItem/orderDetailsShipmentAccordion.styles";

interface LineItemProps {
	orderDetail: OrderDetailViewModel | null;
	searchValue?: string | null;
	equalTrackingNumberCount: OrderLineItemViewModel[];
	trackingNumber: string[];
	truckNumber: string[];
	shipmentCount?: number;
}

export const OrderDetailsShipmentAccordion = ({
	orderDetail,
	searchValue,
	equalTrackingNumberCount,
	trackingNumber,
	truckNumber,
	shipmentCount
}: LineItemProps) => {
	const [shippingMessage, setShippingMessage] = useState("");

	const shipmentStatusMap = equalTrackingNumberCount.map((e: any) => e)[0].status;

	useEffect(() => {
		ORDER_STATUS_REASONS.forEach(
			(reason) => reason.status === shipmentStatusMap && setShippingMessage(reason.description)
		);
	}, [shipmentStatusMap]);

	const lineItems = equalTrackingNumberCount.map((item) => `${item.sku} ${item.description}`);
	const foundAccordionSearch = lineItems.find((item) =>
		item.toUpperCase().includes((searchValue ?? "").toUpperCase())
	);

	return (
		<div className={style.accordionBannerContainer}>
			{foundAccordionSearch && (
				<Accordion
					defaultExpanded
					data-testid="shipment-accordion"
					key={orderDetail?.orderId}
				>
					<StyledAccordionSummary
						expandIcon={<ExpandMoreIcon sx={{ color: "var(--cwg-blue-900)" }} />}
						aria-controls="panel1a-content"
						id="panel1a-header-backorder"
					>
						<Grid container>
							<Grid
								item
								lg={6}
								md={4}
								xs={12}
							>
								<Typography
									sx={{
										width: "100%",
										fontFamily: "Gibson Medium, sans-serif",
										fontSize: "var(--font-size-6)",
										color: "var(--cwg-blue-900)",
										display: "flex",
										paddingTop: "5px"
									}}
								>
									<img
										src="/assets/LocalShipping.svg"
										alt="Order Details Shipping Logo"
										data-testid="shipping-logo"
										className={style.backorderAndShippingIcon}
									/>
									{ShipmentText} {shipmentCount} (
									{equalTrackingNumberCount?.length === 1
										? `${equalTrackingNumberCount?.length} ${ItemText}`
										: `${equalTrackingNumberCount?.length} ${ItemsText}`}
									)
								</Typography>
							</Grid>

							{equalTrackingNumberCount?.map((e: any) => e)[0]?.status && (
								<Grid
									item
									xs={12}
									style={{
										marginLeft: "1rem",
										display: "flex",
										maxWidth: "min-content",
										alignItems: "center",
										paddingTop: "5px"
									}}
								>
									<Typography>
										<Chip
											label={convertToTitleCase(
												equalTrackingNumberCount.map((e: any) => e)[0].status
											)}
											size="small"
											variant="outlined"
											sx={{
												marginRight: "0.5rem",
												color: "var(--cwg-blue-900)",
												fontWeight: "500",
												borderColor: "var(--cwg-blue-900)",
												"& .MuiChip-label": {
													fontSize: "1rem",
													fontFamily: ["Gibson Medium", "sans-serif"].join(",")
												},
												"& .MuiChip-icon": {
													color: "var(--cwg-blue-900)"
												}
											}}
										/>
									</Typography>
								</Grid>
							)}

							<Grid
								item
								xs={12}
							>
								<Typography
									sx={{
										fontFamily: "Gibson Medium, sans-serif",
										fontSize: "var(--font-size-4)",
										color: "var(--cwg-blue-900)",
										display: "inline-flex",
										alignItems: "center"
									}}
								>
									<div
										style={{
											marginLeft: "1rem",
											display: "flex",
											height: "2rem",
											alignItems: "center",
											paddingTop: "5px"
										}}
									>
										{shippingMessage}
									</div>
								</Typography>
							</Grid>
						</Grid>
					</StyledAccordionSummary>
					<AccordionDetails>
						<OrderDetailsShipmentHeader
							orderDetail={orderDetail}
							trackingNumber={trackingNumber}
							truckNumber={truckNumber}
						/>

						{equalTrackingNumberCount.map((lineItemShipment) => {
							const foundSearch = `${lineItemShipment.sku} ${lineItemShipment.description}`
								.toUpperCase()
								.includes((searchValue ?? "").toUpperCase());
							if (foundSearch) {
								return (
									<OrderDetailsLineItemGrid
										key={lineItemShipment?.orderLineItemId}
										finish={lineItemShipment?.finish}
										measurements={lineItemShipment.measurements}
										modifications={lineItemShipment?.modifications}
										logo={orderDetail?.parentBrand}
										listPrice={lineItemShipment?.listPrice}
										extendedPrice={lineItemShipment.extendedPrice}
										sku={lineItemShipment?.sku}
										quantityBackordered={lineItemShipment.quantityBackordered}
										partsQuantityBackordered={lineItemShipment?.partInfo?.partsQuantityBackordered}
										parts={lineItemShipment?.partInfo?.parts}
										partInfoPartCount={
											lineItemShipment?.partInfo?.parts?.map((part: any) => part.description)
												.length
										}
										doorStyle={lineItemShipment?.doorStyle}
										description={lineItemShipment?.description}
										construction={lineItemShipment?.construction}
										hinge={lineItemShipment?.hinge}
										quantityOrdered={lineItemShipment?.quantityOrdered}
										orderType={orderDetail?.orderType}
										lineItemNumber={lineItemShipment?.lineItemNumber}
										itemNumber={lineItemShipment.name}
										lineItemId={lineItemShipment?.orderLineItemId}
									/>
								);
							}
							return undefined;
						})}
					</AccordionDetails>
				</Accordion>
			)}
		</div>
	);
};

export default OrderDetailsShipmentAccordion;
