/**
 * Represents the attributes used by the Google Tag Manager instance.
 *
 */
export interface GA4TagManagerAttributes {
	event?: string;
	eventAction?: string;
	eventCategory?: string;
	eventLabel?: string;
	dataPremise?: string;
	contactId?: string;
	wholeCabinetFileUploads?: number;
	partFileUploads?: number;
	replacementUploadType?: string;
	numberOfFileUploads?: number;
	defectCode?: string;
}

export const pushAnalytics = (params: any) => {
	try {
		// @ts-ignore
		window.dataLayer?.push(params);
	} catch {}
};

export const trackGA4Event = ({
	event,
	eventCategory,
	eventLabel,
	eventAction,
	contactId,
	numberOfFileUploads,
	replacementUploadType,
	dataPremise,
	defectCode
}: GA4TagManagerAttributes) => {
	const customDimensions = dataPremise
		? {
				dimension58: dataPremise
			}
		: {};

	pushAnalytics({
		event: event,
		event_category: eventCategory,
		event_label: eventLabel,
		event_action: eventAction,
		contact_id: contactId,
		number_of_file_uploads: numberOfFileUploads,
		replacement_upload_type: replacementUploadType,
		defect_code: defectCode,
		...customDimensions
	});
};
