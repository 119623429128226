import { UnavailableDataPlaceholderText } from "../constants/text";

export const splitWord = (word: string, length: number): string[] => {
	if (word.length <= length) {
		return [word];
	} else {
		const result: string[] = [];
		for (let i = 0; i < word.length; i += length) {
			result.push(word.substring(i, i + length));
		}
		return result;
	}
};

export const checkForAndReplaceNullEmptyWhitespace = (item: string | undefined | null | number) => {
	return (typeof item === "number" && item !== 0) || (item && item.trim() !== "")
		? item
		: UnavailableDataPlaceholderText;
};

export const toFormattedPrice = (price: number | undefined | null | string): string => {
	if (price === 0 || price === "0") return "$0.00";
	if (!price) return UnavailableDataPlaceholderText;

	const parsedPrice = Number(price);
	if (isNaN(parsedPrice)) return UnavailableDataPlaceholderText;

	return `$${(parsedPrice + 0.0001).toFixed(2)}`;
};

export const convertToTitleCase = (inputString: string | undefined | null) => {
	if (inputString === null || inputString === undefined) {
		return "";
	}

	const skipWords = new Set([
		"a",
		"an",
		"as",
		"the",
		"and",
		"but",
		"or",
		"for",
		"nor",
		"on",
		"at",
		"to",
		"by",
		"with",
		"via"
	]);

	return inputString
		.replaceAll("_", " ")
		.split(" ")
		.filter((word) => word.length > 0)
		.map((word) =>
			skipWords.has(word.toLowerCase())
				? word.toLowerCase()
				: word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		)
		.join(" ");
};

export const encodeQueryStringToBase64 = (url: string): string => {
	const urlObj = new URL(url);
	const encodedSearch = btoa(urlObj.searchParams.toString());
	urlObj.search = `?${encodedSearch}`;
	return urlObj.href;
};
