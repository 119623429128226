import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../features/windowSettings";

const useWindowSettingsInit = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		const handleResize = () => dispatch(actions.setWindowDimension(window.innerWidth));
		handleResize();

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, [dispatch]);
};

export default useWindowSettingsInit;
