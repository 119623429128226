import { FormGroup, FormControlLabel, Button, Radio } from "@mui/material";
import { useState } from "react";
import DatePickerDateField from "./DatePickerDateField";
import useSession from "hooks/useSession";
import { WthinLastDaysLabelText, CustomDateText, ApplyButtonText } from "constants/text";
import { getPriorDateByDaysOffset } from "utils/date";

interface DatePickerFormProps {
	fromDate?: Date;
	toDate?: Date;
	onSetDateRange: (from: Date, to: Date) => void;
}

const DatePickerForm = ({ onSetDateRange, fromDate, toDate }: DatePickerFormProps) => {
	const dayOffset = 90;
	const defaultFromDate = getPriorDateByDaysOffset(new Date(), dayOffset);
	const defaultToDate = new Date();

	const [useDefaultDateRange, setUseDefaultDateRange] = useSession<boolean>("_last_90", true);
	const [fromDateLocal, setFromDateLocal] = useState<Date>(
		useDefaultDateRange || fromDate === undefined ? defaultFromDate : fromDate
	);
	const [toDateLocal, setToDateLocal] = useState<Date>(
		useDefaultDateRange || toDate === undefined ? defaultToDate : toDate
	);
	const [formOpen, setFormOpen] = useState(true);

	const maximumDateRange = new Date();
	maximumDateRange.setFullYear(maximumDateRange.getFullYear() - 20);

	const handleOnClick = () => {
		const from = useDefaultDateRange ? defaultFromDate : fromDateLocal;
		const to = useDefaultDateRange ? defaultToDate : toDateLocal;
		setFormOpen((prev) => !prev);
		onSetDateRange(from, to);
	};

	const handleDateRangeTypeChanged = (isDefault: boolean) => setUseDefaultDateRange(isDefault);

	return (
		<>
			{formOpen && (
				<FormGroup
					sx={{
						p: "20px"
					}}
				>
					<FormControlLabel
						control={
							<Radio
								data-testid="date-picker-last-ninety-radio"
								checked={useDefaultDateRange}
								onChange={(e) => handleDateRangeTypeChanged(e.target.checked)}
							/>
						}
						label={WthinLastDaysLabelText}
					/>
					<FormControlLabel
						control={
							<Radio
								data-testid="date-picker-custom-dates-radio"
								checked={!useDefaultDateRange}
								onChange={(e) => handleDateRangeTypeChanged(!e.target.checked)}
							/>
						}
						label={CustomDateText}
					/>
					{!useDefaultDateRange && (
						<>
							<DatePickerDateField
								data-testid="date-picker-form-item-from"
								label="From"
								value={fromDateLocal}
								firstValidDate={maximumDateRange}
								secondValidDate={toDateLocal}
								onChange={setFromDateLocal}
							/>
							<DatePickerDateField
								data-testid="date-picker-form-item-to"
								label="To"
								value={toDateLocal}
								firstValidDate={fromDateLocal}
								secondValidDate={toDateLocal}
								onChange={setToDateLocal}
							/>
						</>
					)}
					<Button
						variant="outlined"
						onClick={handleOnClick}
						sx={{ margin: ".5rem 0 0 0" }}
						data-id="date-picker-apply-button"
					>
						{ApplyButtonText}
					</Button>
				</FormGroup>
			)}
		</>
	);
};

export default DatePickerForm;
