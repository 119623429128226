import { Grid } from "@mui/material";
import SuggestedSearch from "components/Common/SuggestedSearch/SuggestedSearch";
import { SearchPartText } from "constants/text";

interface PartsSearchProps {
	searchOptions: string[];
	searchValue: string;
	handleSearch: (e: any, v: any) => void;
}

const PartsSearch = ({ searchOptions, searchValue, handleSearch }: PartsSearchProps) => {
	return (
		<div>
			<Grid
				container
				columns={12}
			>
				<Grid
					item
					xs={12}
				>
					{" "}
					<SuggestedSearch
						ariaLabel="search-replacements"
						dataTestId="replacements-lineItem-search"
						dropDownOpenOnChar={3}
						placeholder={SearchPartText}
						handleSearch={(event, values) => handleSearch(event, (values as string) ?? "")}
						id="replacements-search-parts"
						searchOptions={searchOptions ?? []}
						searchValue={searchValue ?? ""}
						size="small"
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default PartsSearch;
