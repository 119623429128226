import { Box } from "@mui/material";
import {
	NoOrdersFoundFirstBullet,
	NoOrdersFoundHeader,
	NoOrdersFoundSecondBullet,
	NoOrdersFoundThirdBullet,
	NoOrdersListSubSubheader,
	NoOrdersListSubheader
} from "constants/text";
import styles from "containers/Orders/order-styles.module.css";

const NoOrdersErrorBanner = () => {
	return (
		<div className={styles.noOrdersErrorBannerContainer}>
			<Box maxWidth="366px">
				<img
					src="/assets/NoOrdersSadBoxImage.svg"
					alt="No Orders Banner"
					className={styles.noOrdersErrorBannerContainerImage}
				/>
				<h5>{NoOrdersFoundHeader}</h5>
				<p>{NoOrdersListSubheader}</p>
				<ul>
					<li>{NoOrdersFoundFirstBullet}</li>
					<li>{NoOrdersFoundSecondBullet}</li>
					<li>{NoOrdersFoundThirdBullet}</li>
				</ul>
				<p>{NoOrdersListSubSubheader}</p>
			</Box>
		</div>
	);
};

export default NoOrdersErrorBanner;
