// LIST PROVIDED BY JACOB AS OF 02/16/24
export const ORDER_PARENT_BRANDS = [
	"ADVANTA",
	"ARBOR_CREEK_CABINETS",
	"CARDELL_CABINETRY",
	"CEDAR_SPRINGS",
	"DESIGNCRAFT_CABINETS",
	"GALLERY",
	"HAMPTON_BAY",
	"KRAFTMAID",
	"MASTERCRAFT",
	"MEDALLION_MENARDS",
	"MEDALLION",
	"MERILLAT",
	"MODICO",
	"PRIVATE_LABEL",
	"PROJECT_SOURCE",
	"QUALITYCABINETS",
	"SCHULER",
	"SERENADE",
	"SMART_CABINETRY",
	"STUDIO_FULL_ACCESS",
	"WOLF_ARTISAN",
	"YORKTOWNE",
	"UNKNOWN",
	"MASTERPIECE",
	"CONCEPTS_CABINETRY"
];
