import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import { paperClasses } from "@mui/material/Paper";

export const Content = styled.div(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "16px",
	"div:first-of-type": {
		padding: "0 24px"
	}
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	[`.${paperClasses.root}`]: {
		maxWidth: "655px",
		paddingBottom: "24px",
		h2: {
			gap: "16px",
			alignItems: "flex-start",
			"button:first-of-type": {
				paddingTop: "5px"
			}
		},
		[theme.breakpoints.down("md")]: {
			width: "257px",
			h2: {
				"button:first-of-type": {
					padding: "4px 0px"
				}
			}
		}
	}
}));
