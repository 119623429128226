import { Skeleton } from "@mui/material";
import styles from "containers/Orders/order-styles.module.css";

const OrderListSkeleton = () => {
	return (
		<div
			data-testid="order-list-skeleton-container"
			className={styles.orderListAccordionSkeleton}
		>
			<div className={styles.orderListSkeletonTopRow}>
				<Skeleton
					data-testid="order-card-skeleton-header-1"
					animation="wave"
					sx={{ marginRight: "0.5rem", width: "10vw" }}
				/>
				<Skeleton
					data-testid="order-card-skeleton-header-2"
					animation="wave"
					sx={{ width: "10vw" }}
				/>
			</div>
			<Skeleton
				data-testid="order-card-skeleton-subheader"
				animation="wave"
				sx={{ width: "100%" }}
				data-test
			/>
		</div>
	);
};

export default OrderListSkeleton;
