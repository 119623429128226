import styled from "@emotion/styled";

export const LegalLinksWrapper = styled.div(({ theme }) => ({
	display: "flex",
	gap: 12,
	marginLeft: "272px",
	padding: "12px 0",
	width: "fit-content",
	[theme.breakpoints.down("lg")]: {
		margin: "20px"
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		margin: "20px",
		padding: 0,
		hr: {
			display: "none"
		}
	}
}));
