export const LEGAL_LINKS = [
	{
		title: "Privacy Policy",
		url: "https://cabinetworksgroup.com/privacy-policy/",
		GTMIdentifier: "privacy-policy-link"
	},
	{
		title: "Sales Terms & Conditions",
		url: "https://cabinetworksgroup.com/terms/",
		GTMIdentifier: "sales-terms-and-conditions-link"
	},
	{
		title: "Transparency in Supply Chain Act Disclosure",
		url: "https://cabinetworksgroup.com/cabinetworksgroupmichiganllc-california-transparency-in-supply-chains/",
		GTMIdentifier: "transparency-in-supply-chain-act-disclosure-link"
	}
];
