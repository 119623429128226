import styled from "@emotion/styled";

export const CWGBrandingContainer = styled.div(({ theme }) => ({
	padding: "1rem 0.5rem",
	[theme.breakpoints.down("lg")]: {
		marginLeft: "0.75rem",
		padding: 0,
		marginTop: "-10px"
	}
}));

export const CWGBrandingLogo = styled.img(({ theme }) => ({
	width: "208px",
	height: "50px",
	[theme.breakpoints.down("lg")]: {
		width: "150px",
		height: "36px",
		margin: "1.25rem 0",
		position: "absolute"
	}
}));
