import styled from "@emotion/styled";
import LineItemGrid from "components/Common/LineItemGrid/LineItemGrid";
import { LoadingSkeletonProps } from "../../Common/LineItemGrid/LineItemGridStyles";

export const BuildLineItemGrid = styled(LineItemGrid)<LoadingSkeletonProps>(({ isLoading }) => ({
	display: isLoading ? "none" : "grid",
	"& > div:first-of-type > div:first-of-type": {
		gridColumn: "span 3"
	}
}));
