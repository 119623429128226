import styled from "@emotion/styled";
import { ListItemButton, ListItemText } from "@mui/material";
import Person from "@mui/icons-material/Person";
export const NavigationSubMenuItemButtonContainer = styled.div({
	margin: "1rem 0",
	display: "flex",
	alignItems: "center",
	"&:hover": {
		backgroundColor: "#004465",
		borderRadius: "1.5rem"
	}
});

export const NavigationSubMenuLinks = styled.div({
	fontSize: "0.875rem",
	backgroundColor: "#005982",
	height: "100vh",
	padding: "1rem 0.5rem"
});

export const SubMenuListItemButton = styled(ListItemButton)({
	"&.Mui-selected": {
		"&:hover": {
			borderRadius: "1.5rem",
			backgroundColor: "#004465"
		},
		borderRadius: "1.5rem",
		backgroundColor: "#004465"
	},
	"&:hover": {
		backgroundColor: "transparent"
	}
});
export const NavigationSubMenuItemText = styled(ListItemText)({
	color: "#ffffff",
	width: "auto"
});

export const CompressedMenuSection = styled.div({
	padding: "2.5rem 1.5rem",
	display: "flex",
	alignItems: "center",
	justifyContent: "center"
});

export const CompressedMenuPersonIcon = styled(Person)({
	color: "#ffffff"
});

export const CompressedMenuNavigationContainer = styled.div({
	paddingTop: "1rem"
});

export const CompressedNavigationMenuButton = styled(ListItemButton)({
	padding: 0,
	height: "55px",
	justifyContent: "center",
	"&:hover": {
		boxSizing: "border-box",
		backgroundColor: "transparent"
	}
});

export const CompressedNavigationIcon = styled.img({
	"&:hover": {
		boxSizing: "border-box",
		transformOrigin: "center",
		padding: "0.5rem",
		backgroundColor: "#005982",
		borderRadius: "1.5rem"
	}
});

const CompressedNavigationIconContainerWidth = "56px";

export const CompressedNavigationContainer = styled.div({
	display: "grid",
	gridTemplateColumns: `${CompressedNavigationIconContainerWidth} auto`
});
