import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledDialogTitle } from "components/Dialog/dialog.styles";

interface TitleProps {
	title: string;
	handleClose?: () => void;
}

const DialogTitle = ({ handleClose, title }: TitleProps) => (
	<StyledDialogTitle id="customized-dialog-title">
		<div>{title}</div>
		{handleClose && (
			<IconButton
				aria-label="close"
				data-testid="close-modal-icon-button"
				onClick={handleClose}
			>
				<CloseIcon />
			</IconButton>
		)}
	</StyledDialogTitle>
);

export default DialogTitle;
