export const ORDER_STATUS_REASONS = [
	{
		id: 1,
		status: "RECEIVED",
		formattedStatus: "Received",
		description: "Order has been submitted to Cabinetworks Group.",
		statusColor: "var(--cwg-blue-900)"
	},
	{
		id: 2,
		status: "ACCEPTED",
		formattedStatus: "Accepted",
		description: "Order has been validated and been accepted for production scheduling.",
		statusColor: "var(--cwg-blue-900)"
	},
	{
		id: 3,
		status: "SUBMITTED_WITH_ERRORS",
		formattedStatus: "Submitted with Errors",
		description: "Order has been submitted with errors and could not be validated.",
		statusColor: "var(--orange-500)"
	},
	{
		id: 4,
		status: "HOLD",
		formattedStatus: "Hold",
		description: "Production on hold.",
		statusColor: "var(--orange-500)"
	},
	{
		id: 5,
		status: "SCHEDULED_FOR_PRODUCTION",
		formattedStatus: "Scheduled for Production",
		description: "Order has been scheduled for production.",
		statusColor: "var(--cwg-blue-900)"
	},
	{
		id: 6,
		status: "IN_PRODUCTION",
		formattedStatus: "In Production",
		description: "Order currently being built on the production line.",
		statusColor: "var(--cwg-blue-900)"
	},
	{
		id: 7,
		status: "PRODUCTION_COMPLETE",
		formattedStatus: "Production Complete",
		description: "Production has been completed and order is packaged.",
		statusColor: "var(--cwg-blue-900)"
	},
	{
		id: 8,
		status: "HEADED_TO_CROSS_DOCK",
		formattedStatus: "Headed to Cross Dock",
		description: "Order is en route to shipping carrier.",
		statusColor: "var(--cwg-blue-900)"
	},
	{
		id: 9,
		status: "EN_ROUTE_VIA_CARRIER",
		formattedStatus: "En Route via Carrier",
		description: "Order is en route to the shipping address with carrier.",
		statusColor: "var(--cwg-blue-900)"
	},
	{
		id: 10,
		status: "DELIVERED",
		formattedStatus: "Delivered",
		description: "Order has been delivered to the delivery address.",
		statusColor: "var(--cwg-blue-900)"
	},
	{
		id: 11,
		status: "CANCELLED",
		formattedStatus: "Cancelled",
		description: "Order has been cancelled.",
		statusColor: "var(--text-primary)"
	}
];
