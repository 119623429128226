import { Checkbox, FormControlLabel } from "@mui/material";
import { SetStateAction, useState, useRef, PropsWithChildren, useEffect, useCallback } from "react";
import ExpandMoreRounded from "@mui/icons-material/ExpandMoreRounded";
import {
	InactiveAccountOptions,
	InactiveAccountsDivider,
	InactiveAccountsHeader,
	Menu,
	MenuItemContentWrapper,
	MenuItemListWrapper,
	MenuItemWrapper,
	StyledChip,
	WarningAmber
} from "components/OrderSearch/chipDropdownMenu.styles";
import { FilterType } from "./filterType";
import CancelIcon from "@mui/icons-material/Cancel";
import { filterSpecificBrands } from "utils/order";
import ChipDropdownMenuSearch from "./ChipDropdownMenuSearch";
import { InactiveAccounts } from "constants/text";
import ChipDropdownMenuSearchNoResults from "./ChipDropdownMenuSearchNoResults";

const ChipDropDownMenu = ({
	children,
	selectedItems = [],
	filterType,
	items,
	onChange,
	isMobile,
	hasWarning,
	isSearchable,
	inactiveAccounts = [],
	activeAccounts = [],
	GTMIdentifier
}: ChipDropdownMenuProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);
	const hasSelections = selectedItems.length > 0;

	const handleClear = () => {
		onChange([]);
		defaultSearchItems();
	};

	const handleItemOnChange = (event: SetStateAction<any>) => {
		const itemWasSelected = selectedItems.indexOf(event.target.value) === -1;

		const updatedItems = itemWasSelected
			? [...selectedItems, event.target.value]
			: selectedItems.filter((item) => item !== event.target.value);

		onChange(updatedItems);
	};

	const getLabel = () => {
		if (selectedItems.length === 0 || items.length === selectedItems.length) {
			return filterType.toString();
		}

		if (selectedItems.length === 1) {
			return filterSpecificBrands(selectedItems[0]);
		}

		return filterType.toString() + " (" + selectedItems.length + ")";
	};
	const label = getLabel();

	const [searchedItems, setSearchedItems] = useState(items);
	const [searchedActiveAccounts, setSearchedActiveAccounts] = useState(activeAccounts);
	const [searchedInactiveAccounts, setSearchedInactiveAccounts] = useState(inactiveAccounts);
	const [isSearched, setIsSearched] = useState(false);
	const [searchValue, setSearchValue] = useState<string>("");

	useEffect(() => {
		setSearchedItems(items);
	}, [items]);

	const handleOnClose = () => {
		setIsOpen((prev) => !prev);
		if (selectedItems.length === 0) {
			defaultSearchItems();
		}
	};

	const defaultSearchItems = useCallback(() => {
		setSearchedItems(items);
		setSearchedActiveAccounts(activeAccounts);
		setSearchedInactiveAccounts(inactiveAccounts);
		setIsSearched(false);
		setSearchValue("");
	}, [activeAccounts, inactiveAccounts, items]);

	const handleSearch = useCallback(
		(query: string | undefined) => {
			if (query?.trim()) {
				const normalize = (str: string) => str.toUpperCase();
				const filterItems = (array: (string | ItemType)[], searchTerm: string): string[] => {
					return array
						.map((item) => (typeof item === "string" ? item : item.value))
						.filter((value) => normalize(value).includes(normalize(searchTerm.trim())));
				};
				const searchResults = filterItems(items, query);
				const activeAccountsSearchResults = filterItems(activeAccounts, query);
				const inactiveAccountsSearchResults = filterItems(inactiveAccounts, query);
				setSearchedItems(searchResults);
				setSearchedActiveAccounts(activeAccountsSearchResults);
				setSearchedInactiveAccounts(inactiveAccountsSearchResults);
				setIsSearched(true);
				setSearchValue(query);
				const combinedResults =
					filterType === FilterType.ACCOUNT
						? activeAccountsSearchResults.concat(inactiveAccountsSearchResults)
						: searchResults;
				onChange(combinedResults.filter((result) => selectedItems.includes(result)));
			} else {
				defaultSearchItems();
			}
		},
		[items, activeAccounts, inactiveAccounts, onChange, selectedItems, defaultSearchItems, filterType]
	);

	const getItemValue = (item: string | ItemType): string =>
		item && typeof item !== "string" ? (item.value ?? "") : (item ?? "");

	return (
		<div>
			<StyledChip
				hasWarning={hasWarning}
				clickable
				ref={anchorRef}
				deleteIcon={hasSelections ? <CancelIcon /> : undefined}
				onDelete={hasSelections ? handleClear : undefined}
				variant="outlined"
				label={label}
				onClick={() => setIsOpen(!isOpen)}
				icon={<ExpandMoreRounded />}
				color={!hasSelections ? "primary" : "secondary"}
				data-testid="filter-chip"
				data-id={GTMIdentifier}
			/>
			<Menu
				isMobile={isMobile}
				open={isOpen}
				anchorEl={anchorRef.current}
				onClose={handleOnClose}
				data-testid="filter-chip-dropdown-menu"
			>
				{isSearchable && (
					<ChipDropdownMenuSearch
						filterType={filterType}
						handleSearch={handleSearch}
						cancelSearch={defaultSearchItems}
						isSearched={isSearched}
						value={searchValue}
					/>
				)}
				{children}
				{filterType !== FilterType.ACCOUNT ? (
					<div>
						{isSearched && searchedItems.length === 0 ? (
							<ChipDropdownMenuSearchNoResults filterType={filterType} />
						) : (
							<MenuItemListWrapper
								isSearchable={isSearchable}
								child={children}
							>
								{searchedItems.map((item) => {
									const itemValue = getItemValue(item);
									const itemError = typeof item !== "string" && item?.error;

									return (
										<MenuItemWrapper key={JSON.stringify(item)}>
											<MenuItemContentWrapper item={item}>
												<FormControlLabel
													data-testid="filter-chip-dropdown-menu-option"
													control={
														<Checkbox
															value={itemValue}
															size="medium"
														/>
													}
													label={filterSpecificBrands(itemValue)}
													checked={selectedItems.includes(itemValue)}
													onChange={handleItemOnChange}
												/>
												{itemError && <WarningAmber fontSize="small" />}
											</MenuItemContentWrapper>
										</MenuItemWrapper>
									);
								})}
							</MenuItemListWrapper>
						)}
					</div>
				) : (
					<div>
						{isSearched && searchedActiveAccounts.length === 0 && searchedInactiveAccounts.length === 0 ? (
							<ChipDropdownMenuSearchNoResults filterType={filterType} />
						) : (
							<MenuItemListWrapper
								isSearchable={isSearchable}
								child={children}
							>
								{searchedActiveAccounts.map((item) => {
									const itemValue = getItemValue(item);
									return (
										<MenuItemWrapper key={JSON.stringify(item)}>
											<MenuItemContentWrapper item={item}>
												<FormControlLabel
													data-testid="filter-chip-dropdown-menu-option"
													control={
														<Checkbox
															value={itemValue}
															size="small"
														/>
													}
													label={filterSpecificBrands(itemValue)}
													checked={selectedItems.includes(itemValue)}
													onChange={handleItemOnChange}
												/>
											</MenuItemContentWrapper>
										</MenuItemWrapper>
									);
								})}
								{searchedInactiveAccounts.length > 0 && (
									<>
										<InactiveAccountsHeader>
											<span>{InactiveAccounts}</span>
										</InactiveAccountsHeader>
										<InactiveAccountsDivider />
									</>
								)}
								{searchedInactiveAccounts?.map((item) => {
									const itemValue = getItemValue(item);
									return (
										<MenuItemWrapper key={JSON.stringify(item)}>
											<MenuItemContentWrapper item={item}>
												<InactiveAccountOptions
													data-testid="filter-chip-dropdown-menu-option"
													control={
														<Checkbox
															value={itemValue}
															size="small"
														/>
													}
													label={filterSpecificBrands(itemValue)}
													checked={selectedItems.includes(itemValue)}
													onChange={handleItemOnChange}
												/>
											</MenuItemContentWrapper>
										</MenuItemWrapper>
									);
								})}
							</MenuItemListWrapper>
						)}
					</div>
				)}
			</Menu>
		</div>
	);
};

export interface ChipDropdownMenuProps extends PropsWithChildren {
	filterType: FilterType;
	items: string[] | ItemType[];
	onChange: (arg: string[]) => void;
	selectedItems: string[];
	isMobile?: boolean;
	hasWarning?: boolean;
	isSearchable?: boolean;
	inactiveAccounts?: string[] | ItemType[];
	activeAccounts?: string[] | ItemType[];
	GTMIdentifier: string;
}

interface ItemType {
	value: string;
	error: boolean;
}

export default ChipDropDownMenu;
