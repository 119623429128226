import { Box } from "@mui/material";
import { BulletContainer, DefaultMessageWrapper } from "../../OrderList/OrderList.styles";

interface DefaultMessageProps {
	asset: string;
	altMessage: string;
	bullets?: string[];
	title: string;
	subtitle: string;
	dataTestId?: string;
	removeHeight?: boolean;
}

const DefaultMessage = ({
	asset,
	altMessage,
	bullets,
	title,
	subtitle,
	dataTestId,
	removeHeight
}: DefaultMessageProps) => {
	return (
		<DefaultMessageWrapper
			data-testid={dataTestId}
			removeHeight={removeHeight}
		>
			<Box maxWidth="438px">
				<img
					src={asset}
					alt={altMessage}
				/>
				<div>
					<h6>{title}</h6>
					<p>{subtitle}</p>
					<BulletContainer>
						{bullets?.map((bullet: string) => <li key={bullet}>{bullet}</li>)}
					</BulletContainer>
				</div>
			</Box>
		</DefaultMessageWrapper>
	);
};

export default DefaultMessage;
