import styles from "pages/replacements-page-styles.module.css";
import { Button } from "@mui/material";
import { Fragment, MouseEventHandler } from "react";
import { EmptyFlyoutHeaderText, EmptyFlyoutHelperText, ReplacementsFlyoutSelectPartText } from "constants/text";

export interface EmptyShoppingCartProps {
	closeDrawer: MouseEventHandler;
}

const EmptyShoppingCart = ({ closeDrawer }: EmptyShoppingCartProps) => {
	return (
		<Fragment key="right">
			<div className={styles.emptyOrder}>
				<img
					src="/assets/NoOrdersSadBoxImage.svg"
					alt="Empty Order Flyout"
					className={styles.noOrdersErrorBannerContainerImage}
				/>

				<h2>{EmptyFlyoutHeaderText}</h2>
			</div>
			<p className={styles.emptyOrderMessage}>{EmptyFlyoutHelperText}</p>

			<div className={styles.emptyOrderButton}>
				<Button
					fullWidth
					onClick={closeDrawer}
					data-testid="empty-shopping-cart-select-parts-button"
					variant="contained"
					sx={{ marginLeft: 0 }}
				>
					{ReplacementsFlyoutSelectPartText}
				</Button>
			</div>
		</Fragment>
	);
};

export default EmptyShoppingCart;
