import { Box, Grid, Popover, Button } from "@mui/material";
import styles from "containers/Orders/order-styles.module.css";
import WarningBox from "../Common/WarningBox/WarningBox";
import { LargeModalPDFText } from "constants/text";
import Download from "@mui/icons-material/Download";

interface LargePDFModalProps {
	handleClose: () => void;
	open: boolean;
	popupAnchor?: HTMLElement | null;
	onExportAsExcel?: () => void;
}

const LargePDFModal = ({ handleClose, open, popupAnchor, onExportAsExcel }: LargePDFModalProps) => {
	return (
		<Popover
			open={open}
			anchorEl={popupAnchor}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right"
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right"
			}}
			onClose={handleClose}
			data-testid="order-list-export-large-pdf-modal"
		>
			<Box
				data-testid="order-list-export-large-pdf-warning"
				className={styles.largePDFModalContainer}
			>
				<WarningBox
					warning={LargeModalPDFText.LargeFileTitle}
					message={LargeModalPDFText.PDFTooLarge}
					containerTestId="order-list-export-large-pdf-warning-container"
					iconShapeTestId="order-list-export-large-pdf-warning-icon"
					warningTestId="order-list-export-large-pdf-warning-label"
					messageTestId="order-list-export-large-pdf-warning-text"
				/>
				<div className="body1">
					<p data-testid="order-list-export-large-pdf-proceed-with-csv-prompt">
						{LargeModalPDFText.ProceedWithExcelPrompt}
					</p>
				</div>
				<Grid
					className="body1"
					onClick={onExportAsExcel}
					data-testid="order-list-large-pdf-modal-export-excel"
					container
				>
					<p className={styles.largePDFModalExportPDF}>{LargeModalPDFText.ExportExcelOption}</p>
					<Download
						data-testid="download-excel-export-icon"
						className={styles.largePDFModalExportPDF}
						sx={{
							marginLeft: "5px"
						}}
					/>
				</Grid>
				<Button
					data-testid="order-list-export-large-pdf-close-button"
					onClick={handleClose}
					fullWidth
					variant="outlined"
					sx={{
						margin: ".5rem 0"
					}}
				>
					{LargeModalPDFText.CloseButtonLabel}
				</Button>
			</Box>
		</Popover>
	);
};

export default LargePDFModal;
