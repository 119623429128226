import { ChipDropdownMenuSearchNoResultsWrapper } from "./chipDropdownMenu.styles";
import { FilterType } from "./filterType";

const ChipDropdownMenuSearchNoResults = ({ filterType }: ChipDropdownMenuSearchNoResultsProps) => {
	return (
		<ChipDropdownMenuSearchNoResultsWrapper data-testid="chip-dropdown-menu-search-no-results">
			<img
				src="/assets/UnauthorizedSadImage.svg"
				alt="no-search=results-logo"
			/>
			<h5>No {filterType}s Found</h5>
			<p>
				Uh oh, it looks like the {filterType} you are trying to search for does not exist. Please try searching
				again by checking your spelling or contact your Sales Rep if the problem persists.
			</p>
		</ChipDropdownMenuSearchNoResultsWrapper>
	);
};

interface ChipDropdownMenuSearchNoResultsProps {
	filterType: FilterType;
}

export default ChipDropdownMenuSearchNoResults;
