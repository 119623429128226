import { EmptyOrdersContainer, EmptyOrdersText } from "../OrderDetailsHeader.styles";
import { Fragment } from "react";

interface EmptyErrorStatesProps {
	image: string;
	titleText: string;
	helperText: string;
}

const EmptyErrorStates = ({ helperText, image, titleText }: EmptyErrorStatesProps) => {
	return (
		<Fragment key="associatedOrders">
			<EmptyOrdersContainer>
				<img
					src={image}
					alt="Linked Orders Flyout"
				/>

				<h2>{titleText}</h2>
			</EmptyOrdersContainer>

			<EmptyOrdersText>{helperText}</EmptyOrdersText>
		</Fragment>
	);
};

export default EmptyErrorStates;
