import { LEGAL_LINKS } from "constants/links/legalLinks";
import { LegalLinksWrapper } from "./LegalLinks.styles";
import Link from "@mui/material/Link";
import { Divider } from "@mui/material";
import { Fragment } from "react";

const LegalLinks = () => {
	return (
		<LegalLinksWrapper data-testid="legal-links">
			{LEGAL_LINKS.map((navigationLink, index) => (
				<Fragment key={navigationLink.title}>
					<Link
						key={navigationLink.title}
						href={navigationLink.url}
						target="_blank"
						aria-describedby={`legal-link: ${navigationLink.title}`}
						data-id={navigationLink.GTMIdentifier}
					>
						{navigationLink.title}
					</Link>
					{index !== LEGAL_LINKS.length - 1 && (
						<Divider
							flexItem
							orientation="vertical"
						/>
					)}
				</Fragment>
			))}
		</LegalLinksWrapper>
	);
};

export default LegalLinks;
