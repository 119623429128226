import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "../data/api/v1/endpoints";

import { baseQueryWithRedirect } from "./apiUtils";
import { UploadImageDataModel } from "data/api/v1/model/images-upload-view-model";

export const imageApiSlice = createApi({
	reducerPath: "imageApi",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		getImageUploadDataByOrderId: builder.query<UploadImageDataModel, string>({
			query: (tandemOrderId: string) => endpoints.images.getUploadDataById(tandemOrderId),
			transformResponse: (response: { data: UploadImageDataModel }) => response?.data,
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		})
	})
});

export const { useLazyGetImageUploadDataByOrderIdQuery } = imageApiSlice;
